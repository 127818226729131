

const initialValues = {
  id: null,
  supplier_id: null,
  issue_date: '',
  total: '',
  notes: '',
  internal_notes: '',
  order_type_id: null,
  devolucion:'',
  returned_request_reason_id:'',
  detail:[]
}

export {
  initialValues,
}