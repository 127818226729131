import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AssignmentOutlined, Inventory2Outlined } from '@mui/icons-material'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCustomerSuppliers, useRequest } from 'hooks'
import { DetailContainer, TabsContainer } from 'containers'
import GeneralTab from './GeneralTab'
import ArticlesTab from './ArticlesTab'
import { initialValues } from './constants'
import routes from 'app/routing/routes'

const AddReturnRequest = ({ income = false }) => {
  const [customers, setCustomers] = useState([])
  const { handleGetCustomerSuppliers, loading:loadingCustomerSupplier } = useCustomerSuppliers()
  const { handleCreateRequest, handleCreateRequestDetail, loading: loadingRequest } = useRequest()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const createPurchaseRequest = async (data) => {
    try {
      const response = await handleCreateRequest(data)
      if (response) {
        const transport = {
          request_id:response.id, 
          articles:data.detail
        }
        await handleCreateRequestDetail(transport);
        toast.success(response.message, { theme: "colored" })
        navigate(`${routes.RETURN_REQUEST}/${response.id}`, { state: { tab: 'articles' } })
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const handleSubmit =  (values) => {

    if(values?.id){
        try{
            const transport = {
                supplier_id: values.supplier_id,
                issue_date: values.issue_date,
                notes: values.observations,
                internal_notes: values.internal_notes,
                order_type_id: 5,
                business_unit_id: values.business_unit_id,
                out_order_id: values.id,
                returned_request_reason_id: values.returned_request_reason_id,
                detail:values.detail.map(d=>({var_id:d.product_var_id, qty:d.devolucion}))
            }

            if(!values.returned_request_reason_id || values.returned_request_reason_id==''){
              toast.error("El campo motivo de devolución es obligatorio")
              return
            }
            createPurchaseRequest(transport)
        }
        catch(err){

        }
    }
    else{
      toast.error("No hay OE seleccionada")
    }
  }

  const loadSuppliers = async () => {
    const data = await handleGetCustomerSuppliers()
    if (data && data.data && data.data.result) {
      setCustomers(data.data.result
        .filter(p => Boolean(p.is_customer))
        .map(p => ({ ...p, label: p.business_name, value: p.id }))
      )
    }
  }
  useEffect(()=>{
    if(loadingCustomerSupplier || loadingRequest){
      setLoading(true)
    }
    else{
      setLoading(false)
    }
  },[loadingCustomerSupplier, loadingRequest])

  useEffect(()=>{
    console.log(loading)
  }, [loading])

  useEffect(()=>{
    loadSuppliers()
  }, [])

  const tabsContent =  [
    <GeneralTab customers={customers} loading={loading} />,
    <ArticlesTab loading={loading}/>,
  ]

  const tabs = [
    { value: 'general', label: "General", icon: <AssignmentOutlined />, show: true, disabled: loading },
    { value: 'articles', label: "Articulos", icon: <Inventory2Outlined />, show: true, disabled: loading }
  ]


  return (
    <>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          // validationSchema={income?incomeSchema:purchaseSchema }
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched }) => (
            <Form className="d-flex flex-column mt-4" >
              <DetailContainer
                title='Nuevo pedido de devolución'
                // status={'Borrador'}
                buttons={
                  <div className='align-self-center'>
                    <Button variant="secondary" onClick={() => navigate(-1)}>
                      <i className="ki-duotone ki-left" />
                      Volver
                    </Button>
                  </div>
                }
              >
                <TabsContainer
                  initialValue='general'
                  tabs= {tabs} 
                  tabsContent={tabsContent}
                />
              </DetailContainer>
            </Form>
          )}
        </Formik>


    </>
  )
}

export default AddReturnRequest