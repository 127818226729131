import { useNavigate } from 'react-router-dom'
import { Dropdown, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useStock } from 'hooks'

import { DepotMovementStatusI } from 'interfaces'
import { useEffect } from 'react'

const Actions = ({ loading = false, movement, handleGetDepotMovement, handleGetDepotMovementDetail }) => {
  const navigate = useNavigate()

  const { loading: loadingS, handleConfirmDepotMovement, handleCancelDepotMovement, handleGenerarRemitoInterno, handlePrintRemito } = useStock()

  const handleConfirm = async () => {
    if (movement) {
      try {
        const resp = await Swal.fire({
          title: 'Advertencia',
          text: `¿Confirma el Movimiento interno de stock #${movement.id}?`,
          icon: 'warning',
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          confirmButtonText: `Si, confirmar`,
          customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          const response = await handleConfirmDepotMovement(movement.id)
          toast.success(response.data.message)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetDepotMovement()
        handleGetDepotMovementDetail()
      }
    }
  }

  const handleCancel = async () => {
    if (movement) {
      try {
        const resp = await Swal.fire({
          title: "Advertencia",
          text: `Va a cancelar el Movimiento interno de stock #${movement.id}, ¿confirma?`,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, confirmar",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
        })

        if (resp.isConfirmed) {
          const response = await handleCancelDepotMovement(movement.id)
          toast.success(response.data.message)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetDepotMovement()
        handleGetDepotMovementDetail()
      }
    }
  }

  const handleGenerateInternalWaybill = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a generar el remito interno para el Movimiento interno de stock #${movement.id}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, confirmar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleGenerarRemitoInterno(movement.id)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetDepotMovement()
      handleGetDepotMovementDetail()
    }
  }

  const printInternalWaybill = async () => {
    try {
      if (movement) {
        let copias = 2
        const response = handlePrintRemito(movement.id, copias)
        // const response = await handlePrintInternalWaybill(movement.id, copias)

        if (response.status === 200) {
          toast.success('¡Remito descargado!')

          // Crear un Blob a partir de la respuesta
          const blob = await response.data

          // Crear un enlace de descarga
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `remito-${movement.remito_id}.pdf`
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          URL.revokeObjectURL(url)

        } else {
          // Manejar errores si la solicitud no fue exitosa
          console.error('Error al descargar el PDF')
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {}, [movement])

  return (
    <div className='d-flex pt-5'>
      <Button variant="secondary" type="button" className='me-3' style={{ height:"38px" }} onClick={() => navigate(-1)} disabled={loading || loadingS}>
        <i className="ki-duotone ki-left" />
        Volver
      </Button>

      <Dropdown>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">Acciones</Dropdown.Toggle>

        <Dropdown.Menu align={'end'}>
          {movement.depot_movement_status_id === DepotMovementStatusI.EN_PREPARACION && movement.detail_count > 0 &&
            <Dropdown.Item href="" onClick={handleConfirm} disabled={loading || loadingS}>
              <i className="bi bi-arrow-left-right text-primary me-2" />
              Confirmar
            </Dropdown.Item>
          }

          {movement.depot_movement_status_id === DepotMovementStatusI.EN_PREPARACION &&
            <Dropdown.Item href="" onClick={handleCancel} disabled={loading || loadingS}>
              <i className="bi bi-x-circle text-danger me-2" />
              Cancelar Movimiento
            </Dropdown.Item>
          }

          {(movement.depot_movement_status_id == DepotMovementStatusI.CONFIRMADO && movement?.remito_id == null) &&
            <Dropdown.Item href='' onClick={handleGenerateInternalWaybill} disabled={loading || loadingS}>
              <i className='bi bi-link-45deg text-warning me-2' />
              Generar remito interno
            </Dropdown.Item>
          }

          {movement.remito_id != null &&
            <Dropdown.Item href="" onClick={printInternalWaybill} disabled={loading || loadingS}>
              <i className="bi bi-printer text-warning me-2" />
              Descargar remito
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default Actions