import { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useCustomerSuppliers, useShipmentAddresses } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import LinkShipmentAddressFilters from './LinkShipmentAddressFilters'
import { COLUMNS } from './LinkShipmentAddressColumns'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const LinkShipmentAddressModalV2 = ({ show, onClose }) => {
  const { id } = useParams()

  const { loading, handleGetShipmentAddresses } = useShipmentAddresses()
  const { handleLinkShipmentAddressToCustomerSupplier } = useCustomerSuppliers()
  const [data, setData] = useState([])
  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [idDireccionSeleccionada, setIdDireccionSeleccionada] = useState(null)

  const getData = useCallback(async () => {
    try {
      const params = {
        ...query,
      }
      const response = await handleGetShipmentAddresses(null, null, params)
      const result = response.data.result
      const count = response.data.metadata.count

      setData(result)
      setTotalCount(count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetShipmentAddresses, query])

  const linkShipmentAddress = async () => {
    try {
      await handleLinkShipmentAddressToCustomerSupplier(id, idDireccionSeleccionada)
      toast.success("Dirección vinculada correctamente")
    } catch (error) {
      toast.error(error.message)
    } finally {
      onClose()
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'seleccionar-direccion',
      accessor: (row) => (
        <Button
          type='button'
          variant='primary'
          onClick={() => {
            setIdDireccionSeleccionada(row.id)
          }}
          disabled={row?.id == idDireccionSeleccionada}
        >
          <i className='bi bi-plus' />
        </Button>
      )
    }
  ], [idDireccionSeleccionada, COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    if (show) {
      getData()
    }
  }, [show, getData])

  useEffect(() => {
    helix.register()
  }, [])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' size='xl' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Relacionar dirección de entrega existente</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TableContainer
          filter={<LinkShipmentAddressFilters loading={loading} query={query} setQuery={setQuery} />}
        >
          {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

          <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} />
        </TableContainer>
      </Modal.Body>

      <Modal.Footer>
        <Button type='button' variant='secondary' className='me-3' onClick={onClose} disabled={loading}>
          <i className='bi bi-slash-circle' />
          Cerrar
        </Button>

        <Button type='button' variant='primary' onClick={linkShipmentAddress} disabled={loading}>
          <i className='bi bi-save me-2' />
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LinkShipmentAddressModalV2