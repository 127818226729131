import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useWaybill } from 'hooks'

import routes from 'app/routing/routes'

import { ESTADOS_ORDEN_ENVIO } from '../List'

const Actions = ({ loading, shipmentOrder }) => {
  const navigate = useNavigate()

  const { loading: loadingW, handleMarkOrderAsDelivered } = useWaybill()

  const availableActions = () => {
    let actions = []

    if (shipmentOrder.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION) {
      /*
      actions.push({
        label: "Envío entregado",
        icon: "bi bi-bag-check text-success",
        action: actionDelivered
      })
      */

      /*
      actions.push({
        label: "Envío devuelto",
        icon: "bi bi-arrow-return-left text-danger",
        action: actionReturned
      })
      */

      /*
      actions.push({
        label: "Entrega / Devolución",
        icon: "bi bi-bag-check text-primary",
        action: ()=>setShowShipmentResult(true)
      })
      */
    }
    return actions
  }

  const actionDelivered = async () => {
    if (shipmentOrder) {
      try {
        const resp = await Swal.fire({
          title:"Envío entregado",
          text:`¿Confirma que el envío ${shipmentOrder.id}, con ${shipmentOrder.out_order_count == 1 ? ' 1 órden': shipmentOrder.out_order_count + 'órdenes' } de egreso, se entregó?`,
          icon:"warning",
          showDenyButton:true,
          denyButtonText:"No, cancelar",
          confirmButtonText:"Si, confirmar",
          customClass: {confirmButton:'btn btn-primary', denyButton:"btn btn-secondary"},
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          await handleMarkOrderAsDelivered({ order_delivery_id: [shipmentOrder.id] });
          toast.success("Envío marcado como entregado", { theme: "colored" })
        }
      } catch (err) {
        toast.error(err.message, { theme: "colored" })
      }
    }
  }

  const actionReturned = async () => {
    //
  }

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <Button variant='secondary' onClick={() => navigate(-1)} className='me-4' disabled={loading || loadingW}>
        <i className='ki-duotone ki-left' />
        Volver
      </Button>

      {shipmentOrder.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION && availableActions().length > 0 &&
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic" disabled={loading || loadingW}>
            Acciones
          </Dropdown.Toggle>

          <Dropdown.Menu style={{width:"200px"}}>
            {availableActions().map((data, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => data.action()}
              >
                <i className={`${data.icon} pe-3`} />
                {data.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      }
    </div>
  )
}

export default Actions