import { useState, useCallback } from 'react';

import { Purchases } from '../../services';


const initialQueryString = {
  filter:"",
  metadata:{
    pageNumber:"1",
    pageSize:"10"
  }
}

const useRequest = () => {
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryStringBase] = useState(initialQueryString);

  const setQueryString = useCallback((nextQueryParams) => {
    setQueryStringBase((prevQueryParams) => {
      return nextQueryParams;
    });
  }, []);

  const handleGetRequests = useCallback(async (params) => {
    try {
      let query = "";
      setLoading(true);

      const {filter, queryMD} = params;

      if(filter!=null && filter!=''){
        query = filter + '&' + new URLSearchParams(queryMD).toString();
      }
      else{
        query = "?" + new URLSearchParams(queryMD).toString();
      }

      const response = await Purchases.getRequests(query);

      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetRequestsV2 = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Purchases.getRequestsV2(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetRequest = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Purchases.getRequest(id);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCreateRequest = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Purchases.createRequest(data);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleUpdateRequest = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Purchases.updateRequest(data);
      return response.data;
    } catch (err) {
      console.log(err)
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleAuthorizeRequest = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Purchases.authorizeRequest(data);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleRejectRequest = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Purchases.rejectRequest(data);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);
  const handleCancelRequest = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Purchases.cancelRequest(data);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCheckInRequestPVP = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Purchases.checkInRequestPVP(id);

      return response;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handlePublishRequest = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Purchases.publishRequest(id);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleClosePartialRequest = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Purchases.closePartialRequest(id);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);


  const handleCreateRequestDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Purchases.createRequestDetail(data);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);
  const handleUpdateRequestDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      console.log(data)
      const response = await Purchases.updateRequestDetail(data.data);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);
  const handleDeleteRequestDetail = useCallback(async (data) => {
    try {
      setLoading(true);
      const response = await Purchases.deleteRequestDetail(data);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetPaymentConditions = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Purchases.getPaymentConditions();
      return response?.data
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  const handlePrintRequest = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Purchases.printRequest(id)

      return response
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
    }
  }, [])
  const handleGetEvents = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await Purchases.getEvents(id);
      return response.data;
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);
  const handleGetRequestReturnedReason = useCallback(async () => {
    try {
      setLoading(true);
      const response = await Purchases.getRequestReturnedReason();
      return response?.data
    } catch (err) {
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);


  return {
    loading,
    queryString,
    setQueryString,
    handleGetPaymentConditions,
    handleGetRequests,
    handleGetRequestsV2,
    handleGetRequest,
    handleCreateRequest,
    handleCreateRequestDetail,
    handleUpdateRequestDetail,
    handleDeleteRequestDetail,
    handleAuthorizeRequest,
    handleRejectRequest,
    handleCancelRequest,
    handleCheckInRequestPVP,
    handlePublishRequest,
    handleClosePartialRequest,
    handleUpdateRequest,
    handlePrintRequest,
    handleGetEvents,
    handleGetRequestReturnedReason
  }
}

export default useRequest;