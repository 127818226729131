
import routes from 'app/routing/routes'
import { Link } from 'react-router-dom'
import { currencyFormat } from 'utilities'

const useGetColumns = (selectedRows = [], handleRowSelection) => {
  const COLUMNS =  [
    {
      Header: <p className='m-0 p-0'>Pedido</p>,
      id: 'sales_order_id',
      accessor: (row) => (
        <Link to={`${routes.SALES_ORDERS}/${row.sales_order_id}`}>
          {row.sales_order_id}
        </Link>
      ),
    },
    {
      Header: <p className='m-0 p-0'>O. Egreso</p>,
      id: 'out_order_id',
      accessor: (row) => (
        <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>
          {row.out_order_id}
        </Link>
      )
    },
    {
      Header: <p className='m-0 p-0'>Dirección</p>,
      id: "shipment_address_name",
      accessor: (row) => (
        <>
          <strong className='m-0 p-0'>{row.ship_name}</strong>
          <p className='m-0 p-0 mt-4'>{row.ship_domicilio?.trim()}, {row.ship_localidad?.trim()}</p>
          <p className='m-0 p-0 mt-4'>{row.ship_provincia?.trim()}</p>
        </>
      ),
    },
    {
      Header: <p className='m-0 p-0 text-end'>Total</p>,
      id: "total",
      accessor: (row) => (
        <p className='m-0 p-0 text-end'>
          {currencyFormat.format(row.total)}
        </p>
      ),
    },
    {
      Header: <p className='m-0 p-0'>AFIP</p>,
      id: 'afip',
      accessor: (row) => (
        <p className='m-0 p-0'>
          {row?.afip
            ? row.afip
            : <i>Sin comprobante</i>
          }
        </p>
      ),
    },
    {
      Header: <p className='m-0 p-0'>Remito</p>,
      id: 'remito',
      accessor: (row) => (
        <p className='m-0 p-0'>
          {row?.remito
            ? row.remito
            : <i>Sin remito</i>
          }
        </p>
      ),
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns