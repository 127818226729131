import { useState, useCallback, useEffect } from 'react'
import { useFormikContext, ErrorMessage } from 'formik'
import { Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useOutOrders, useCustomerSuppliers, usePatients, useShipmentAddresses, useHomeDeliveryOptions, useUsers } from 'hooks'

import { ShipmentAddressModal } from 'containers'
import { Label, Checkbox, SelectField, TextareaBS, Error, InputBS } from 'components'

import { HomeDeliveryOptionI, OutOrderCoordinationStatusI } from 'interfaces'

import routes from 'app/routing/routes'
import { GLNEditModal } from './GLNEditModal'

const AddresseeTab = ({ editing, handleGetOutOrder, anmatValidate, loadingOrder }) => {
  const { currentUser } = useAuth()

  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const intl = useIntl()

  const { handleGetCoordinationStatus } = useOutOrders()
  const [coordinationStatus, setCoordinationStatus] = useState([])

  const [addressModal, setAddressModal] = useState(false)
  const [patientAddressInfo, setPatientAddressInfo] = useState(null)

  const { handleGetShipmentAddresses, handleGetShipmentAddress } = useShipmentAddresses()
  const [shipmentAddresses, setShipmentAddresses] = useState([])

  const { handleGetCustomerSupplierPatients, handleGetCustomerSupplierShipmentAddresses, handleGetClientesParaRemitir } = useCustomerSuppliers()
  const [patients, setPatients] = useState([])
  const [customerSupplierShipmentAddresses, setCustomerSupplierShipmentAddresses] = useState([])
  const [clientesParaRemitir, setClientesParaRemitir] = useState([])

  const { handleGetPatientAddresses, handleAddPatientAddress, handleUpdatePatientAddress } = usePatients()
  const [patientAddresses, setPatientAddresses] = useState([])

  const { handleGetHomeDeliveryOptions } = useHomeDeliveryOptions()
  const [homeDeliveryOptions, setHomeDeliveryOptions] = useState([])

  const { handleGetUsers } = useUsers()
  const [users, setUsers] = useState([])
  const [displayGLNEdit, setDisplayGLNEdit] = useState(false)
  const [showGLNEditModal, setShowGLNEditModal] = useState(false)



  const getCoordinationStatus = useCallback(async () => {
    try {
      const response = await handleGetCoordinationStatus()
      const data = response.data.result.map(d => ({
        value: d.id,
        name: d.name,
        label: d.label,
      }))

      let all_items = data.filter(d => d.name !== OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2)
      let last_item = data.filter(d => d.name == OutOrderCoordinationStatusI.PENDIENTE_DE_COORDINACION_2)

      setCoordinationStatus([...last_item, ...all_items])
    } catch (error) {
      setCoordinationStatus([])
      toast.error(error.message)
    }
  }, [handleGetCoordinationStatus])

  const patientAddressSubmit = async (data) => {
    try {
      let response

      let new_address = {
        ...data,
        address: {
          ...data.address,
          manual: Boolean(data.address.manual),
          origin: Boolean(data.address.manual) ? 'MANUAL' : 'GOOGLE',
        }
      }

      if (patientAddressInfo?.id) {
        response = await handleUpdatePatientAddress(Number(values.patient_id), Number(values.address_id_ship), new_address)

        toast.success("Registro actualizado correctamente", { theme: "colored" })
        return
      }

      response = await handleAddPatientAddress(Number(values.patient_id), new_address, 'token')
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getPatientAddresses()
      handleGetOutOrder()
      setAddressModal(false)
    }
  }

  const getCustomerSupplierPatients = useCallback(async () => {
    try {
      const params = {
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierPatients(Number(values.customer_supplier_id), params)
      const data = response.data.result.map(p => ({
        ...p,
        display_name : `[${p.id}] ${p.documentation ? `${p.documentation} - ${p.first_name} ${p.last_name}` : `${p.first_name} ${p.last_name}`}${p?.affiliate_number ? ` | Nro. afiliado: ${p.affiliate_number}` : ''}`
      }))

      setPatients(data)
    } catch (error) {
      setPatients([])
      toast.error(error.message)
    }
  }, [handleGetCustomerSupplierPatients, values.customer_supplier_id])

  const getCustomerSupplierShipmentAddresses = useCallback(async () => {
    try {
      let params = {
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierShipmentAddresses(Number(values.customer_supplier_id), params, 'token')
      setCustomerSupplierShipmentAddresses(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetCustomerSupplierShipmentAddresses, values.customer_supplier_id])

  const getPatientAddresses = useCallback(async () => {
    try {
      let params = {
        is_enabled: 1,
        has_traceability: 0,
        is_ours: 0,
      }
      const response = await handleGetPatientAddresses(Number(values.patient_id), params)
      setPatientAddresses(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetPatientAddresses, values.patient_id])

  const getShipmentAddresses = useCallback(async () => {
    try {
      let params = {
        is_ours: 1,
        has_traceability: 1,
        is_enabled: 1,
      }
      const response = await handleGetShipmentAddresses('token', '', params)
      setShipmentAddresses(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetShipmentAddresses])


  const getHomeDeliveryOptions = useCallback(async () => {
    try {
      const response = await handleGetHomeDeliveryOptions()
      setHomeDeliveryOptions(response.data.result)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetHomeDeliveryOptions])

  const getUsers = useCallback(async () => {
    try {
      const response = await handleGetUsers()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.name
      }))

      setUsers(data)
    } catch (error) {
      setUsers([])
      toast.error(error.message)
    }
  }, [handleGetUsers])

  const onHideGLNModal = ()=>{
    setShowGLNEditModal(false)
    getPatientAddresses()
    handleGetOutOrder()
  }

  const getClientesParaRemitir = useCallback(async () => {
    try {
      let params = {
        id_cliente: values.customer_supplier_id,
        activo: 1,
      }
      const response = await handleGetClientesParaRemitir(params)
      const data = response.data.result.map(d => ({
        value: d.id_cliente_para_remitir,
        label: `[${d.id_cliente_para_remitir}] ${d.para_remitir}`,
      }))

      setClientesParaRemitir(data)
    } catch (error) {
      setClientesParaRemitir([])
      toast.error(error.message)
    }
  }, [handleGetClientesParaRemitir])

  useEffect(() => {
    if (values.to_patient) {
      getCustomerSupplierPatients()
    } else {
      setFieldValue('patient_id', '')
      setFieldValue('patient_name', '')
    }
  }, [values.to_patient, getCustomerSupplierPatients, setFieldValue])

  useEffect(() => {
    if (values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE) {
      getPatientAddresses()
      getShipmentAddresses()
    }  else if (values.home_delivery_option_id === HomeDeliveryOptionI.CLIENTE) {
      getCustomerSupplierShipmentAddresses()
      getShipmentAddresses()
    } else if (values.home_delivery_option_id === HomeDeliveryOptionI.FARMACIA) {
      getShipmentAddresses()
    } else {
      getCustomerSupplierShipmentAddresses()
      setFieldValue('address_id_ship', '')
      // setFieldValue('shipment_address_id_home_delivery', '')
    }
  }, [values.home_delivery_option_id, getPatientAddresses, getShipmentAddresses, getCustomerSupplierShipmentAddresses, setFieldValue])

  useEffect(() => {
    setFieldValue('coordination_user_id', currentUser?.user_id ? currentUser.user_id : values?.coordination_user_id || '')
  }, [currentUser?.user_id])

  useEffect(() => {
    getHomeDeliveryOptions()
    getCoordinationStatus()
    getUsers()
    // console.log(values)
  }, [getHomeDeliveryOptions, getCoordinationStatus, getUsers])

  const evaluateGlnEdit = async()=>{
    if(values.address_id_traza){
      const resp = await handleGetShipmentAddress(values.address_id_traza)
      const is_our = resp?.data?.result?.is_ours;
      const _err = anmatValidate?.items?.filter(d=>d.anmat_traced_error && d.traced==1);
      if((_err?.length>0 || !values.remito_id) && !is_our){
        setDisplayGLNEdit(true)
      }
      else{
        setDisplayGLNEdit(false)
      }
    }
    else{
      setDisplayGLNEdit(false)
    }
  }

  useEffect(()=>{
      evaluateGlnEdit()
  }, [anmatValidate, values.remito_id])

  useEffect(() => {
    getClientesParaRemitir()
  }, [getClientesParaRemitir])

  return (
    <>
      <GLNEditModal show={showGLNEditModal} onHide={onHideGLNModal} address_id_traza={values?.address_id_traza} />

      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='dispatch_date' name='dispatch_date' type='date' label={'Fecha de entrega'} disabled={!editing} />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='to_patient' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE' })} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='requires_coordination' disabled={!editing} />
              <Label htmlFor='requires_coordination' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE.COORDINATION' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='to_patient' disabled={!editing} />
              <Label htmlFor='to_patient' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.ADDRESSEE.TO_PATIENT' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='remito_para_terceros' disabled={!editing}/>
              <Label htmlFor='remito_para_terceros' label={'Remito a nombre de terceros'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        <div className='col-3'>
          <SelectField
            id='home_delivery_option_id'
            name='home_delivery_option_id'
            label='Entrega especial'
            options={homeDeliveryOptions.map(o => ({ value: o.id, label: o.label }))}
            disabled={!editing}
          />
        </div>

        {values.to_patient && (
          <>
            <div className="col">
              <Label label="Paciente" htmlFor='patient_id' />
              {values.patient_id &&
                <a className="display-inline ms-8" href={`${routes.PATIENTS}/${values.patient_id}`} target="_blank" rel="noopener noreferrer">
                  Ver paciente
                </a>
              }
              <Typeahead
                id='patient_id'
                name='patient_id'
                minLength={3}
                labelKey={option => option?.display_name}
                options={patients}
                filterBy={['id', 'documentation', 'first_name', 'last_name', 'affiliate_number']}
                promptText={'Buscar paciente'}
                searchText='Buscando...'
                placeholder={'Buscar paciente'}
                emptyLabel={'No se encontró el paciente'}
                onChange={(selected) => {
                  if (!selected || selected.length === 0) {
                    setFieldValue('patient_id', '')
                    setFieldValue('patient_name', '')
                    setFieldValue('to_patient', false)
                    setFieldValue('address_id_ship', '')
                    setFieldValue('shipment_address_id_home_delivery', '')
                  } else {
                    const value = selected[0]
                    setFieldValue('patient_id', value.id)
                    // setFieldValue('patient_name', `${value.first_name} ${value.last_name}`)
                    setFieldValue('patient_name', `#${value.id} | ${value?.documentation ? value.documentation : value?.health_insurance?.affiliate_number ? value?.health_insurance?.affiliate_number : ''} - ${value.first_name} ${value.last_name}`)
                  }
                }}
                onBlur={e => setFieldTouched('patient_id', true)}
                defaultInputValue={values.patient_name}
                disabled={!editing}
              />
              <ErrorMessage className='text-danger' name='patient_id' render={msg => <Error message={msg} />} />
            </div>
{/* 
            {values.patient_id &&
              <div className='col-2 align-center'>
                <a href={`${routes.PATIENTS}/${values.patient_id}`} target="_blank" rel="noopener noreferrer">
                Ver paciente
                </a>
              </div>
            } */}
          </>
        )}

        {/* Remito para terceros */}
        {values.remito_para_terceros &&
          <div className='col-4'>
            {editing
              ?
                <SelectField
                  id='id_tercero_para_remitir'
                  name='id_tercero_para_remitir'
                  options={clientesParaRemitir}
                  placeholder='Seleccionar tercero para remitir'
                  disabled={!editing}
                />
              : <InputBS id='tercero_para_remitir' name='tercero_para_remitir' disabled />
            }
          </div>
        }
      </div>

      {Boolean(values.requires_coordination) &&
        <div className='row my-8'>
          <div className='col-4'>
            <SelectField
              id='coordination_status'
              name='coordination_status'
              label='Estado de coordinación'
              options={coordinationStatus}
              placeholder='Seleccionar estado'
              disabled={!editing}
            />
          </div>

          {editing &&
            <div className='col-4'>
              <SelectField
                id='coordination_user_id'
                name='coordination_user_id'
                label='Usuario de coordinación'
                options={users}
                placeholder='Seleccionar usuario'
                disabled={!editing}
              />
            </div>
          }

          {values.usuario_coordinacion !== '' &&
            <div className='col-4'>
              <InputBS id='coordination_user' name='coordination_user' type='text' label='Usuario coordinación (últ.)' disabled />
            </div>
          }
        </div>
      }

      <ShipmentAddressModal
        show={addressModal}
        onClose={() => setAddressModal(false)}
        values={patientAddressInfo}
        onSave={patientAddressSubmit}
      />

      {values.to_patient && values.patient_id && values.home_delivery_option_id === HomeDeliveryOptionI.PACIENTE &&
        <div className='row my-8'>
          <div className={editing ? 'col-8' : 'col-12'}>
            {editing
              ?
                <SelectField
                  id='address_id_ship'
                  name='address_id_ship'
                  label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })}
                  options={patientAddresses.map(address => ({
                    value: address.id,
                    label: `${address.name} - ${address.address?.domicilio?.trim()}, ${address.address?.localidad_completa?.trim()}${address?.gln ? ` | GLN: ${address.gln}` : ''}`
                  }))}
                  placeholder='Seleccione una dirección de entrega'
                  disabled={!editing}
                />
              : <InputBS id='direccion_ship' name='direccion_ship' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })} disabled />
            }
          </div>

          {editing && values.address_id_ship &&
            <>
              <div className='d-flex flex-row align-items-center col' style={{ paddingTop: '20px' }}>
                <Button
                  variant='secondary'
                  onClick={() => {
                    setPatientAddressInfo(patientAddresses.find(address => address.id === values.address_id_ship))
                    setAddressModal(true)
                  }}
                  className='me-4'
                >
                  Editar dirección
                </Button>

                <Button
                  variant='primary'
                  onClick={() => {
                    setPatientAddressInfo(null)
                    setAddressModal(true)
                  }}
                >
                  Crear dirección
                </Button>
              </div>
            </>
          }

          {editing && !values.address_id_ship &&
            <div className='d-flex flex-row align-items-center col-3' style={{ paddingTop: '20px' }}>
              <Button variant='primary'
                onClick={() => {
                  setPatientAddressInfo(null)
                  setAddressModal(true)
                }}
              >
                Crear dirección
              </Button>
            </div>
          }
        </div>
      }

      {values.home_delivery_option_id === HomeDeliveryOptionI.CLIENTE && (
        <div className='row my-8'>
          <div className='col-12'>
            {editing
              ?
                <SelectField
                  id='address_id_ship'
                  name='address_id_ship'
                  label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })}
                  options={customerSupplierShipmentAddresses.map(address => ({
                    value: address.id,
                    label: `${address.name} - ${address.address?.domicilio?.trim()}, ${address.address?.localidad_completa?.trim()}${address?.gln ? ` | GLN: ${address.gln}` : ''}`
                  }))}
                  placeholder='Seleccione una dirección de entrega'
                  disabled={!editing}
                />
                : <InputBS id='direccion_ship' name='direccion_ship' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_PATIENT' })} disabled />
            }
          </div>
        </div>
      )}

      <div className='row my-8'>
        <div className='col-12'>
          {editing
            ?
              <SelectField
                id='address_id_traza'
                name='address_id_traza'
                label={intl.formatMessage({id: (values.home_delivery_option_id !== HomeDeliveryOptionI.NO)
                  ? 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_CUSTOMER_SECONDARY'
                  : 'FORM.BUDGETS.ADDRESSEE.SHIPMENT_ADDRESS_CUSTOMER' })}
                options={
                values.home_delivery_option_id === HomeDeliveryOptionI.NO
                  ? customerSupplierShipmentAddresses.map(address => ({
                      value: address.id,
                      label: `${address.name} - ${address.address?.domicilio}, ${address.address?.localidad_completa}`
                    }))
                  : shipmentAddresses.map(address => ({
                    value: address.id,
                    label: `${address.name} - ${address.address?.domicilio}, ${address.address?.localidad_completa}`
                  }))
                }
                placeholder={values.home_delivery_option_id === HomeDeliveryOptionI.NO ? 'Seleccione una dirección de entrega' : 'Seleccione una dirección de trazabilidad'}
                disabled={!editing}
              />
            :

            <div className='row'>
              <div className='col'>
                <label className='form-label'>{(values.home_delivery_option_id == HomeDeliveryOptionI.NO || values.home_delivery_option_id==HomeDeliveryOptionI.FARMACIA)
                    ? 'Dirección de trazabilidad / entrega'
                    : 'Dirección de trazabilidad'
                  }
                  {displayGLNEdit &&
                    <span className={`ms-8 text-primary ${loadingOrder? '' : 'cursor-pointer'}`}
                      onClick={loadingOrder ? null : () => setShowGLNEditModal(true)}
                    >Cambiar GLN / Agente</span>
                  }
                </label>
                <input type="text" className='form-control' value={values.direccion_traza} disabled></input>
                {/* <InputBS
                  id='direccion_traza'
                  name='direccion_traza'
                  label={(values.home_delivery_option_id == HomeDeliveryOptionI.NO || values.home_delivery_option_id==HomeDeliveryOptionI.FARMACIA)
                    ? 'Dirección de trazabilidad / entrega'
                    : 'Dirección de trazabilidad'

                  }
                  disabled
                /> */}
              </div>
              
              {/* {displayGLNEdit ?
                <div className='col-2 align-center'>
                  <label 
                    className={`text-primary ${loadingOrder? '' : 'cursor-pointer'}`}
                    onClick={loadingOrder ? null : () => setShowGLNEditModal(true)} >Cambiar GLN / Agente</label>
                </div>
                :
                <></>
              } */}
            </div>
          }
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-12'>
          <TextareaBS id='observations' name='observations' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.OBSERVATIONS' })} disabled={!editing} />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-12'>
          <TextareaBS id='dispatch_observations' name='dispatch_observations' label={intl.formatMessage({ id: 'FORM.BUDGETS.ADDRESSEE.DISPATCH_OBSERVATIONS' })} disabled={!editing} />
        </div>
      </div>
    </>
  )
}

export default AddresseeTab