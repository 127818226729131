import routes from "app/routing/routes"
import { Tooltip } from "components"
import { Link } from "react-router-dom"

const COLUMNS = [
  {
    Header: <p className='m-0 p-0'>ID</p>,
    id: 'id_cliente_para_remitir',
    accessor: (row) => (
      <Tooltip text='Ver'>
        <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.id_cliente_para_remitir}`}>
          {row.id_cliente_para_remitir}
        </Link>
      </Tooltip>
    ),
    hasOrder: true,
  },
  {
    Header: <p className='m-0 p-0'>Cliente</p>,
    id: 'para_remitir',
    accessor: (row) => (
      <p className='m-0 p-0'>
        {row.para_remitir}
      </p>
    ),
    hasOrder: true,
  },
  {
    Header: <p className='m-0 p-0 text-center'>Activo</p>,
    id: 'activo',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row.activo) ? 'success' : 'danger'}`}>
          {Boolean(row.activo) ? 'Sí' : 'No'}
        </span>
      </div>
    ),
    hasOrder: true,
  }
]

export {
  COLUMNS
}