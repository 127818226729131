import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap';
import moment from 'moment';

import { Tooltip } from 'components';

import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'

const COLUMNS = [
  {
    Header: <div className=''>Nro.</div>,
    id: 'id',
    accessor: (row) => (
      <Link to={`${row.order_type_id == 1 ? routes.PURCHASE_REQUEST_DETAIL : row.order_type_id == 5 ? routes.RETURN_REQUEST : routes.INCOME_REQUEST_DETAIL}/${row.id}`}>
        {row.id}
      </Link>
    ),
    hasOrder: true
  },
  {
    Header: <div className=''>Tipo</div>,
    accessor: 'order_type_label',
    hasOrder: true
  },
  {
    Header: <div className='text-center'>F. creación</div>,
    id: 'created_at',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.created_at).format("DD/MM/YYYY")}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className=''>Proveedor</div>,
    id: 'supplier_name',
    accessor: (row) => (
      <div className=''>
        <Tooltip text='Ver proveedor'>
          <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.supplier_id}`}>
            [{row.supplier_id}] {row.supplier_name}
          </Link>
        </Tooltip>

        <p className='m-0 p-0 mt-2'>Documento: {row?.supplier_documentation || '-'}</p>
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className=''>Distribuidor</div>,
    id: 'distributor_id',
    accessor: (row) => (
      <div className=''>
        {row?.distributor_id
          ?
            <>
              <Tooltip>
                <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.distributor_id}`}>
                  [{row.distributor_id}] {row.distributor_name}
                </Link>
              </Tooltip>

              <p className='m-0 p-0 mt-2'>Documento: {row?.distributor_documentation || '-'}</p>
            </>
          : <i>Sin distribuidor</i>}
      </div>
    ),
    hasOrder: true,
  },
  {
    Header: <div className='text-center'>Artículos</div>,
    id: 'product_qty',
    accessor: (row) => (
      <div className='text-center'>
        {row.product_qty}

        {row?.detail &&
          <OverlayTrigger
            placement='right'
            overlay={
              <TooltipBS id={`tooltip-${row.id}`}>
                <div>
                  {row.detail.split(';').map((item, index) => (
                    <div key={index}>{item.trim()}</div>
                  ))}
                </div>
              </TooltipBS>
            }
          >
            <i className='bi bi-info-circle text-warning ms-2' />
          </OverlayTrigger>
        }
      </div>
    )
  },
  {
    Header: <div className='text-center'>Total</div>,
    id: 'total',
    accessor: (row) => (
      <div className='text-end pe-20'>
        {currencyFormat.format(row.total)}
      </div>
    )
  },
  {
    Header: <div className='text-center'>F. ingreso estimado</div>,
    id: 'estimated_in_date',
    accessor: (row) => (
      <div className='text-center'>
        {row?.estimated_in_date ? moment(row.estimated_in_date).format("DD/MM/YYYY") : '-'}
      </div>
    )
  },
  {
    Header: <div className='text-center'>F. Ingreso</div>,
    id: 'in_order_created_at',
    accessor: (row) => (
      <div className='text-center'>
        {row?.in_order_created_at ? moment(row.in_order_created_at).format("DD/MM/YYYY") : '-'}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id:'status',
    className:'col-status',
    accessor: (row) =>(
      <span className={`badge ${row.label_color} w-100 justify-content-center`}>
        {row.order_status_label}
      </span>
    )
  },
]

export {
  COLUMNS
}