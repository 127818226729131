import service from "../service"

const PATH = {
  Documents: "/transactional-documents",
  PuntosVenta: "/transactional-documents/puntos-venta",
}

export class TransactionalDocuments {

  static getDocuments = async (params) => {
    const response = await service.get(PATH.Documents, {
      params
    })
    return response;
  }

  static getDocument = async (transactional_document_type_id, id) => {
    const response = await service.get(`${PATH.Documents}/${transactional_document_type_id}/${id}`)
    return response
  }

  static printFactura = async (transactional_document_type_id, id) => {
    const response = await service.get(`${PATH.Documents}/${transactional_document_type_id}/${id}/print`, {
      responseType: 'blob',
    })
    return response
  }
  static printFacturaSimplificada = async (transactional_document_type_id, id) => {
    const response = await service.get(`${PATH.Documents}/${transactional_document_type_id}/${id}/print-summary`, {
      responseType: 'blob',
    })
    return response
  }
  static printRemito = async (id, copias) => {
    const response = await service.get(`${PATH.Documents}/remito/${id}/print?copias=${copias}`, {
      responseType: 'blob',
    })
    return response
  }

  static createCreditNote = async (transactional_document_type_id, id, params=undefined) => {
    const response = await service.post(`${PATH.Documents}/${transactional_document_type_id}/${id}/reverse-complete`, params )
    return response
  }
  
  static createPartialCreditNote = async (transactional_document_type_id, id, params=undefined) => {
    const response = await service.post(`${PATH.Documents}/${transactional_document_type_id}/${id}/reverse-partial`, params )
    return response
  }

  static getPuntosVenta = async (params) => {
    const response = await service.get(`${PATH.PuntosVenta}`, {
      params,
    });
    return response
  }

  static emitirDiferido = async (id, params=undefined) => {
    const response = await service.post(`${PATH.Documents}/${id}/emitir-diferido`, params )
    return response
  }

  static descartarDiferido = async (id, params=undefined) => {
    const response = await service.post(`${PATH.Documents}/${id}/descartar-diferido`, params )
    return response
  }
  static unificarComprobantes = async (params=undefined) => {
    const response = await service.post(`${PATH.Documents}/unificar-comprobantes`, params )
    return response
  }


  static printVisualizarComprobante = async (transactional_document_type_id, id, arrayModificaciones) => {
    const response = await service.post(
      `${PATH.Documents}/${transactional_document_type_id}/${id}/print-visualizar`, 
      {
        arrayModificaciones
      },
      {
        responseType: 'blob',
      }
    );
    return response;
  }
  

  static getVisualizarComprobante = async (transactional_document_type_id, id) => {
    try {
      const response = await service.get(`${PATH.Documents}/${transactional_document_type_id}/${id}/get-visualizar`, {
        responseType: 'json', 
      });
  
      return response.data;
    } catch (error) {
      console.error("Error al obtener el comprobante:", error);
      throw error;
    }
  }
  
  
  


}