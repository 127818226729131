import {
  FC,
  // lazy,
  Suspense,
  useEffect
} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'

import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { WithChildren } from '../../_metronic/helpers'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'

import routes from './routes'

// -- Ventas
// import { Budgets, BudgetDetail, AddBudget } from 'pages/Sells/Budgets'
import { SalesOrders, SalesOrderDetail, AddSalesOrder, AddOutRequest, OutRequestDetail } from 'pages/Sells/Orders'
import { ProfitabilityAuthorizations, CreditAuthorizations } from 'pages/Sells/OrdersAuthorizations'
import { OutOrders, OutOrderDetail } from 'pages/Sells/OutOrders'
import { Coordination } from 'pages/Sells/Coordination'
import { ListCosts } from 'pages/Sells/ListCosts'

// -- Compras
import { PurchaseRequests } from 'pages/Purchases/Requests'
import AddRequest from 'pages/Purchases/Requests/Add'
import RequestDetail from 'pages/Purchases/Requests/Detail'
import { OrdersAuthorizations } from 'pages/Purchases/Authorizations'
import { Costs, CostDetail, AddCost } from 'pages/Purchases/Costs'

// -- Logística
import { LogisticIncome, LogisticIncomeAdd } from 'pages/Logistic/Income'
import IncomeDetail from 'pages/Logistic/Income/Detail'
import { IncomeErrors } from 'pages/Logistic/IncomeErrors'

import { Waves, WaveDetail } from 'pages/Logistic/Waves'
import { ShipmentOrders, ShipmentOrderDetail } from 'pages/Logistic/ShipmentOrders'
import { DispatchOrders, DispatchOrderDetail } from 'pages/Logistic/DispatchOrders'

// -- Inventario
import BuscadorSerie from 'pages/Inventory/BuscadorSerie'
import { Articles, ArticleDetail, AddArticle } from 'pages/Inventory/Articles'
import { AddProduct, ProductDetail, Products } from 'pages/Inventory/Products'
import { Warehouse } from 'pages/Inventory/Warehouse'
import WarehouseDetail from 'pages/Inventory/Warehouse/Detail'

// -- Relaciones comerciales
import { CustomerSuppliers, CustomerSupplierDetail, AddCustomerSupplier, CustomerSupplierTaxes, CustomerSupplierDiscounts, ClientesParaRemitir } from 'pages/BusinessRelationships/CustomerSuppliers'
import { Patients, PatientDetail, AddPatient } from 'pages/BusinessRelationships/Patients'
import { ShipmentAddresses, ShipmentAddressDetail, AddShipmentAddress } from 'pages/BusinessRelationships/ShipmentAddresses'

// -- Maestros
import { Usuarios, UsuarioDetalle } from 'pages/Masters/Usuarios'
import { HealthInsurances, HealthInsuranceDetail, AddHealthInsurance } from 'pages/Masters/HealthInsurances'
import { Sellers, SellerDetail, AddSeller } from 'pages/Masters/Sellers'
import { Carriers, CarrierDetail, AddCarrier } from 'pages/Masters/Carriers'
import { PaymentConditions, PaymentConditionDetail, AddPaymentCondition } from 'pages/Masters/PaymentConditions'
import { PaymentMethods, PaymentMethodDetail, AddPaymentMethod } from 'pages/Masters/PaymentMethods'
import { DocumentTypes, DocumentTypeDetail, AddDocumentType } from 'pages/Masters/DocumentTypes'

import { AdministrativeVoucherNew, AdministrativeVouchers, AdministrativeVoucherDetail } from 'pages/Administrative/Documents'
import { LogisticActivation, LogisticActivationDetail } from 'pages/Logistic/Activations'
import { LogisticStock } from 'pages'
import { DepotMovements, DepotMovementDetail } from 'pages/Inventory/StockMovements'
import LogisticStockDetail from 'pages/Inventory/StockManagement/Detail'
import { PendingInvoiceNew, PendingInvoices } from 'pages/Administrative/PendingInvoices'
import { useAuth } from 'app/modules/auth'
import LogisticRevision from 'pages/Logistic/Revision/List'
import PurchaseStockManagement from 'pages/Purchases/StockManagement/List'
import { IncomesReport, RemitosReport, InventarioReport, AnalisisStockReport } from 'pages/Reports'
import TransactionalDocumentsReport from 'pages/Reports/TransactionalDocuments'
import VentasReport from 'pages/Reports/Ventas'
import Verifarma from 'pages/Integrations/Verifarma/List'
import GroupedInvoicesAdd from 'pages/Administrative/GroupedInvoices/Add'
import ReturnRequests from 'pages/Logistic/ReturnRequests/List'
import DetailReturnRequest from 'pages/Logistic/ReturnRequests/Detail'
import AddReturnRequest from 'pages/Logistic/ReturnRequests/Add'

const PrivateRoutes = () => {
  const { currentUser } = useAuth();

  // Lazy modules eliminados el 04/09/2024

  useEffect(()=>{
  }, [currentUser])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={routes.DASHBOARD} />} />
        {/* # DASHBOARD # */}
        <Route path={routes.DASHBOARD} element={<DashboardWrapper />} />

        {/* # VENTAS # */}
        {/* Presupuestos */}
        {/* <Route path={routes.BUDGETS} element={<Budgets />} />
        <Route path={`${routes.BUDGETS}/:id`} element={<BudgetDetail />} />
        <Route path={routes.BUDGETS_NEW} element={<AddBudget />} /> */}
        {/* Pedidos */}
        <Route path={routes.SALES_ORDERS} element={<SalesOrders />} />
        <Route path={`${routes.SALES_ORDERS}/:id`} element={<SalesOrderDetail />} />
        <Route path={routes.SALES_ORDERS_NEW} element={<AddSalesOrder />} />
        <Route path={`${routes.OUT_REQUEST}/:id`} element={<OutRequestDetail />} />
        <Route path={routes.OUT_REQUEST_NEW} element={<AddOutRequest />} />
        {/* Autorizaciones */}
        <Route path={routes.PROFITABILITY_AUTHORIZATIONS} element={<ProfitabilityAuthorizations />} />
        <Route path={routes.CREDIT_AUTHORIZATIONS} element={<CreditAuthorizations />} />
        {/* Costos de lista */}
        <Route path={routes.LIST_COSTS} element={<ListCosts />} />

        {/* Órdenes de egreso */}

        {/* # COMPRAS # */}
        <Route path={routes.PURCHASE_REQUESTS} element={<PurchaseRequests />} />
        <Route path={routes.PURCHASE_REQUEST_NEW} element={<AddRequest />} />
        <Route path={routes.INCOME_REQUEST_NEW} element={<AddRequest income />} />
        <Route path={`${routes.PURCHASE_REQUEST_DETAIL}/:id`} element={<RequestDetail />} />
        <Route path={`${routes.INCOME_REQUEST_DETAIL}/:id`} element={<RequestDetail income/>} />
        <Route path={routes.AUTHORIZATIONS} element={<OrdersAuthorizations />} />
        {/* Costos de lista especial */}
        <Route path={routes.COSTS} element={<Costs />} />
        <Route path={`${routes.COST_CUSTOMER}/:id`} element={<CostDetail />} />
        <Route path={`${routes.COST_PRODUCT}/:id`} element={<CostDetail />} />
        <Route path={routes.COSTS_NEW} element={<AddCost />} />
        <Route path={routes.PURCHASE_STOCK_MANAGEMENT} element={<PurchaseStockManagement />} />

        {/* # LOGÍSTICA # */}
        {/* Ingreso */}
        <Route path={routes.LOGISTIC_INCOME} element={<LogisticIncome />} />
        <Route path={routes.LOGISTIC_INCOME_NEW} element={<LogisticIncomeAdd />} />
        <Route path={`${routes.LOGISTIC_INCOME}/:id`} element={<IncomeDetail />} />
        <Route path={routes.LOGISTICS_ACTIVATION} element={<LogisticActivation />} />
        <Route path={`${routes.LOGISTICS_ACTIVATION}/:id`} element={<LogisticActivationDetail />} />
        {/* Egreso */}
        <Route path={routes.OUT_ORDERS} element={<OutOrders />} />
        <Route path={`${routes.OUT_ORDERS}/:id`} element={<OutOrderDetail />} />
        {/* Coordinación */}
        <Route path={routes.COORDINATION} element={<Coordination />} />
        {/* Revision */}
        <Route path={routes.LOGISTICS_REVISION} element={<LogisticRevision />} />
        {/* Olas */}
        <Route path={routes.WAVES} element={<Waves />} />
        <Route path={`${routes.WAVES}/:id`} element={<WaveDetail />} />
        <Route path={`${routes.WAVES}/:id/orden/:order_id`} element={<IncomeDetail />} />
        {/* Órdenes de envio */}
        <Route path={routes.LOGISTICS_SHIPMENT_ORDERS} element={<ShipmentOrders />} />
        <Route path={`${routes.LOGISTICS_SHIPMENT_ORDERS}/:id`} element={<ShipmentOrderDetail />} />
        {/* Órdenes de despacho */}
        <Route path={routes.LOGISTICS_DISPATCH_ORDERS} element={<DispatchOrders />} />
        <Route path={`${routes.LOGISTICS_DISPATCH_ORDERS}/:id`} element={<DispatchOrderDetail />} />
        {/* Pedidos de devolución */}
        <Route path={`${routes.RETURN_REQUEST}/:id`} element={<DetailReturnRequest />} />
        <Route path={`${routes.RETURN_REQUEST}`} element={<ReturnRequests />} />
        <Route path={routes.RETURN_REQUEST_NEW} element={<AddReturnRequest />} />

        {/* # INVENTARIO # */}
        <Route path={routes.BUSCADOR_SERIE} element={<BuscadorSerie />} />
        <Route path={routes.ARTICLES} element={<Articles />} />
        <Route path={`${routes.ARTICLES}/:id`} element={<ArticleDetail />} />
        <Route path={routes.ARTICLES_NEW} element={<AddArticle />} />
        <Route path={routes.PRODUCTS} element={<Products />} />
        <Route path={`${routes.PRODUCTS}/:id`} element={<ProductDetail />} />
        <Route path={routes.PRODUCTS_NEW} element={<AddProduct />} />
        <Route path={routes.WAREHOUSE} element={<Warehouse />} />
        <Route path={`${routes.WAREHOUSE}/:id`} element={<WarehouseDetail />} />
        {/* Stock */}
        <Route path={routes.INVENTORY_STOCK} element={<LogisticStock />} />
        <Route path={`${routes.INVENTORY_STOCK}/:id`} element={<LogisticStockDetail />} />
        <Route path={routes.INVENTORY_MOVEMENTS} element={<DepotMovements />} />
        <Route path={`${routes.INVENTORY_MOVEMENTS}/:id`} element={<DepotMovementDetail />} />

        {/* REPORTES */}
        <Route path={`${routes.REPORTE_INGRESOS}`} element={<IncomesReport />} />
        <Route path={`${routes.REPORTE_COMPROBANTES}`} element={<TransactionalDocumentsReport />} />
        <Route path={`${routes.REPORTE_VENTAS}`} element={<VentasReport />} />
        <Route path={`${routes.REPORTE_REMITOS}`} element={<RemitosReport />} />
        <Route path={`${routes.REPORTE_INVENTARIO}`} element={<InventarioReport />} />
        <Route path={`${routes.REPORTE_ANALISIS_STOCK}`} element={<AnalisisStockReport />} />

        {/* RELACIONES COMERCIALES */}
        {/* Clientes proveedores */}
        <Route path={routes.CUSTOMER_SUPPLIERS} element={<CustomerSuppliers />} />
        <Route path={`${routes.CUSTOMER_SUPPLIERS}/:id`} element={<CustomerSupplierDetail />} />
        <Route path={routes.CUSTOMER_SUPPLIERS_NEW} element={<AddCustomerSupplier />} />
        <Route path={`${routes.CUSTOMER_SUPPLIERS}/:id/impuestos`} element={<CustomerSupplierTaxes />} />
        <Route path={`${routes.CUSTOMER_SUPPLIERS}/:id/descuentos`} element={<CustomerSupplierDiscounts />} />
        <Route path={`${routes.CUSTOMER_SUPPLIERS}/:id/clientes-para-remitir`} element={<ClientesParaRemitir />} />
        {/* Pacientes */}
        <Route path={routes.PATIENTS} element={<Patients />} />
        <Route path={`${routes.PATIENTS}/:id`} element={<PatientDetail />} />
        <Route path={routes.PATIENTS_NEW} element={<AddPatient />} />
        {/* Direcciones de entrega */}
        <Route path={routes.SHIPMENT_ADDRESSES} element={<ShipmentAddresses />} />
        <Route path={`${routes.SHIPMENT_ADDRESSES}/:id`} element={<ShipmentAddressDetail />} />
        <Route path={routes.SHIPMENT_ADDRESSES_NEW} element={<AddShipmentAddress />} />

        {/* MAESTRO */}
        {/* Usuarios */}
        <Route path={routes.USERS} element={<Usuarios />} />
        <Route path={`${routes.USERS}/:id`} element={<UsuarioDetalle />} />
        {/* Obras sociales */}
        <Route path={routes.HEALTH_INSURANCES} element={<HealthInsurances />} />
        <Route path={`${routes.HEALTH_INSURANCES}/:id`} element={<HealthInsuranceDetail />} />
        <Route path={routes.HEALTH_INSURANCES_NEW} element={<AddHealthInsurance />} />
        {/* Vendedores */}
        <Route path={routes.SELLERS} element={<Sellers />} />
        <Route path={`${routes.SELLERS}/:id`} element={<SellerDetail />} />
        <Route path={routes.SELLERS_NEW} element={<AddSeller />} />
        {/* Transportistas */}
        <Route path={routes.CARRIERS} element={<Carriers />} />
        <Route path={`${routes.CARRIERS}/:id`} element={<CarrierDetail />} />
        <Route path={routes.CARRIERS_NEW} element={<AddCarrier />} />
        {/* Métodos de pago */}
        <Route path={routes.PAYMENT_METHODS} element={<PaymentMethods />} />
        <Route path={`${routes.PAYMENT_METHODS}/:id`} element={<PaymentMethodDetail />} />
        <Route path={routes.PAYMENT_METHODS_NEW} element={<AddPaymentMethod />} />
        {/* Condiciones de pago */}
        <Route path={routes.PAYMENT_CONDITIONS} element={<PaymentConditions />} />
        <Route path={`${routes.PAYMENT_CONDITIONS}/:id`} element={<PaymentConditionDetail />} />
        <Route path={routes.PAYMENT_CONDITIONS_NEW} element={<AddPaymentCondition />} />
        {/* Tipos de documento */}
        <Route path={routes.DOCUMENT_TYPES} element={<DocumentTypes />} />
        <Route path={`${routes.DOCUMENT_TYPES}/:id`} element={<DocumentTypeDetail />} />
        <Route path={routes.DOCUMENT_TYPES_NEW} element={<AddDocumentType />} />

        {/* # INTEGRACIONES # */}
        <Route path={routes.INTEGRATION_ANMAT_ERRORS} element={<IncomeErrors />} />
        <Route path={routes.INTEGRATION_VERIFARMA} element={<Verifarma />} />

        {/* # ADMINISTRACION # */}
        {/* Comprobantes */}
        <Route path={routes.ADMINISTRATIVE_VOUCHERS} element={<AdministrativeVouchers />} />
        <Route path={routes.ADMINISTRATIVE_VOUCHER_NEW} element={<AdministrativeVoucherNew />} />
        <Route path={`${routes.ADMINISTRATIVE_VOUCHER}/:id/:transactional_document_type_id`} element={<AdministrativeVoucherDetail />} />
        <Route path={routes.ADMINISTRATIVE_PENDING_INVOICES} element={<PendingInvoices />} />
        <Route path={routes.ADMINISTRATIVE_PENDING_INVOICES_NEW} element={<PendingInvoiceNew />} />
        {/* <Route path={routes.ADMINISTRATIVE_GROUPED_INVOICES_NEW} element={<GroupedInvoicesAdd />} /> */}
        <Route path={routes.ADMINISTRATIVE_GROUPED_INVOICES} element={<GroupedInvoicesAdd />} />

        {/* Lazy Modules */}
        {/* Eliminados el 04/09/2024 */}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

// const SuspensedView: FC<WithChildren> = ({ children }) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
// }

export {PrivateRoutes}
