import React from 'react'
import { Formik, Form } from 'formik'
import { Input } from 'components'
import { Button } from 'react-bootstrap'

const formikInitialValues = {
  serial_number: '',
}

const Filter = ({ loading, query, setQuery }) => {
  const handleSubmit = (values) => {
    const params = {
      ...query,
      ...values,
      serial_number: values?.serial_number || undefined,
    }

    setQuery(params)
  }

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={handleSubmit}
    >
      {({ values, errors }) => (
        <Form>
          <div className='row mt-6'>
            <div className='col-6'>
              <Input id='serial_number' name='serial_number' disabled={loading} />
              <p className='text-muted'>Nro. de serie</p>
            </div>
          </div>

          <div className='row my-6'>
            <div className='col text-end'>
              <Button type='submit' variant='primary'>
                <i className='bi bi-search mb-1' />
                {loading ? 'Buscando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter