import * as Yup from 'yup'
import moment from 'moment'

import { currentDate, formatInputDate } from "utilities"
import { HomeDeliveryOptionI } from 'interfaces'

const initialValues = {
  id: '',
  // -- General
  out_order_status_id: '',
  out_order_status_name: '',
  out_order_status_label: '',
  sales_order_id: '',
  sales_order: '',
  customer_supplier_id: '',
  customer_supplier_business_name: '',
  business_unit_id: '',
  seller_id: '',
  payment_condition_id: '',
  issue_date: currentDate,
  de_blister: false,
  bidding: false,
  bidding_value: '',
  billing_type_id: '',
  billing_date: '',
  purchase_order: '',
  // -- Articles
  search_product_var: '',
  detail: [],
  price_update_id: '',
  delivery_cost: 0,
  total: 0,
  total_profitability: 0,
  total_delivery_cost: 0,
  // -- Addressee
  requires_coordination: false,
  to_patient: false,
  remito_para_terceros: false,
  home_delivery_option_id: '',
  patient_id: '',
  id_tercero_para_remitir: '',
  patient_name: '',
  tercero_para_remitir: '',
  address_id_ship: '',
  // shipment_address_id_home_delivery: '',
  address_id_traza: '',
  observations: '',
  dispatch_date: '',
  dispatch_observations: '',
  // -- Audit
  user_creation_name: '',
  created_at: '',
  created_by_user_id: '',
  updated_at: '',
  updated_by_user_id: '',
  user_update_name: '',
  // canceled_at: '',
  // canceled_by_user_id: '',
  order_type_id: null,
  coordination_status_id: '',
  coordination_status: '',
  coordination_user_id: '',
  coordination_user: '',
}

const schema = Yup.object().shape({
  // -- General
  budget_id: Yup.number(),
  customer_supplier_id: Yup.number().required('* Seleccione un cliente').nonNullable(),
  seller_id: Yup.number().required('* Seleccione un vendedor').nonNullable(),
  payment_condition_id: Yup.number().required('* Seleccione una condición de pago').nonNullable(),
  issue_date: Yup.string().required('* Ingrese una fecha de emisión').nonNullable(),
  dispatch_date: Yup.string(),
  de_blister: Yup.boolean().default(false),
  // -- Articles
  // detail:
  // -- Addressee
  requires_coordination: Yup.boolean().default(false),
  to_patient: Yup.boolean().default(false),
  remito_para_terceros: Yup.boolean().default(false),
  patient_id: Yup.number().when(['to_patient'], {
    is: (to_patient) => to_patient,
    then: (schema) => schema.required('* Seleccione un paciente').nonNullable(),
    otherwise: (schema) => schema,
  }),
  id_tercero_para_remitir: Yup.number().when(['remito_para_terceros'], {
    is: (remito_para_terceros) => remito_para_terceros,
    then: (schema) => schema.required('* Seleccione un tercero para remitir').nonNullable(),
    otherwise: (schema) => schema,
  }),
  address_id_ship: Yup.number().when(['to_patient', 'home_delivery_option_id'], {
    is: (to_patient, home_delivery_option_id) => to_patient && home_delivery_option_id === HomeDeliveryOptionI.PACIENTE,
    then: (schema) => schema.required('* Seleccione una dirección de entrega').nonNullable(),
    otherwise: (schema) => schema,
  }),
  // shipment_address_id_home_delivery: Yup.number().when(['home_delivery_option_id'], {
  //   is: (home_delivery_option_id) => home_delivery_option_id === HomeDeliveryOptionI.CLIENTE,
  //   then: (schema) => schema.required('* Seleccione una dirección de entrega').nonNullable(),
  //   otherwise: (schema) => schema,
  // }),
  address_id_traza: Yup.number().when(['home_delivery_option_id'], {
    is: (home_delivery_option_id) => (home_delivery_option_id === HomeDeliveryOptionI.PACIENTE || Number(home_delivery_option_id) === HomeDeliveryOptionI.CLIENTE),
    then: (schema) => schema.required('* Seleccione una dirección de trazabilidad').nonNullable(),
    otherwise: (schema) => schema,
  }),
  observations: Yup.string().nullable(),
  dispatch_observations: Yup.string().nullable(),
});

const detailDataAdapter = (data) => {
  const detail = data.map(article => {
    return {
      id: article.id,
      product_var_id: article.product_var_id,
      product_var_desc: article.name,
      line: article?.line ? article.line : '',
      actual_pvp: article.pvp,
      pvp_original: article.pvp_original,
      list_cost: article.list_cost,
      qty: article.qty,
      units: article.units,
      discount: parseFloat(article.discount),
      price: article.price,
      profitability: article.profitability,
      profitability_rate: article.profitability_rate,
      vat: article.vat,
      vat_rate: article.vat_rate,
      total: +article.subtotal + +article.vat_amount,
      traced: article.traced,
      gtin: article.gtin,
      subtotal:article.subtotal,
      vat_amount:article.vat_amount,
      returned_qty:article.returned_qty
    }
  })

  return detail
}

const dataAdapter = (values) => {
  const detail = detailDataAdapter(values.detail)

  let data = {
    ...values,
    // -- General
    sales_order_id: values.sales_order_id,
    sales_order: values.sales_order_id ? `Pedido #${values.sales_order_id}` : '',
    customer_supplier_business_name: values.customer_supplier_business_name,
    business_unit_id: values.business_unit_id,
    issue_date: formatInputDate(values.issue_date),
    de_blister: Boolean(values.de_blister),
    billing_type_id: values.billing_type_id,
    billing_date: formatInputDate(values.billing_date),
    // -- Articles
    lines: values.lines,
    price_update_id: Number(values.price_update_id),
    detail: detail,
    delivery_cost: Number(values.delivery_cost).toFixed(2),
    total: Number(values.total).toFixed(2),
    total_profitability: Number(values.total_profitability).toFixed(5),
    total_delivery_cost: (Number(values.total) + Number(values.delivery_cost)).toFixed(2),
    // -- Addressee
    dispatch_date: values?.dispatch_date ? moment(values.dispatch_date).format('YYYY-MM-DD') : '',
    requires_coordination: Boolean(values.requires_coordination),
    to_patient: Boolean(values.to_patient),
    remito_para_terceros: Boolean(values.remito_para_terceros),
    home_delivery_option_id: Number(values.home_delivery_option_id),
    patient_id: values?.patient_id ? values.patient_id : '',
    patient_name: values?.patient_id ? `[${values.patient_id}] ${values?.patient_documentation ? `${values.patient_documentation} - ${values.patient_name}` : `${values.patient_name}`}${values?.patient_affiliate_number ? ` | Nro. afiliado: ${values.patient_affiliate_number}` : ''}` : '',
    id_tercero_para_remitir: values?.id_tercero_para_remitir ? values.id_tercero_para_remitir : '',
    tercero_para_remitir: values?.id_tercero_para_remitir ? values.tercero_para_remitir : '',
    address_id_ship: values?.address_id_ship ? values.address_id_ship : '',
    shipment_address_id_home_delivery: values?.shipment_address_id_home_delivery ? values.shipment_address_id_home_delivery : '',
    address_id_traza: values?.address_id_traza ? values.address_id_traza : '',
    observations: values?.observations ? values.observations : '',
    // dispatch_date: formatInputDate(values.dispatch_date),
    dispatch_observations: values?.dispatch_observations ? values.dispatch_observations : '',
    created_at: formatInputDate(values.created_at),
    updated_at: formatInputDate(values.updated_at),
    canceled_at: formatInputDate(values.canceled_at),
    coordination_status_id: values?.coordination_status || '',
    // coordination_status: values?.coordination_status || '',
    coordination_user_id: values?.coordination_user_id,
    coordination_user: values?.coordination_user,
    direccion_traza:
      values?.address_id_traza
        ? `${values?.traza_name} - ${values?.traza_address?.trim()}, ${values?.traza_full_locality?.trim()}${values?.traza_gln ? ` | GLN: ${values.traza_gln}` : ''}`
        : (values?.home_delivery_option_id == HomeDeliveryOptionI.NO || values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA)
          ? 'Seleccione una dirección de entrega'
          : 'Seleccione una dirección de trazabilidad',
    direccion_ship:
      values?.address_id_ship
        ? `${values?.ship_name} - ${values?.ship_address?.trim()}, ${values?.ship_full_locality?.trim()}${values?.ship_gln ? ` | GLN: ${values.ship_gln}` : ''}`
        : 'Seleccione un domicilio de entrega',
  }
  // delete data.customer_supplier_business_name

  return data
}

const updateDataAdapter = (data) => {
  let values = {
    ...data,
    sales_order_id: data.sales_order_id,
    delivery_cost: Number(data.delivery_cost),
    patient_id: data?.patient_id ? data.patient_id : null,
    id_tercero_para_remitir: data?.id_tercero_para_remitir ? data.id_tercero_para_remitir : null,
    address_id_traza: data?.address_id_traza ? data.address_id_traza : null,
    address_id_ship: (data.home_delivery_option_id == 1 || data.home_delivery_option_id == 4) // Entrega especial: NO - FARMACIA
      ? data.address_id_traza
      : data?.address_id_ship
        ? data.address_id_ship
        : null,
    shipment_address_id_home_delivery: data?.shipment_address_id_home_delivery ? data.shipment_address_id_home_delivery : null,
    coordination_status_id: data.coordination_status
    // coordination_status: data.coordination_status,
  }

  delete values.sales_order
  delete values.out_order_status_label
  delete values.out_order_status_name
  delete values?.customer_supplier_business_name
  delete values?.customer_supplier_name
  delete values.seller_name
  delete values.search_product_var
  delete values.detail
  delete values.lines
  delete values.total
  delete values.delivery_cost
  delete values?.patient_documentation
  delete values?.patient_affiliate_number
  delete values?.patient_name
  delete values?.patient_phone
  delete values?.patient_mobile
  delete values?.tercero_para_remitir
  delete values.created_at
  delete values.canceled_at
  delete values.canceled_by_user_id
  delete values.user_creation_name
  delete values.user_update_name

  delete values.bidding
  delete values.bidding_value

  delete values.total
  delete values.total_profitability
  delete values.total_delivery_cost
  delete values.created_by_user_id
  delete values.updated_at
  delete values.sale_document_id
  delete values.coordination_status_name
  delete values.coordination_user

  delete values.direccion_traza
  delete values.direccion_ship

  return values
}

const filterNewArticles = (detail, newArticles) => {
  const ids = detail.map(d => d.product_var_id)

  const newFilteredArticles = newArticles.filter((newArticle) => !ids.includes(newArticle.id))

  return newFilteredArticles
}

const vatOptions = [
  { value: 1, label: 'No Gravado', percent: 0 },
  { value: 2, label: 'Exento', percent: 0 },
  { value: 3, label: '0%', percent: 0 },
  { value: 9, label: '2.5%', percent: 2.5 },
  { value: 8, label: '5%', percent: 5 },
  { value: 4, label: '10.5%', percent: 10.5 },
  { value: 5, label: '21%', percent: 21 },
  { value: 6, label: '27%', percent: 27 }
]



export {
  initialValues,
  schema,
  detailDataAdapter,
  dataAdapter,
  updateDataAdapter,
  filterNewArticles,
  vatOptions,
}