import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

// Función para organizar los IDs en filas y columnas
const createTableStructure = (ids, columnsLimit) => {
  const rows = Math.ceil(ids.length / columnsLimit)
  const table = []

  for (let i = 0; i < rows; i++) {
    table.push(ids.slice(i * columnsLimit, (i + 1) * columnsLimit))
  }

  return table
}

const AgrupacionModal = ({ show, onClose, title, ids, pedido = true }) => {
  const [columnsLimit, setColumnsLimit] = useState(10)
  // Creamos la tabla a partir de los IDs y el límite de columnas
  const tableStructure = createTableStructure(ids, columnsLimit)

  return (
    <Modal show={show} onHide={onClose} backdrop='static' size='lg' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Agrupamiento de {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='table-responsive'>
          <table className='table table-bordered'>
            <tbody>
              {tableStructure.map((row, rowIndex) => (
                <tr key={`row-${rowIndex}`}>
                  {row.map((id, colIndex) => (
                    <td key={`col-${colIndex}`} className='text-center fs-8 p-0 py-2'>
                      <Tooltip text={`Ir a ${pedido ? 'pedido' : 'orden'}`}>
                        <Link to={`${routes[pedido ? 'SALES_ORDERS' : 'OUT_ORDERS']}/${id}`} target='__blank'>
                          {id}
                        </Link>
                      </Tooltip>
                    </td>
                  ))}
                  {/* Asegurar que la fila tenga el número correcto de columnas */}
                  {row.length < columnsLimit &&
                    Array(columnsLimit - row.length).fill().map((_, idx) => <td key={`empty-${idx}`} />)
                  }
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type='button' variant='primary' onClick={onClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AgrupacionModal