import { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Input, SelectField, SwitchField } from 'components'
import moment from 'moment'
import { BorderColor } from '@mui/icons-material'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useCustomerSuppliers, useOutOrders } from 'hooks'

const initialValues = {
    nombre: '',
    from_date: moment().subtract(7, 'd').format('YYYY-MM-DD'),
    to_date: moment().format('YYYY-MM-DD'),
}

const Filter = ({ setData, setFilter, queryMD, setQueryMD, exportData  }) => {
  const [submitted, setSubmitted] = useState(false)
  const [customerSuppliers, setCustomerSuppliers] = useState([])
  const { handleGetCustomerSuppliers, loading } = useCustomerSuppliers()
  const {loading:loadingOutOrders} = useOutOrders();

  const getCustomerSuppliers = async () => {
    try {
      const response = await handleGetCustomerSuppliers('token', '', { is_enabled: 1, is_customer: 1})
      setCustomerSuppliers(response.data.result)
    } catch (error) {
      setCustomerSuppliers([])
      toast.error(error.message)
    }
  }

  const handleSubmit = async (values) => {
      let params = {
        ...values,
        from_date: values?.from_date? moment(values.from_date).format("YYYY-MM-DD") : undefined,
        to_date: values?.to_date? moment(values.to_date).format("YYYY-MM-DD") : undefined,
        nombre: values?.nombre || undefined,
      }

      try {
        setSubmitted(true)
        if(!params.nombre || params.nombre.trim()=='')
            return
        // const response = await handleGetDocuments(params)
        setQueryMD({ ...queryMD, ...params, pageNumber: 1 })
        // setData(response.data.result)
        setSubmitted(false)
      } catch (error) {
        toast.error(error.message)
        setSubmitted(false)
      }

  }


  useEffect(()=>{
    getCustomerSuppliers();
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>

          {
            loading &&
            <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>
          }

          <div className='row'>
            <div className='col-6'>
              {/* <Input id='nombre' name='nombre' autoComplete="off" 
                  className={`form-control ${submitted && (!values.nombre || values.nombre.trim()=='') ? 'border-danger':''}`}/> */}
              
              <Typeahead
                id='customer_supplier_id'
                name='customer_supplier_id'
                minLength={1}
                labelKey={option => `#${option.id} | ${option.business_name}`}
                filters={['id', 'business_name']}
                options={customerSuppliers}
                promptText='Buscar cliente'
                searchText='Buscando...'
                emptyLabel='No se encontró el cliente'
                onChange={(selected) => {
                  if (!selected || selected.length === 0) {
                    setFieldValue('customer_supplier_id', '')
                    setFieldValue('nombre', '')
                  } else {
                    const value = selected[0]
                    setFieldValue('customer_supplier_id', value.id)
                    setFieldValue('nombre', value.business_name)
                  }
                }}
                defaultInputValue={values.nombre}
                disabled={loading || loadingOutOrders}
              />   
              
              <p className='text-muted'>Cliente</p>
            </div>
            <div className='col-3'>
              <Input id='from_date' name='from_date' type='date' disabled={loading || loadingOutOrders}/>
              <p className='text-muted'>Desde</p>
            </div>

            <div className='col-3'>
              <Input id='to_date' name='to_date' type='date' disabled={loading || loadingOutOrders}/>
              <p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 text-end'>
              {/* <Button variant="secondary" type='button' onClick={() => exportData()} className="me-3">
                <i className="bi bi-file-earmark-excel mb-1"/>
                Exportar
              </Button> */}

              <Button  variant="primary" type='submit' onClick={()=>{}} disabled={loading || loadingOutOrders}>
                <i className="bi bi-search mb-1"/>
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter