import { useState, useCallback } from 'react'

import { Waybill } from 'services'

const useWaybill = () => {
  const [loading, setLoading] = useState(false)

  const handleGetShipmentOrders = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waybill.getShipmentOrders(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetShipmentOrder = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Waybill.getShipmentOrder(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetShipmentOrderDetail = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Waybill.getShipmentOrderDetail(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateShipmentOrderShippingCarrierService = useCallback(async (id, shipping_carrier_service_id) => {
    try {
      setLoading(true)
      const response = await Waybill.updateShipmentOrderShippingCarrierService(id, shipping_carrier_service_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintDispatchGuide = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Waybill.printDispatchGuide(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  // -- Dispatch orders
  const handleGetDispatchOrders = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waybill.getDispatchOrders(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDispatchOrder = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waybill.getDispatchOrder(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateDispatchOrder = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Waybill.createDispatchOrder(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])
  
  const handleCreateDispatchOrderGrouped = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Waybill.createDispatchOrderGrouped(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancelDispatchOrder = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Waybill.cancelDispatchOrder(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleRemoveOrderDeliveryFromDispatch = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waybill.removeOrderDeliveryFromDispatch(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])


  const handleConfirmDispatch = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Waybill.confirmDispatch(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddToDispatch = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Waybill.addToDispatch(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDispatchOrdersPending = useCallback(async () => {
    try {
      setLoading(true)
      const response = await Waybill.getDispatchOrdersPending()

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleMarkOrderAsDelivered = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Waybill.markOrderAsDelivered(data)
      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDeliveryResult = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Waybill.updateDelivery(data)
      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    // -- Shipment orders
    handleGetShipmentOrders,
    handleGetShipmentOrder,
    handleGetShipmentOrderDetail,
    handleUpdateShipmentOrderShippingCarrierService,
    handlePrintDispatchGuide,
    // -- Dispatch orders
    handleGetDispatchOrders,
    handleGetDispatchOrder,
    handleCreateDispatchOrder,
    handleCancelDispatchOrder,
    handleRemoveOrderDeliveryFromDispatch,
    handleConfirmDispatch,
    handleGetDispatchOrdersPending,
    handleCreateDispatchOrderGrouped,
    handleMarkOrderAsDelivered,
    handleUpdateDeliveryResult,
    handleAddToDispatch
    
  }
}

export default useWaybill