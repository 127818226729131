import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'

import { Input } from 'components'

const Filter = ({ loading, query, setQuery }) => {
  const handleFilter = (values) => {
    let params = {
      ...query,
      ...values,
      id: values?.id || undefined,
      text: values?.text || undefined,
      documentation: values?.documentation || undefined,
    }

    setQuery({ ...params, pageNumber: 1, pageSize: 10 })
  }

  return (
    <Formik
      initialValues={{
        id: '',
        text: '',
        documentation: '',
      }}
      onSubmit={handleFilter}
    >
      {({ values, errors, handleSubmit }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSubmit(); // Realizar la acción del filtro aquí
            }
          }}
        >
          <div className='row'>
            <div className='col-2'>
              <Input id='id' name='id' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-6'>
              <Input id='text' name='text' disabled={loading} />
              <p className='text-muted'>Nombre</p>
            </div>

            <div className='col-4'>
              <Input id='documentation' name='documentation' disabled={loading} />
              <p className='text-muted'>Documento / CUIT</p>
            </div>
          </div>

          <div className='row'>
            <div className='col text-end'>
              <Button
                type='button'
                variant='primary'
                onClick={() => {
                  handleSubmit()
                }}
                disabled={loading}
              >
                <i className='bi bi-search' />
                {loading ? 'Buscando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter