
import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { useOutOrders, useRequest } from 'hooks'
import {  Input, SelectField, Textarea, SwitchField } from 'components'
import { OutOrderSelectionModal } from './OutOrderSelectionModal/OutOrderSelectionModal';


const GeneralTab = ({ customers, loading}) => {
  const { values, setFieldValue } = useFormikContext()
  const [showOESelectionModal, setShowOESelectionModal] = useState(false)
  const [returnedReasons, setReturnedReasons] = useState([])
  const {handleGetOutOrder, loading:loadingOE} = useOutOrders();
  const {handleGetRequestReturnedReason} = useRequest();

  const hideOrderSelectionModal = async(data)=>{

    setShowOESelectionModal(false)
    if(data){
      const resp = await handleGetOutOrder(data.id)
      if(resp?.data?.result){
        const outOrder = resp.data.result
        setFieldValue('id', outOrder.id)
        setFieldValue('supplier_id', outOrder.customer_supplier_id)
        setFieldValue('business_unit_id', outOrder.business_unit_id)
        setFieldValue('observations', outOrder.observations || '')
        setFieldValue('issue_date', new Date(outOrder.issue_date).toISOString().split('T')[0])
        setFieldValue('total', outOrder.total? `$ ${parseFloat(outOrder.total).toFixed(2)}`:'')
        setFieldValue('detail', outOrder.detail.map(d=>({...d, devolucion:d.qty})))
      }
    }
  }

  const fetchReturnedReasons = async()=>{
      const data = await handleGetRequestReturnedReason()
      setReturnedReasons(data.result.map(r=>({
        ...r, 
        value:r.id, 
        label:r.reason + ' ' + r.detail + ' (' + r.sector + ')'
      })))
  }

  useEffect(() => {
    setShowOESelectionModal(false)
    fetchReturnedReasons()
  }, [])


  return (
    <>

      <OutOrderSelectionModal show={showOESelectionModal} onHide={hideOrderSelectionModal} />

      <div className='row'>
        <div className='col mb-4 text-end'>
          <Button variant="secondary" type='button' className='me-3' onClick={()=>setShowOESelectionModal(true)} disabled={loadingOE}>
            <i className="bi bi-file-earmark-arrow-down"/>
            Seleccionar OE
          </Button>
          <Button variant="primary" type='submit' disabled={loadingOE || !values?.id}>
            <i className="bi bi-save"/>
            Guardar
          </Button>
        </div>
      </div>
      { (loading || loadingOE) && (
        <div className="custom-overlay-layer d-flex justify-content-center align-items-center">
          <div className="spinner-border text-success" role="status"></div>
        </div>
      )}   
      
      <div className='row mb-10 mt-8'>
        <div className='col-6'>
          {values?.id ? `Órden de egreso #${values.id}` : ''}
        </div>
      </div>

      <div className='row mb-10 mt-8'>
        <div className='col-6'>
          <SelectField
            label={'Cliente'}
            name="supplier_id"
            options={customers}
            marginTop='3px'
            isMulti={false}
            placeholder='Seleccione el cliente'
            handleChange={()=>{}}
            disabled={true}
          />
        </div>
        <div className="col-6">
            <SelectField
              label={'Motivo de devolución'}
              name="returned_request_reason_id"
              options={returnedReasons}
              marginTop='3px'
              isMulti={false}
              placeholder='Seleccione el motivo'
              handleChange={()=>{}}
              disabled={loadingOE || !values?.id}
            />
        </div>
      </div>
      <div className='row mb-10'>
        <div className="col-3">
          <label htmlFor='issue_date' className='form-label'>Fecha comprobante</label>
          <Input id='issue_date' name='issue_date' type='date' disabled />
        </div>
        <div className="col-3">
          <label htmlFor='total' className='form-label'>Monto total</label>
          <Input id='total' name='total' type='text' disabled={true} className="form-control text-end"/>
        </div>
      </div>

      <div className='row mb-20'>
        <div className="col">
          <label htmlFor='notes' className='form-label'>Observaciones</label>
          <Textarea id='observations' name='observations' 
            disabled={true}/>
        </div>
        <div className="col">
          <label htmlFor='internal_notes' className='form-label'>Notas internas</label>
          <Textarea id='internal_notes' name='internal_notes' 
            disabled={!values?.id}/>
        </div>
      </div>

    </>
  )
}

export default GeneralTab