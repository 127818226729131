import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useCustomerSuppliers, useClickOutside } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './columns'
import { Button } from 'react-bootstrap'

import AñadirClienteModal from './AñadirClienteModal'
import Filter from './Filter'
import Swal from 'sweetalert2'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const ClientesParaRemitir = () => {
  const { id } = useParams()

  const [modal, setModal] = useState(false)

  const { loading, handleGetClientesParaRemitir, handleUpdateClienteParaRemitir, handleDeleteClienteParaRemitir } = useCustomerSuppliers()
  const [data, setData] = useState([])
  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getClientesParaRemitir = useCallback(async () => {
    try {
      let params = {
        ...query,
        id_cliente: id,
      }

      const response = await handleGetClientesParaRemitir(params)
      const data = response.data.result
      const count = response.data.metadata.count

      setData(data)
      setTotalCount(count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [id, handleGetClientesParaRemitir, query])

  const closeModal = async () => {
    setModal(false)
    await getClientesParaRemitir()
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: Boolean(currentRow?.activo) ? 'Desactivar' : 'Activar',
      icon: `bi bi-${Boolean(currentRow?.activo) ? 'x-circle text-warning' : 'check-circle text-success'}`,
      action: updateClienteParaRemitir
    })

    actions.push({
      label: 'Eliminar',
      icon: 'bi bi-trash text-danger',
      action: deleteClienteParaRemitir
    })

    return actions
  }

  const updateClienteParaRemitir = async () => {
    if (currentRow) {
      try {
        const resp = await Swal.fire({
          title: "Actualizar cliente para remitir",
          text: `¿${Boolean(currentRow.activo) ? 'Desactivar' : 'Activar'} cliente para remitir #${currentRow.id_cliente_para_remitir}`,
          icon: 'warning',
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          confirmButtonText: `Si, ${Boolean(currentRow.activo) ? 'desactivar' : 'activar'}`,
          customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          const params = {
            activo: !Boolean(currentRow.activo)
          }
          const response = await handleUpdateClienteParaRemitir(currentRow.id, params)
          toast.success(response.data.message)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        await getClientesParaRemitir()
      }
    }
  }

  const deleteClienteParaRemitir = async () => {
    if (currentRow) {
      try {
        const resp = await Swal.fire({
          title: "Eliminar cliente para remitir",
          text: `¿Eliminar relación con cliente para remitir #${currentRow.id_cliente_para_remitir}?`,
          icon: 'warning',
          showDenyButton: true,
          denyButtonText: 'No, cancelar',
          confirmButtonText: 'Si, eliminar',
          customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          const response = await handleDeleteClienteParaRemitir(currentRow.id)
          toast.success(response.data.message)
        }
      } catch (error) {
        toast.error(error.message)
      } finally {
        await getClientesParaRemitir()
      }
    }
  }
  /* Fin de menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i id='threeDots' ref={threeDotsRef} className="bi bi-three-dots-vertical" style={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getClientesParaRemitir()
  }, [getClientesParaRemitir])

  return (
    <DetailContainer title='Clientes para remitir'>
      <AñadirClienteModal show={modal} onClose={closeModal} />

      <div className='row my-8'>
        <div className='col text-end'>
          <Button type='button' variant='primary' onClick={() => setModal(true)} disabled={loading}>
            <i className='bi bi-person-fill-add' />
            Añadir cliente para remitir
          </Button>
        </div>
      </div>

      <TableContainer
        filter={<Filter loading={loading} query={query} setQuery={setQuery} />}
      >
        <CustomTable data={data} columns={columns} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </DetailContainer>
  )
}

export default ClientesParaRemitir