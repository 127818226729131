import { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useCustomerSuppliers } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { FileModal, FilesColumns } from './'

import { RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const FilesTab = ({ editing, handleEditing, handleGetCustomerSupplier }) => {
  const { currentUser } = useAuth()

  const { values } = useFormikContext();
  const { COLUMNS } = FilesColumns()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [modal, setModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const { handleGetCustomerSupplierFiles, handleGetCustomerSupplierFile, handleAddCustomerSupplierFile } = useCustomerSuppliers()
  const [files, setFiles] = useState([])

  const handleCloseModal = () => {
    setModal(false)
  }

  const handleShowModal = () => {
    setModal(true)
  }

  const getFiles = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetCustomerSupplierFiles(values.id, params)
      setFiles(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setFiles([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetCustomerSupplierFiles, values.id, query])

  const getFile = async (fileId, fileName) => {
    try {
      const response = await handleGetCustomerSupplierFile(values.id, fileId)

      const blob = response.data;
      const contentType = response.headers['content-type'];
      const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success('Archivo del cliente/proveedor descargado correctamente')
    } catch (error) {
      console.log(error)
      toast.error('Error al descargar el archivo del cliente/proveedor')
    }
  }

  const addFile = async (file) => {
    try {
      const data = new FormData()
      data.append('document_type_id', file.document_type_id)
      data.append('file', selectedFile)
      data.append('expiration_date', file.expiration_date)

      await handleAddCustomerSupplierFile(values.id, data)

      toast.success("Archivo agregado correctamente", { theme: "colored" })
    } catch (error) {
      toast.error(error.message)
    } finally {
      setSelectedFile(null)
      handleCloseModal()
      handleEditing(false)
      handleGetCustomerSupplier()
      getFiles()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Descargar',
      icon: 'bi bi-download text-primary',
      action: () => getFile(currentRow?.id, currentRow?.riga_file_asset_name)
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getFiles()
  }, [getFiles])

  return (
    <>
      <FileModal show={modal} onClose={handleCloseModal} onSave={addFile} selectedFile={selectedFile} handleSelectedFile={setSelectedFile} />

      {currentUser.roles.some(r => r.id != RoleI.COORDINACION) &&
        <div className='row mb-8'>
          <div className="col text-end">
            <Button className='BSButton' variant='outline-primary' onClick={handleShowModal}>Añadir documento</Button>
          </div>
        </div>
      }

      <div className="row">
        <div className="col">
          <TableContainer>
            <CustomTable columns={columns} data={files || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
          </TableContainer>

          <ContextMenu
            showContextMenu={showContextMenu}
            setShowContextMenu={setShowContextMenu}
            actions={availableActions()}
            currentPos={currentPos}
            ref={contextRef}
          />
        </div>
      </div>
    </>
  )
}

export default FilesTab