const COLUMNS = [
  {
    Header: <p className="m-0 p-0">ID</p>,
    id: 'id',
    accessor: (row) => (
      <p className="m-0 p-0">{row.id}</p>
    ),
  },
  {
    Header: <p className="m-0 p-0">Nombre</p>,
    id: 'name',
    accessor: (row) => (
      <div>
        <strong className="m-0 p-0">{row.name}</strong>
        <p className="m-0 p-0 mt-2">GLN: {row?.gln || '-'}</p>
      </div>
    )
  },
  {
    Header: <p className="m-0 p-0">Domicilio</p>,
    id: 'domicilio',
    accessor: (row) => (
      <p className="m-0 p-0">{row.address?.domicilio?.trim()}</p>
    )
  },
  {
    Header: <p className="m-0 p-0">Localidad</p>,
    id: 'localidad_completa',
    accessor: (row) => (
      <p className="m-0 p-0">{row.address?.localidad_completa?.trim()}</p>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Trazabilidad</p>,
    id: 'has_traceability',
    accessor: (row) => (
      <div className="text-center">
        <span className={`badge badge-${Boolean(row.has_traceability) ? 'success' : 'danger'}`}>
          {Boolean(row.has_traceability) ? 'Sí' : 'No'}
        </span>
      </div>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Tipo de agente</p>,
    id: 'agent_type_id',
    accessor: (row) => (
      <>
        {row?.agent_type_id
          ? <p className="m-0 p-0 text-center">{row.agent_type_name}</p>
          : <p className="m-0 p-0 text-center"><i>Sin tipo de agente</i></p>
        }
      </>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Verifarma</p>,
    id: 'integrates_verifarma',
    accessor: (row) => (
      <div className="text-center">
        <span className={`badge badge-${Boolean(row.integrates_verifarma) ? 'success' : 'danger'}`}>
          {Boolean(row.integrates_verifarma) ? 'Sí' : 'No'}
        </span>
      </div>
    )
  },
  {
    Header: <p className="m-0 p-0 text-center">Activa</p>,
    id: 'is_enabled',
    accessor: (row) => (
      <div className="text-center">
        <span className={`badge badge-${Boolean(row.is_enabled) ? 'success' : 'danger'}`}>
          {Boolean(row.is_enabled) ? 'Sí' : 'No'}
        </span>
      </div>
    )
  },
]

export {
  COLUMNS
}