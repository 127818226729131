import { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'

const DetailModal = ({ show, onClose, data}) => {
  useEffect(() => {
  }, [data])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' size='md' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Detalle de Movimiento interno de stock #{data?.id}</Modal.Title>
      </Modal.Header>

      <Modal.Body className='py-0'>
        <div className='table-responsive'>
          <table className='table'>
            <tbody>
              {data?.articles?.split(';').map((d, i) => (
                <tr key={`row-${i}`}>
                  <td className='pb-2'>
                    {d}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button type='button' variant='secondary' onClick={onClose}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DetailModal