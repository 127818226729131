import { useState, useMemo, useEffect } from "react"
import { Link } from "react-router-dom"
import { useTable } from "react-table"
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import clsx from 'clsx'
import moment from 'moment'
import { useOutOrders, useRequest } from "hooks"

import { KTCard, KTCardBody } from "_metronic/helpers"

import routes from "app/routing/routes"
import { CustomRow } from "./CustomRow"
import Filter from "./Filter"

const initialObQuery = {
  pageNumber: 1,
  pageSize: 1000
}

const MyTooltip = ({ row }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [data, setData] = useState([])
  const {handleGetOutOrder} = useOutOrders()

  const getOutOrder = async () => {
    if (row) {
      const resp = await handleGetOutOrder(row.id)

      if (resp?.data?.result ) {
        const _data = resp.data.result.detail.map(d => ({
          gtin: d.gtin,
          name: d.name,
          qty: d.qty
        }))

        setData(_data)
      }
    }
  }

  useEffect(() => {
    if (showTooltip) {
      getOutOrder()
    }
  }, [showTooltip])

  return (
    <OverlayTrigger
      placement="left"
      show={showTooltip}
      onToggle={setShowTooltip}
      overlay={
        <Tooltip id="tooltip">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>GTIN</th>
                <th>Nombre</th>
                <th>Cant.</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.gtin}</td>
                  <td>{item.name}</td>
                  <td>{item.qty}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tooltip>
      }
    >
      <span className="me-5">
        <i className='bi bi-info-circle text-success fs-3' />
      </span>
    </OverlayTrigger>
  )
}

export function OutOrderSelectionModal({ show, onHide }) {
  const [data, setData] = useState([])
  const [filter, setFilter] = useState("?order_status_id=2")
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const { loading, handleGetOutOrders } = useOutOrders()
  const [totalCount, setTotalCount] = useState(0)
  const [sortState, setSortState] = useState({})

  const getOutOrders = async () => {
    try {
      if (queryMD && queryMD.pageNumber) {
        const query = { filter, queryMD }
        const response = await handleGetOutOrders(queryMD)

        if (response?.data?.result) {
          const data = response.data.result
          setData(data)
          setTotalCount(response.data.metadata.count)
        }
      }
    } catch (error) {
      setData([])
      console.log(error)
    }
  }

  const COLUMNS = [
    {
      Header: <p className='m-0 p-0'>Nro.</p>,
      id: 'id',
      accessor: (row) => (
            <p className='m-0 p-0'>{row.id}</p>
      )
    },
    {
      Header: <p className='m-0 p-0'>Pedido Venta</p>,
      id: 'sales_order_id',
      accessor: (row) => (
            <p className='m-0 p-0'>{row.sales_order_id}</p>
      )
    },
    {
      Header: <p className='m-0 p-0 text-center'>Fecha</p>,
      id: 'date',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {moment(row.created_at).format("DD/MM/YYYY")}
        </p>
      )
    },
    {
      Header: <p className='m-0 p-0'>Cliente</p>,
      id: 'supplier_name',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.customer_supplier_business_name}</p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0 text-center'>Estado</p>,
      id: 'order_status_id',
      accessor: (row) => (
        <div className="badge badge-warning w-100 justify-content-center" style={{ color: "#333333" }}>
          {row.out_order_status_label}
        </div>
      )
    },
  ]


  const isSelectedForSorting = (id) => {
    return sortState.sort && sortState.sort === id
  }



  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <div className='text-center'>
          <MyTooltip row={row} />

          <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Seleccionar</Tooltip>}>
            <Link to='' className='me-5'>
              <i className="bi bi-clipboard-check text-primary fs-3" style={{ cursor: 'pointer' }} onClick={() => loadOutOrder(row)} />
            </Link>
          </OverlayTrigger>

        </div>
      )
    }
  ], [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  const loadOutOrder = (row) => {
    onHide(row)
  }
  const sortColumn = (id) => {
    if (sortState.order == 'asc') {
      setSortState({ sort: id, order: 'desc' })
      setQueryMD({ ...queryMD, sortField: id, sortOrder: 'desc' })
    } else {
      setSortState({ sort: id, order: 'asc' })
      setQueryMD({ ...queryMD, sortField: id, sortOrder: 'asc' })
    }
  }

  useEffect(() => {
    if(show){
      getOutOrders()
    }
  }, [filter, queryMD])

  useEffect(()=>{
    if(show){
      setData([])
    }
  }, [show])

  return (
    <>
      <Modal size="xl" show={show} backdrop='static' centered>
        <Modal.Header closeButton>
          <Modal.Title>Órdenes de egreso</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <KTCard>
            <Filter setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />
          </KTCard>
          { (loading) && (
            <div className="custom-overlay-layer d-flex justify-content-center align-items-center">
              <div className="spinner-border text-success" role="status"></div>
            </div>
          )}   
          <KTCard>
            <KTCardBody className='TableContainer'>
              <div>
                <div className='table-responsive' style={{ overflowY: "auto", height: "400px" }}>
                  <table
                    id='kt_table_users'
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    {...getTableProps()}
                  >
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200" {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => {
                            return (column.hasOrder
                              ?
                                <th {...column.getHeaderProps()}
                                  className={clsx( isSelectedForSorting(column.id) && sortState.order !== undefined && `table-sort-${sortState.order}`)}
                                  style={{cursor: 'pointer'}}
                                  onClick={() => sortColumn(column.id)}
                                >
                                  {column.Header}
                                </th>
                              :
                                <th {...column.getHeaderProps()} >
                                  {column.render('Header')}
                                </th>
                          )})}
                        </tr>
                      ))}
                    </thead>

                    <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                      {rows.length > 0
                        ? (
                          rows.map((row, i) => {
                            prepareRow(row)
                            return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                          })
                        )
                        : (
                          <tr>
                            <td colSpan={7}>
                              <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                No hay registros
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>

                </div>
              </div>
            </KTCardBody>
          </KTCard>
        </Modal.Body>

        <Modal.Footer style={{ borderTop: "0 none" }}>
          <Button variant="secondary" type='button' onClick={()=>onHide(null)}>
            <i className="bi bi-slash-circle" />
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}