import { RoleNameMatchI, RoleNameI, RoleColorsI } from "interfaces"

export const rolesNames = (role) => {
  if (!role) {
    return 'Sin rol'
  }

  switch (role) {
    case RoleNameMatchI.ADMIN:
      return RoleNameI.ADMIN
    case RoleNameMatchI.VENTAS:
      return RoleNameI.VENTAS
    case RoleNameMatchI.CONTROL_RENTAS:
      return RoleNameI.CONTROL_RENTAS
    case RoleNameMatchI.CONTORL_CRED:
      return RoleNameI.CONTORL_CRED
    case RoleNameMatchI.COMPRAS:
      return RoleNameI.COMPRAS
    case RoleNameMatchI.OPERACIONES_GTE:
      return RoleNameI.OPERACIONES_GTE
    case RoleNameMatchI.FARMACIA:
      return RoleNameI.FARMACIA
    case RoleNameMatchI.CONTROL_COMPRAS:
      return RoleNameI.CONTROL_COMPRAS
    case RoleNameMatchI.VENTAS_GTE:
      return RoleNameI.VENTAS_GTE
    case RoleNameMatchI.OPERACIONES:
      return RoleNameI.OPERACIONES
    case RoleNameMatchI.COORDINACION:
      return RoleNameI.COORDINACION
    case RoleNameMatchI.IMPUESTOS:
      return RoleNameI.IMPUESTOS
    case RoleNameMatchI.PRESENTACION:
      return RoleNameI.PRESENTACION
    default:
      return 'Sin rol'
  }
}

export {default as Usuarios} from './List'
export {default as UsuarioDetalle} from './Detail'
