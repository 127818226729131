import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { AssignmentOutlined, Inventory2Outlined } from '@mui/icons-material'
import { labelColor } from '../List';
import { useCustomerSuppliers, useOutOrders, useRequest } from 'hooks'
import { DetailContainer, TabsContainer } from 'containers'
import GeneralTab from './GeneralTab'
import ArticlesTab from './ArticlesTab'
import { initialValues } from './constants'
import { helix } from 'ldrs'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ESTADOS_PEDIDO_COMPRA} from '../List/index'

const DetailReturnRequest = ({ income = false }) => {
  const [customers, setCustomers] = useState([])
  const [request, setRequest] = useState(null)
  const navigate = useNavigate()
  const { handleGetCustomerSuppliers, loading:loadingCustomerSupplier } = useCustomerSuppliers()
  const { handleGetRequest, handleUpdateRequest, loading:loadingRequest, handleAuthorizeRequest } = useRequest()
  const { handleGetOutOrder, loading:loadingOutOrder} = useOutOrders();
  const { id } = useParams()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    const data = await handleGetCustomerSuppliers()
    if (data && data.data && data.data.result) {
      setCustomers(data.data.result
        .filter(p => Boolean(p.is_customer))
        .map(p => ({ ...p, label: p.business_name, value: p.id }))
      )
    }
    const req = await handleGetRequest(id)
    if(req?.result?.length){
        const oe = await handleGetOutOrder(req.result[0].out_order_id)

        if(oe?.data?.result?.detail){
            const combinedArray = req.result[0].detail.map(item1 => {
              const match = oe.data.result.detail.find(item2 => item2.product_var_id === item1.product_var_id);
              return {
                ...item1,
                original_qty: match ? match.qty : 0 // Asignar 0 si no se encuentra returned_qty
              };
            });
            const obTransfer = {...req.result[0], detail:combinedArray}
            setRequest(obTransfer)
        }
    }

  }

  useEffect(()=>{
      if(request?.id){
          if (formikRef.current) {
              formikRef.current.setValues({
                id: request.id,
                supplier_id: request.supplier_id,
                observations: request.notes,
                internal_notes: request.internal_notes,
                issue_date: request.issue_date ? new Date(request.issue_date).toISOString().split('T')[0] : '',
                out_order_id: request.out_order_id,
                total: request.total ? '$' + parseFloat(request.total).toFixed(2):'',
                order_status_id: request.order_status_id,
                order_status_label: request.order_status_label,
                business_unit_id: request.business_unit_id,
                returned_request_reason_id: request.returned_request_reason_id,
                detail:request.detail
              });
          }
      }
  }, [request])

  const save = async()=>{
    if(formikRef?.current?.values){
      const in_request =  formikRef.current.values      
      try{            
        const transport = {
            in_request_id:in_request.id,
            supplier_id: in_request.supplier_id,
            issue_date: in_request.issue_date,
            notes: in_request.observations,
            internal_notes: in_request.internal_notes,
            order_type_id: 5,
            business_unit_id: in_request.business_unit_id,
            out_order_id: in_request.out_order_id,
            returned_request_reason_id: in_request.returned_request_reason_id,
            detail: in_request.detail.map(d=>({
              detail_id: d.id,
              var_id: d.product_var_id,
              qty: d.qty
            }))
        }
        await handleUpdateRequest(transport)
        toast.success("Registro actualizado correctamente")  
      }
      catch(err){
        toast.error(err.message)
      }
    }
  } 

  const confirm = async()=>{
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: "Confirma el envío a ingreso?",
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, enviar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })
      if (resp.isConfirmed) {
        // primero guarda el pedido por si modificaron alguna cantidad
        if(formikRef?.current?.values){
          const in_request =  formikRef.current.values   

          if(!in_request.returned_request_reason_id || in_request.returned_request_reason_id=='' ){
              toast.error("El campo motivo de devolución es obligatorio")
              return
          }
          const transport = {
              in_request_id:in_request.id,
              supplier_id: in_request.supplier_id,
              issue_date: in_request.issue_date,
              notes: in_request.observations,
              internal_notes: in_request.internal_notes,
              order_type_id: 5,
              business_unit_id: in_request.business_unit_id,
              out_order_id: in_request.out_order_id,
              returned_request_reason_id: in_request.returned_request_reason_id,
              detail: in_request.detail.map(d=>({
                detail_id: d.id,
                var_id: d.product_var_id,
                qty: d.qty
              }))
          }
          await handleUpdateRequest(transport)
          // luego manda a ingreso
          const resp = await handleAuthorizeRequest({ id:request.id })
          toast.success("Pedido enviado", { theme: "colored" })
        }
      }
      fetchData()      
    } catch(err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  useEffect(()=>{
    if(loadingCustomerSupplier || loadingOutOrder || loadingRequest){
      setLoading(true)
    }
    else{
      setLoading(false)
    }
  },[loadingCustomerSupplier, loadingOutOrder, loadingRequest])

  useEffect(()=>{
    fetchData()
    helix.register()
  }, [])
  

  const tabsContent =  [
    <GeneralTab customers={customers} loading={loading} />,
    <ArticlesTab  loading={loading} />,
  ]

  const tabs =  [
    { value: 'general', label: "General", icon: <AssignmentOutlined />, show: true, disabled:  loading },
    { value: 'articles', label: "Articulos", icon: <Inventory2Outlined />, show: true, disabled:  loading  }
  ]

  return (
    <>

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          // onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched }) => (
            <Form className="d-flex flex-column mt-4" >
              <DetailContainer
                title='Pedido de devolución'
                statusV2={
                  <>
                    <div className={`badge ${labelColor(values?.order_status_id)} justify-content-center ms-5 fw-normal`} style={{ width: "150px" }}>
                      {values.order_status_label}
                    </div>
                  </>
                }
                buttons={
                  <div className='d-flex align-items-center'>
                    <div className='row'>
                      <div className='d-flex mt-5'>
                        <Button type='button' variant="secondary" onClick={() => navigate(-1)} className='me-3'>
                          <i className="ki-duotone ki-left" />
                          Volver
                        </Button>
                        { values?.order_status_id==ESTADOS_PEDIDO_COMPRA.BORRADOR 
                          ?
                          <DropdownButton title="Acciones" disabled={loadingCustomerSupplier || !values?.id || loading}>
                            <Dropdown.Item key="save" onClick={() => {save()}} disabled={loadingCustomerSupplier || !values?.id ||loading}>
                              <i className="bi bi-save text-primary me-2"/>Guardar
                            </Dropdown.Item>
                            
                              <Dropdown.Item key="autorizar" onClick={() => {confirm()}} disabled={loadingCustomerSupplier || !values?.id ||loading}>
                                <i className="bi bi-check-lg text-success me-2"/>Enviar a Ingreso
                              </Dropdown.Item>
                            
                          </DropdownButton>
                          :
                          <></>
                        }
                      </div>
                    </div>
                  </div>
                }
              >
                  <TabsContainer
                    initialValue='general'
                    tabs= {tabs}
                    tabsContent={tabsContent}
                  />

              </DetailContainer>
            </Form>
          )}
        </Formik>


    </>
  )
}

export default DetailReturnRequest