import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useUsers, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './columns'
import Filter from './Filter'

import { RoleI } from 'interfaces'

import routes from 'app/routing/routes'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const Usuarios = () => {
  const { currentUser } = useAuth()

  const { loading, handleObtenerUsuarios, handleActualizarEstadoUsuario } = useUsers()

  const [data, setData] = useState([])
  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const navigate = useNavigate()

  const obtenerUsuarios = useCallback(async (filters) => {
    try {
      let params = {
        ...filters
      }

      if (query && query?.pageNumber) {
        params = {
          ...query,
        }
      }

      const response = await handleObtenerUsuarios(params)
      const result = response.data.result
      const count = response.data.metadata.count

      setData(result)
      setTotalCount(count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleObtenerUsuarios, query])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Ver detalle',
      icon: 'bi bi-pencil-square text-primary',
      action: () => navigate(`${routes.USERS}/${currentRow?.id}`)
    })

    if (
      currentUser?.roles?.some(r => r.id == RoleI.ADMIN) &&
      (currentRow?.roles_ids && currentRow.roles_ids.split(';').some(r => Number(r) !== RoleI.ADMIN))
    ) {
      actions.push({
        label: Boolean(currentRow?.is_active) ? 'Deshabilitar' : 'Activar',
        icon: `bi bi-${Boolean(currentRow?.is_active) ? 'x-circle text-danger' : 'check-circle text-success'}`,
        action: () => actualizarEstadoUsuario(currentRow?.id, Boolean(currentRow?.is_active))
      })
    }

    return actions
  }

  /**
   * @async Actualizar el estado del usuario
   * @param {number} idUsuario - ID del usuario
   * @param {boolean} activo - Estado del usuario (boolean)
   * @returns {Prmose<any>}
   */
  const actualizarEstadoUsuario = async (idUsuario, activo) => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a ${activo ? 'deshabilitar' : 'habilitar'} al usuario #${idUsuario}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, actualizar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleActualizarEstadoUsuario(idUsuario)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      obtenerUsuarios()
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer"}} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    obtenerUsuarios()
  }, [obtenerUsuarios])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <TableContainer
        title={'Usuarios'}
        dropdownDisabled={loading}
        dropdownActions={[
          {
            label: "Nuevo usuario",
            href: routes.SALES_ORDERS_NEW,
            icon: 'bi bi-person-add text-primary',
          },
        ]}
        filter={<Filter loading={loading} handleObtenerUsuarios={obtenerUsuarios} query={query} setQuery={setQuery} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} queryMD={query} setQueryMD={setQuery} totalCount={totalCount} setTotalCount={setTotalCount} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default Usuarios