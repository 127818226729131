const COLUMNS = [
  {
    Header: 'Pedido',
    id: 'sales_order_id',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.sales_order_id}</p>
    )
  },
  {
    Header: 'Orden de egreso',
    id: 'out_order_id',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.out_order_id}</p>
    )
  },
  {
    Header: 'Remito',
    id: 'remito',
    accessor: (row) => (
      <p className='m-0 p-0'>{row?.remito || <i>Sin remito</i>}</p>
    )
  },
  {
    Header: 'Dirección',
    id: 'ship_domicilio',
    accessor: (row) => (
      <p className='m-0 p-0'>{row?.ship_domicilio?.trim()}, {row?.ship_localidad?.trim()}</p>
    )
  },
]

export {
  COLUMNS
}