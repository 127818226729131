import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import { Input } from 'components'
import { Button } from 'react-bootstrap'

const initialFormikValues = {
  sales_order_ids: '',
  out_order_ids: '',
  afip: '',
  remito: '',
}

const Filter = ({ loading, query, setQuery }) => {
  const [initialValues, setInitialValues] = useState(initialFormikValues)

  const handleSubmit = (values) => {
    setQuery({
      ...query,
      ...values,
      sales_order_ids: values?.sales_order_ids || undefined,
      out_order_ids: values?.out_order_ids || undefined,
      afip: values?.afip || undefined,
      remito: values?.remito || undefined,
      pageNumber: 1,
      pageSize: 10,
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input
                id='sales_order_ids'
                name='sales_order_ids'
                placeholder='Ej: 1234, 4321'
                disabled={loading}
              />
              <p className='text-muted'>IDs Pedido</p>
            </div>

            <div className='col-2'>
              <Input
                id='out_order_ids'
                name='out_order_ids'
                placeholder='Ej: 1234, 4321'
                disabled={loading}
              />
              <p className='text-muted'>IDs Órdenes</p>
            </div>

            {/* <div className='col-4'>
              <Input id='afip' name='afip' disabled={loading} />
              <p className='text-muted'>AFIP</p>
            </div>

            <div className='col-4'>
              <Input id='remito' name='remito' disabled={loading} />
              <p className='text-muted'>Remito</p>
            </div> */}
          </div>

          <div className='row'>
            <div className='col text-end'>
              <Button type='submit' variant='primary' disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter