import moment from 'moment'

const COLUMNS = [
  {
    Header: 'ID',
    id: 'id',
    accessor: (row) => (
      <p className='p-0 m-0'>{row.id}</p>
    ),
    hasOrder: true,
  },
  // {
  //   Header: 'ID Artículo',
  //   id: 'product_var_id',
  //   accessor: (row) => (
  //     <p className='p-0 m-0'>{row.product_var_id}</p>
  //   ),
  //   hasOrder: true,
  // },
  {
    Header: 'ID Stock',
    id: 'stock_id',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.stock_id}</p>
    ),
    hasOrder: true,
  },
  {
    Header: 'Nro. de serie',
    id: 'serial_number',
    accessor: (row) => (
      <>
        {row?.serial_number && row?.serial_number?.trim() !== ''
          ? <p className='m-0 p-0'>{row.serial_number}</p>
          : <i>Sin nro. de serie</i>
        }
      </>
    ),
  },
  {
    Header: 'Lote',
    id: 'lot_number',
    accessor: (row) => (
      <p className='m-0 p-0'>{row.lot_number}</p>
    )
  },
  {
    Header: 'Vencimiento',
    id: 'expiration_date',
    accessor: (row) => (
      <p className='m-0 p-0'>{moment(row.expiration_date).format('DD/MM/YYYY')}</p>
    )
  }
]

export {
  COLUMNS
}