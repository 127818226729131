import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import Swal from 'sweetalert2'

import { useWaybill, useDispatchOrders } from 'hooks'

import { ESTADOS_ORDEN_DESPACHO } from '../List'

import routes from 'app/routing/routes';

const Actions = ({ loading, data, handleGetData, handleGetDetail }) => {
  const navigate = useNavigate()

  const { handleConfirmDispatch, handleCancelDispatchOrder } = useWaybill()
  const { loading: loadingLocal, handleGetDispatchOrderDetail } = useDispatchOrders()

  const availableActions = () => {
    let actions = []

    if (data?.dispatch_order_status_id === ESTADOS_ORDEN_DESPACHO.PENDIENTE) {
      actions.push({
        label: "Despachar",
        icon: "bi bi-truck text-primary",
        action: () => actionDispatch(data.id)
      })

      actions.push({
        label: "Cancelar",
        icon: "bi bi-x-circle text-danger",
        action: () => actionCancel(data.id)
      })
    }

    if (data?.dispatch_order_status_id != ESTADOS_ORDEN_DESPACHO.CANCELADO) {
      actions.push({
        label: "Hoja de ruta",
        icon: "bi bi-download text-success",
        action: () => actionPrint(data.id)
      })
    }

    return actions
  }

  const actionDispatch = async (id) => {
    const result = await Swal.fire({
      icon: "warning",
      title: `Confirmar despacho`,
      text: `¿Confirma el despacho #${id}?`,
      showDenyButton: true,
      confirmButtonText: "Si, confirmar",
      denyButtonText: "No, cancelar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    })

    if (result.isConfirmed) {
      try {
        await handleConfirmDispatch(id)
        toast.success("El despacho fue procesado correctamente", { theme: "colored" })
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetData()
        handleGetDetail()
      }
    }
  }

  const actionCancel = async (id) => {
    const result = await Swal.fire({
      icon: 'warning',
      title: `¿Está seguro de cancelar la orden de despacho #${id}?`,
      text: "Esta acción no se puede revertir",
      showDenyButton: true,
      confirmButtonText: 'Si, cancelar',
      denyButtonText: 'No, cerrar',
      customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
      reverseButtons: true,
    })

    if (result.isConfirmed) {
      try {
        await handleCancelDispatchOrder(id)
        toast.success('Orden de despacho cancelada correctamente')
      } catch (error) {
        toast.error(error.message)
      } finally {
        handleGetData()
        handleGetDetail()
      }
    }
  }

  const actionPrint = async (id) => {
    try {
      const response = await handleGetDispatchOrderDetail(id, { pageNumber: 1 })

      if (response?.data?.result) {
        const datos = response.data.result.map(d => {
          let data = {
            "Despacho": d.dispatch_order_id,
            "F. Creación": moment(d.created_at).format('DD/MM/YYYY'),
            "O. envío": d.order_delivery_id,
            "Domicilio (envío)": `${d.address_ship?.domicilio}`,
            "Localidad (envío)": d.address_ship?.localidad_completa,
            "CP (envío)": d.address_ship?.postal_code,
            "Provincia (envío)": d.address_traza?.province,
            "Domicilio (traza)": `${d.address_traza?.domicilio}`,
            "Localidad (traza)": d.address_traza?.localidad_completa,
            "CP (traza)": d.address_traza?.postal_code,
            "Provincia (traza)": d.address_traza?.province,
            "Transportista": d.shipping_carrier_service?.shipping_carrier_name,
            "Servicio": d.shipping_carrier_service?.shipping_carrier_service,
          }

          return data
        })

        var ws = XLSX.utils.json_to_sheet(datos)
        var wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, ws, "Hoja de Ruta")
        XLSX.writeFile(wb, `Despacho ${id} - ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <Button variant='secondary' onClick={() => navigate(routes.LOGISTICS_DISPATCH_ORDERS)} className='me-3' disabled={loading || loadingLocal}>
        <i className="ki-duotone ki-left" />
        Volver
      </Button>

      {availableActions().length > 0 &&
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" variant="primary" disabled={loading || loadingLocal}>
            Acciones
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: "200px" }}>
            {availableActions().map((data, index) => (
              <Dropdown.Item key={index} onClick={() => data.action()}>
                <i className={`${data.icon} pe-3`} />
                {data.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      }
    </div>
  )
}

export default Actions