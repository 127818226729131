import service from "services/service"

const PATH = {
  users: "/users",
};

export class Users {
  static getUsers = async (params) => {
    const response = await service.get(PATH.users, {
      params,
    });

    return response;
  };

  static getUser = async (id) => {
    const response = await service.get(`${PATH.users}/${id}`);

    return response;
  };

  static obtenerUsuarios = async (params) => {
    const response = await service.get(`${PATH.users}/v2`, {
      params,
    })
    return response
  }

  static obtenerUsuario = async (id) => {
    const response = await service.get(`${PATH.users}/v2/${id}`)
    return response
  }

  static obtenerRoles = async (params) => {
    const response = await service.get(`${PATH.users}/v2/roles`, {
      params,
    })
    return response
  }

  static actualizarEstadoUsuario = async (id) => {
    const response = await service.post(`${PATH.users}/v2/${id}/estado`)
    return response
  }
}