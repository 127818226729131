import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'

import { useUsers } from 'hooks'

import { Input, SelectField } from 'components'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { rolesNames } from '..'

const initialValues = {
  id: '',
  name: '',
  email: '',
  roles: [],
  is_active: ''
}

const activeOptions = [
  { value: '', label: 'Todos' },
  { value: '1', label: 'Sí' },
  { value: '0', label: 'No' },
]

const Filter = ({ loading, handleObtenerUsuarios, query, setQuery }) => {
  const { handleObtenerRoles } = useUsers()
  const [roles, setRoles] = useState([])

  const handleSubmit = (values) => {
    const params = {
      ...query,
      ...values,
      id: values?.id || undefined,
      name: values?.name || undefined,
      email: values?.email || undefined,
      roles: values?.roles || undefined,
      is_active: values?.is_active || undefined,
      pageNumber: 1,
    }

    setQuery(params)
  }

  const getRoles = useCallback(async () => {
    try {
      const response = await handleObtenerRoles()
      const result = response.data.result.map(r => ({
        value: r.id,
        label: rolesNames(r.name)
      }))

      setRoles(result)
    } catch (error) {
      setRoles([])
      toast.error(error.message)
    }
  }, [handleObtenerRoles])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id' name='id' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-5'>
              <Input id='name' name='name' disabled={loading} />
              <p className='text-muted'>Nombre</p>
            </div>

            <div className='col-5'>
              <Input id='email' name='email' disabled={loading} />
              <p className='text-muted'>Email</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-5'>
              <SelectField
                id='roles'
                name='roles'
                options={roles}
                placeholder='Seleccionar roles'
                isMulti
                disabled={loading}
              />
              <p className='text-muted'>Roles</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='is_active'
                name='is_active'
                options={activeOptions}
                placeholder='Seleccionar'
                disabled={loading}
              />
              <p className='text-muted'>Activo</p>
            </div>
          </div>

          <div className='row'>
            <div className='col text-end'>
              <Button type='submit' variant='primary' disabled={loading}>
                <i className='bi bi-lens' />
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter