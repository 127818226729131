import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useStock, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import { COLUMNS, COLUMNS_2 } from './columns'
import CreateDepotMovementModal from './partials/CreateDepotMovementModal'
import DetailModal from './partials/DetailModal'

import { DepotMovementStatusI } from 'interfaces'

import routes from 'app/routing/routes'

const labelColor = (statusId) => {
  switch (statusId) {
    case DepotMovementStatusI.EN_PREPARACION:
      return "estado-amarillo"
    case DepotMovementStatusI.CONFIRMADO:
      return "estado-verde"
    case DepotMovementStatusI.CANCELADO:
      return 'estado-rojo'
    default:
      return "estado-gris"
  }
}

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  depot_movement_status_id_filter: [DepotMovementStatusI.EN_PREPARACION],
}

const DepotMovements = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetDepotMovements, handleConfirmDepotMovement, handleCancelDepotMovement, handleGenerarRemitoInterno, handlePrintRemito } = useStock()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [movementsModal, setMovementsModal] = useState(false)
  const [detailModal, setDetailModal] = useState(false)
  const [detailData, setDetailData] = useState(null)

  const getMovements = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD.pageNumber) {
        params = {
          ...params,
          ...queryMD
        }
      }

      if (currentUser && currentUser?.depot_movements) {
        const { depot_movements: dp } = currentUser

        params = {
          ...params,
          id: dp?.id || undefined,
          storage_space_id_origin_filter: dp?.storage_space_id_origin_filter || undefined,
          storage_space_id_dest_filter: dp?.storage_space_id_dest_filter || undefined,
          date_from: dp?.date_from || undefined,
          date_to: dp?.date_to || undefined,
          article_text: dp?.article_text || undefined,
          depot_movement_status_id_filter: dp?.depot_movement_status_id_filter && dp.depot_movement_status_id_filter.length > 0
            ? dp.depot_movement_status_id_filter
            : [DepotMovementStatusI.EN_PREPARACION]
        }
      }

      const response = await handleGetDepotMovements(params)
      const data = response.data.result.map(d => ({
        ...d,
        label_color: labelColor(d.depot_movement_status_id)
      }))

      setData(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetDepotMovements, queryMD, currentUser])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    if (currentRow) {
      actions.push({
        label: "Ver detalle",
        icon: "bi bi-eye text-primary",
        action: actionEdit
      })

      if (currentRow.depot_movement_status_id == DepotMovementStatusI.EN_PREPARACION && currentRow.detail_count > 0) {
        actions.push({
          label: 'Confirmar',
          icon: 'bi bi-arrow-left-right text-primary',
          action: confirmarMovimiento
        })
      }

      if (currentRow.depot_movement_status_id == DepotMovementStatusI.EN_PREPARACION) {
        actions.push({
          label: 'Cancelar Movimiento',
          icon: 'bi bi-x-circle text-danger',
          action: cancelarMovimiento
        })
      }

      if (currentRow.depot_movement_status_id == DepotMovementStatusI.CONFIRMADO && currentRow?.remito_id == null) {
        actions.push({
          label: 'Generar remito interno',
          icon: 'bi bi-link-45deg text-warning',
          action: generarRemitoInterno
        })
      }

      if (currentRow?.remito_id != null) {
        actions.push({
          label: "Descargar remito",
          icon: "bi bi-download text-warning",
          action: printInternalWaybill
        })
      }
    }

    return actions
  }

  const actionEdit = () => {
    navigate(`${routes.INVENTORY_MOVEMENTS}/${currentRow.id}`);
  }

  const confirmarMovimiento = async () => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Confirma el Movimiento interno de stock #${currentRow.id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, confirmar`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleConfirmDepotMovement(currentRow.id)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getMovements()
    }
  }

  const cancelarMovimiento = async () => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a cancelar el Movimiento interno de stock #${currentRow.id}, ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si, cancelar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleCancelDepotMovement(currentRow.id)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getMovements()
    }
  }

  const generarRemitoInterno = async () => {
    try {
      const response = await handleGenerarRemitoInterno(currentRow.id)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      getMovements()
    }
  }

  const printInternalWaybill = async () => {
    try {
      let copias = 2
      const response = await handlePrintRemito(currentRow.id, copias)

      if (response.status === 200) {
        toast.success('¡Remito descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `remito-${currentRow.remito_id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

      } else {
        // Manejar errores si la solicitud no fue exitosa
        console.error('Error al descargar el PDF')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => {
    let cols = [
      ...COLUMNS,
      {
        Header: <p className='m-0 p-0 text-center'>Artículos</p>,
        id: 'detail_count',
        accessor: (row) => (
          <div className='text-center'>
            {row?.detail_count || 0}

            {row?.articles &&
              <Tooltip text='Ver artículos'>
                <i
                  className='bi bi-info-circle text-warning ms-2 cursor-pointer'
                  onClick={() => {
                    setDetailData(row)
                    setDetailModal(true)
                  }}
                />
              </Tooltip>
            }
          </div>
        )
      },
      ...COLUMNS_2,
    ]

    if (!loading) {
      cols.push({
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => (
          <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
        )
      })
    }

    return cols
  }, [COLUMNS, COLUMNS_2, loading])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    getMovements()
  }, [getMovements])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <CreateDepotMovementModal
        show={movementsModal}
        onClose={() => {
          setMovementsModal(false)
          getMovements()
        }}
      />

      <DetailModal
        show={detailModal}
        onClose={() => {
          setDetailModal(false)
          setDetailData(null)
        }}
        data={detailData}
      />

      <TableContainer
        title='Movimientos internos de stock'
        createButton={
          <Button variant='primary' onClick={() => setMovementsModal(true)} disabled={loading}>
            <i className='bi bi-plus-lg me-2' />
            Nuevo movimiento
          </Button>
        }
        filter={<Filter loading={loading} queryMD={queryMD} setQueryMD={setQueryMD} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </>
  )
}

export default DepotMovements
