import { useState, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Select from "react-select"
import moment from 'moment'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useWaybill, useUsers } from 'hooks'

import { TableContainer } from 'containers';
import { Comments, CustomTable } from 'components'

import { COLUMNS } from './ShipmentResultColumns'

import './index.css'

const RESULTADO = {
  ENTREGADO: 1,
  DEVUELTO: 2,
}

const DEVOLUCION = {
  A_REVISION: 1,
  A_REDESPACHO: 2,
}

const optionsResultado = [
  { value: RESULTADO.ENTREGADO, label: "Entregado" },
  { value: RESULTADO.DEVUELTO, label: "Devuelto" }
]

const optionsDevolucion = [
  { value: DEVOLUCION.A_REVISION, label: "Enviar a revisión" },
  { value: DEVOLUCION.A_REDESPACHO, label: "Volver a despachar" }
]

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '38px',
    width:'200px'
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  menu: (base) => ({
    ...base,
    width: '200px'
  }),
  multiValue: (base) => ({
    ...base,
    fontSize: '1.1rem',
  }),
}

const ShipmentResultModal = ({ show, onHide, shipmentOrder }) => {
  const [devolucion, setDevolucion] = useState({})
  const [resultado, setResultado] = useState({})
  const [notes, setNotes] = useState("")
  const [error, setError] = useState(false)

  const { loading, handleGetShipmentOrderDetail } = useWaybill()
  const [data, setData] = useState([])

  const { handleGetUsers } = useUsers()
  const [userData, setUserData] = useState([])

  const getShipmentOrder = async () => {
    try {
      const response = await handleGetShipmentOrderDetail(shipmentOrder?.id)
      const _data = response.data?.result
      setData(_data)
    } catch (error) {
      setData([])
      toast.error(error.message)
    }
  }

  const getUsers = useCallback(async () => {
    const response = await handleGetUsers()
    const data = response.data.result.map((u) => ({
      id: u.id,
      display: u.name
    }))

    setUserData(data)
  }, [handleGetUsers])

  const onSave = () => {
    if (resultado.value == RESULTADO.DEVUELTO && devolucion.value == DEVOLUCION.A_REVISION && notes.trim() == '') {
      toast.error('El campo "Observaciones" es obligatorio')
      setError(true)
      return
    }

    const ret = {}
    ret.id = shipmentOrder.id

    if (resultado.value == RESULTADO.ENTREGADO) {
      ret.entregados = [{ id: shipmentOrder?.id }]
      ret.devueltos = []
    } else {
      ret.entregados = []
      ret.devueltos= [{
        id: shipmentOrder?.id,
        observaciones: notes,
        revision: devolucion?.value == DEVOLUCION.A_REVISION,
        redespacho: devolucion?.value == DEVOLUCION.A_REDESPACHO
      }]
    }

    setData([])
    onHide(ret)
  }

  const handleChangeResultado = (e) => {
    if (e.value == RESULTADO.DEVUELTO) {
      setResultado(optionsResultado.find(o => o.value == RESULTADO.DEVUELTO))
      setDevolucion(optionsDevolucion.find(o => o.value == DEVOLUCION.A_REDESPACHO))
      setError(false)
    } else {
      setResultado(optionsResultado.find(o => o.value == RESULTADO.ENTREGADO))
      setNotes("")
      setDevolucion(null)
    }
  }

  const handleChangeDevolucion = (e) => {
    setDevolucion(optionsDevolucion.find(o => o.value == e.value))
    setError(false)
  }

  useEffect(() => {
    if (show) {
      setDevolucion(null)
      setResultado(optionsResultado.find(r => r.value == RESULTADO.ENTREGADO))
      setError(false)

      if (shipmentOrder?.id) {
        getShipmentOrder()
      }

      setNotes("")
    }
  }, [show])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <Modal show={show} onHide={() => onHide(null)} size="lg" backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Entrega / Devolución</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          <div className='col'>
            <div>
              <table>
                <tbody>
                  <tr>
                    <td style={{ width: "120px" }}>Orden de envío:</td>
                    <td><span className='fw-bold ps-5'>{shipmentOrder?.id}</span></td>
                  </tr>

                  <tr>
                    <td style={{ width: "120px" }}>F. creación:</td>
                    <td><span className='fw-bold ps-5'>{shipmentOrder?.created_at ? moment(shipmentOrder.created_at).format("DD/MM/YYYY HH:mm") : ''}</span></td>
                  </tr>

                  <tr>
                    <td style={{ width: "120px" }}>Transporte:</td>
                    <td>
                      <span className='fw-bold ps-5'>{shipmentOrder?.shipping?.shipping_code ? `[${shipmentOrder.shipping.shipping_code}] ` : ''}{shipmentOrder?.shipping?.name} - {shipmentOrder?.shipping?.service}{shipmentOrder?.shipping?.shipping_type ? ` (${shipmentOrder.shipping.shipping_type})` : ''}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <TableContainer>
              {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

              <CustomTable columns={COLUMNS} data={data} />
            </TableContainer>
          </div>
        </div>

        <div className='row mt-10'>
          <div className='col'>
            <p className='p-0 m-0'>Envío</p>

            <Select
              options={optionsResultado}
              styles={customStyles}
              value={resultado}
              onChange={handleChangeResultado}
              disabled={loading}
            />
          </div>

          <div className='col'>
            {devolucion &&
              <>
                <p className='p-0 m-0'>Devolver</p>
                <Select
                  options={optionsDevolucion}
                  styles={customStyles}
                  value={devolucion}
                  onChange={handleChangeDevolucion}
                  disabled={loading}
                />
              </>
            }
          </div>
        </div>

        {devolucion &&
          <div className='mt-8'>
            <Comments usersData={userData} notes={notes} setNotes={setNotes} title="Observaciones" />
          </div>
        }

        <div className='row mt-15'>
          <div className='col text-end'>
            <Button variant='secondary' onClick={() => onHide(null)} className='me-3' disabled={loading}>
              <i className='bi bi-slash-circle' />
              Cancelar
            </Button>
            <Button variant='primary' onClick={onSave} disabled={loading}>
              <i className='bi bi-save me-2' />
              Guardar
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ShipmentResultModal