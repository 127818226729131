
import { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import {  Input, SelectField, Textarea } from 'components'
import {ESTADOS_PEDIDO_COMPRA} from '../List/index'
import { useRequest } from 'hooks'

const GeneralTab = ({ customers, loading }) => {
  const [returnedReasons, setReturnedReasons] = useState([])
  const { values } = useFormikContext()
  const {handleGetRequestReturnedReason} = useRequest();

  const fetchReturnedReasons = async()=>{
    const data = await handleGetRequestReturnedReason()
    setReturnedReasons(data.result.map(r=>({
      ...r, 
      value:r.id, 
      label:r.reason + ' ' + r.detail + ' (' + r.sector + ')'
    })))
  }

  useEffect(() => {
    fetchReturnedReasons()
  }, [])

  return (
    <>
      <div className='row'>
        <div className='col mb-4 text-end'>

        </div>
      </div>
      { (loading) && (
          <div className="custom-overlay-layer d-flex justify-content-center align-items-center">
            <div className="spinner-border text-success" role="status"></div>
          </div>
      )}      
      <div className='row mb-10 mt-8'>
        <div className='col-6'>
          {
            values?.out_order_id ? 
              <p>Órden de egreso #<b>{values.out_order_id}</b></p>
            :
              <></>          
          }
        </div>
      </div>

      <div className='row mb-10 mt-8'>
        <div className='col-6'>
          <SelectField
            label={'Cliente'}
            name="supplier_id"
            options={customers}
            marginTop='3px'
            isMulti={false}
            placeholder='Seleccione el cliente'
            handleChange={()=>{}}
            disabled={true}
          />
        </div>
        <div className="col-6">
            <SelectField
              label={'Motivo de devolución'}
              name="returned_request_reason_id"
              options={returnedReasons}
              marginTop='3px'
              isMulti={false}
              placeholder='Seleccione el motivo'
              handleChange={()=>{}}
              disabled={values?.order_status_id!=ESTADOS_PEDIDO_COMPRA.BORRADOR}
            />
        </div>
      </div>
      <div className='row mb-10'>
        <div className="col-3">
          <label htmlFor='issue_date' className='form-label'>Fecha comprobante</label>
          <Input id='issue_date' name='issue_date' type='date' disabled />
        </div>
        <div className="col-3">
          <label htmlFor='total' className='form-label'>Monto total</label>
          <Input id='total' name='total' type='text' disabled={true} className="form-control text-end"/>
        </div>
      </div>

      <div className='row mb-20'>
        <div className="col">
          <label htmlFor='notes' className='form-label'>Observaciones</label>
          <Textarea id='observations' name='observations' 
            disabled={true}/>
        </div>
        <div className="col">
          <label htmlFor='internal_notes' className='form-label'>Notas internas</label>
          <Textarea id='internal_notes' name='internal_notes' 
            disabled={!values?.id || values?.order_status_id != ESTADOS_PEDIDO_COMPRA.BORRADOR}/>
        </div>
      </div>

    </>
  )
}

export default GeneralTab