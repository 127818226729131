import { useState, useEffect } from 'react'
import moment from 'moment'

import { labelColor } from '../List'

const Header = ({ shipmentOrder }) => {
  const [transportista, setTransportista] = useState('')
  const [ola, setOla] = useState('')

  useEffect(() => {
    if (!shipmentOrder?.id) {
      setTransportista('')
      setOla('')
    } else {
      let _transportista = `
        ${shipmentOrder.shipping?.shipping_code ? `[${shipmentOrder.shipping.shipping_code}] ` : ''}${shipmentOrder?.shipping?.carrier || ''} - ${shipmentOrder.shipping?.service || ''}${shipmentOrder.shipping?.shipping_type ? ` (${shipmentOrder.shipping?.shipping_type})` : ''}
      `

      let _ola = `
        [${shipmentOrder.wave?.id}] ${shipmentOrder.wave?.name}
      `

      setTransportista(_transportista)
      setOla(_ola)
    }

  }, [shipmentOrder])

  if (!shipmentOrder) return null

  return (
    <>
      <div className={`badge ${labelColor(shipmentOrder.order_delivery_status_id)} justify-content-center fw-normal`} style={{ width: "150px" }}>
        {shipmentOrder.order_delivery_status_label}
      </div>

      <div className='mt-7'>
        <table>
          <tbody>
            <tr>
              <td style={{ width: "120px" }}>F. creación:</td>
              <td><span className='fw-bold ps-5'>{shipmentOrder?.created_at ? moment(shipmentOrder.created_at).format('DD/MM/YYYY HH:mm') : ''}</span></td>
            </tr>

            <tr>
              <td style={{ width: "120px" }}>Transporte:</td>
              <td>
                <span className='fw-bold ps-5'>{transportista}</span>
              </td>
            </tr>

            {shipmentOrder?.shipped_date &&
              <tr>
                <td style={{ width: "120px" }}>Entregado:</td>
                <td>
                  <span className='fw-bold ps-5'>{shipmentOrder?.shipped_date ? moment(shipmentOrder.shipped_date).format("DD/MM/YYYY HH:mm") : ''}</span>
                </td>
              </tr>
            }

            {shipmentOrder?.guide_printed_date &&
              <tr>
                <td style={{ width: "120px" }}>Impresión guía:</td>
                <td>
                  <span className='fw-bold ps-5'>{shipmentOrder?.guide_printed_date ? moment(shipmentOrder.guide_printed_date).format("DD/MM/YYYY HH:mm") : ''}</span>
                </td>
              </tr>
            }

            {shipmentOrder?.ticket_printed_date &&
              <tr>
                <td style={{ width: "120px" }}>Impresión ticket:</td>
                <td>
                  <span className='fw-bold ps-5'>{shipmentOrder?.ticket_printed_date ? moment(shipmentOrder.ticket_printed_date).format("DD/MM/YYYY HH:mm") : ''}</span>
                </td>
              </tr>
            }

            <tr>
              <td style={{ width: "120px" }}>Ola:</td>
              <td>
                <span className='fw-bold ps-5'>{ola}</span>
              </td>
            </tr>

            {shipmentOrder?.cancel_date &&
              <tr>
                <td style={{ width: "120px" }}>F. cancelación:</td>
                <td>
                  <span className='fw-bold ps-5'>{shipmentOrder?.cancel_date ? moment(shipmentOrder.cancel_date).format("DD/MM/YYYY HH:mm") : ''}</span>
                </td>
              </tr>
            }

            {shipmentOrder?.returned_reason_name &&
              <>
                <tr>
                  <td style={{ width: "120px" }}>F. devolución:</td>
                  <td>
                    <span className='fw-bold ps-5'>{shipmentOrder?.returned_date ? moment(shipmentOrder.returned_date).format("DD/MM/YYYY HH:mm") :''}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "120px" }}>Motivo devolución:</td>
                  <td>
                    <span className='fw-bold ps-5'>{shipmentOrder?.returned_reason_name}</span>
                  </td>
                </tr>
              </>
            }
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Header