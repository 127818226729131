import { useState, useEffect } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { AuthModal } from './'
import { SalesOrderStatusI } from 'interfaces'

const TableActions = ({ loading, data, selectedRows, handleSelectedRows, handleGetSalesOrders }) => {
  // const [optionsEnabled, setOptionsEnabled] = useState(false)
  const [auth, setAuth] = useState(true)
  const [modal, setModal] = useState(false)

  const [allSelected, setAllSelected] = useState(false)

  const handleSelectAll = () => {
    if (allSelected) {
      handleSelectedRows([])
    } else {
      const pendingRows = data.filter(d => d.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
        .map(d => ({
          PedidoId: d.id,
          status_id: d.sales_order_status_id,
          fechaAutorizacion: d.profitability_auth_sent_date,
          checked: true,
        }))
      handleSelectedRows(pendingRows)
    }

    setAllSelected(!allSelected)
  }

  const multipleAuth = async () => {
    const canMultipleAuth = selectedRows.every(row => row.status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)

    if (!canMultipleAuth) {
      toast.error(`Todos los pedidos deben estar en el estado "En autorización de rentabilidad"`);
      return
    }

    setAuth(true)
    setModal(true)
  }

  const multipleUnauth = async () => {
    const canMultipleAuth = selectedRows.every(row => row.status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)

    if (!canMultipleAuth) {
      toast.error(`Todos los pedidos deben estar en el estado "En autorización de rentabilidad"`);
      return
    }

    setAuth(false)
    setModal(true)
  }

  // useEffect(() => {
  //   setOptionsEnabled(selectedRows?.length !== 0)
  // }, [selectedRows])

  useEffect(() => {
    const pendingRows = data.filter(d => d.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
    setAllSelected(selectedRows.length === pendingRows.length)
  }, [selectedRows, data])

  return (
    <>
      <AuthModal
        auth={auth}
        show={modal}
        onClose={() => setModal(false)}
        selectedRows={selectedRows}
        handleSelectedRows={handleSelectedRows}
        handleGetSalesOrders={handleGetSalesOrders}
      />

      <div className='d-flex justify-content-between align-items-center mb-2 w-100'>
        <div className='d-flex align-items-center'>
          <input
            id='credit-checkbox-all'
            name="credit-checkbox-all"
            type="checkbox"
            onChange={handleSelectAll}
            checked={allSelected}
          />
          <label htmlFor='credit-checkbox-all' className='ms-2'>{allSelected ? 'Deseleccionar' : 'Seleccionar'} todo</label>
        </div>

        {/* {optionsEnabled && */}
        <DropdownButton
          title={
            <>
              <i />
              { loading ? 'Cargando...' : 'Acciones'}
            </>
          }
          disabled={loading}
          align={{ lg: 'end' }}
        >
            <Dropdown.Item onClick={multipleAuth} disabled={selectedRows?.length < 1}>
              <i className={`bi bi-check-circle text-primary pe-2`} />
              Autorizar
            </Dropdown.Item>

            <Dropdown.Item onClick={multipleUnauth} disabled={selectedRows?.length < 1}>
              <i className={`bi bi-x-circle text-danger pe-2`} />
              No autorizar
            </Dropdown.Item>
        </DropdownButton>
        {/* } */}
      </div>
    </>
  )
}

export default TableActions