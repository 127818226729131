import { useEffect, useMemo, useState } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import routes from 'app/routing/routes'
import { DetailContainer, TabsContainer } from 'containers'
import {  useIncomeOrder } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { AssignmentOutlined, Inventory2Outlined } from '@mui/icons-material'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GeneralTab from './GeneralTab'
import ArticlesTab from './ArticlesTab'
import { formikInitialValues, schema } from './constants'
import { currentDate } from 'utilities';
import { RequestSelectorModal } from './RequestsSelectorModal'
import moment from 'moment'

const LogisticIncomeAdd = () => {
  const navigate = useNavigate()

  const { loading, handleCreateIncomeOrder } = useIncomeOrder()

  const [initialValues, setInitialValues] = useState(formikInitialValues)
  const [request, setRequest] = useState({})
  const [showModal, setShowModal] = useState(true)

  const tabsContent = useMemo(() => [
    <GeneralTab request={request} />,
    <ArticlesTab />,
  ], [])

  const tabs = useMemo(() => [
    { value: 'general', label: "General", icon: <AssignmentOutlined />, show: true },
    { value: 'articles', label: "Articulos", icon: <Inventory2Outlined />, show: true, disabled:true }
  ])

  const onCloseModal = (data) => {
    setShowModal(false)

    if (data && data.id) {
      setRequest(data)
    } else {
      navigate(routes.LOGISTIC_INCOME)
    }
  }

  const createIncomeOrder = async (data) => {
    try {
      const response = await handleCreateIncomeOrder(data)

      if (response) {
        toast.success("Pedido guardado correctamente", {theme: "colored"})
        navigate(`${routes.LOGISTIC_INCOME}/${response.id}`, { state: { tab: 'articles', in_request_id: data.in_request_id }})
      }
    } catch (err) {
      console.log(err)
      toast.error(err.message, { theme: "colored" })
    }
  }

  const handleSubmit = async (values) => {
    const { id, supplier_name, payment_condition_label, income_date, ...rest } = values

    if ((!rest.waybill_number || rest.waybill_number.length < 12) && (!rest.supplier_invoice || rest.supplier_invoice.length < 12)) {
      toast.error("Debe ingresar Nro. de remito o Nro. de factura")
      return
    }
    if (rest.waybill_number && !(rest.waybill_number.length >= 12 && rest.waybill_number.length <= 20)) {
      toast.error("El remito debe tener 12 caracteres como mínimo y 20 caracteres como máximo, incluyendo la letra R")
      return
    }
    if (rest.supplier_invoice && !(rest.supplier_invoice.length >= 12 && rest.supplier_invoice.length <= 20)) {
      toast.error("La factura debe tener 12 caracteres como mínimo y 20 caracteres como máximo, incluyendo la letra")
      return
    }

    const data = {
      ...rest,
      storage_space_id: 1
      //   order_type_id:income?2:1,
      //   payment_condition_id:income?null:rest.payment_condition_id
    }

    await createIncomeOrder(data)
  }

  useEffect(() => {
    if (request.id) {
      const { id: in_request_id, supplier_id, supplier_name, distributor_id, distributor_name, payment_condition_label = '', notes, internal_notes, issue_date, 
        order_type_label, order_type_id } = request
      setInitialValues({
        in_request_id,
        supplier_id,
        supplier_name,
        distributor_id,
        distributor_name: distributor_name || '',
        payment_condition_label,
        notes,
        internal_notes,
        order_type_label,
        order_type_id,
        income_date: currentDate,
        issue_date: issue_date ? moment(issue_date).format("YYYY-MM-DD") : '',
        supplier_invoice: "",
        supplier_invoice_date: '',
        waybill_number: '',
        carrier: '',
        storage_space_id: null
      })
    }
  }, [request])

  return (
    <>
      <RequestSelectorModal show={showModal} onClose={onCloseModal}/>

      <Formik
        initialValues={initialValues}
        validationSchema={ schema }
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched }) => {
          return (
            <Form className="d-flex flex-column mt-4" >
            <DetailContainer
              title={`Nueva orden de Ingreso`}
              buttons={
                <div className='align-self-center'>
                  <Button
                    type='button'
                    variant="light"
                    onClick={() => navigate(-1)}
                    className='me-3'
                    disabled={loading}
                  >
                    <i className="ki-duotone ki-left" />
                    Volver
                  </Button>

                  <Button
                    type='button'
                    variant="secondary"
                    onClick={() => setShowModal(true)}
                    className='me-3'
                    disabled={loading}
                  >
                    <i className="bi bi-search" />
                    Buscar pedido
                  </Button>

                  <Button
                    type='submit'
                    variant="primary"
                    disabled={loading}
                  >
                    <i className="bi bi-save me-2" />
                    Guardar
                  </Button>
                </div>
              }
            >
              <div>
                <TabsContainer
                  initialValue='general'
                  tabs={tabs}
                  tabsContent={tabsContent}
                />
              </div>
            </DetailContainer>
          </Form>
        )}}
      </Formik>
    </>
  )
}

export default LogisticIncomeAdd