import React from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'

import { Input, SelectField } from 'components'

const Filter = ({ loading, query, setQuery }) => {
  const handleFilter = (values) => {
    let params = {
      ...query,
      ...values,
      id_cliente_para_remitir: values?.id_cliente_para_remitir || undefined,
      para_remitir: values?.para_remitir || undefined,
      activo: values?.activo || undefined,
    }

    setQuery({ ...params, pageNumber: 1, pageSize: 10 })
  }

  return (
    <Formik
      initialValues={{
        id: '',
        text: '',
        activo: '',
      }}
      onSubmit={handleFilter}
    >
      {({ values, errors, handleSubmit }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleSubmit()
            }
          }}
        >
          <div className='row'>
            <div className='col-2'>
              <Input id='id_cliente_para_remitir' name='id_cliente_para_remitir' disabled={loading} />
              <p className='text-muted'>ID</p>
            </div>

            <div className='col-6'>
              <Input id='para_remitir' name='para_remitir' disabled={loading} />
              <p className='text-muted'>Nombre</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='activo'
                name='activo'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Sí' },
                  { value: '0', label: 'No' },
                ]}
                marginTop={'-20px'}
              />
            </div>
          </div>

          <div className='row'>
            <div className='col text-end'>
              <Button
                type='button'
                variant='primary'
                onClick={() => {
                  handleSubmit()
                }}
                disabled={loading}
              >
                <i className='bi bi-search' />
                {loading ? 'Buscando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter