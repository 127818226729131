import { useCallback, useState } from 'react'

import { SalesOrders } from 'services'

const useSalesOrders = () => {
  const [loading, setLoading] = useState(false)

  const handleGetSalesOrders = useCallback(async (params, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getSalesOrders(params, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetSalesOrder = useCallback(async (id, params, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getSalesOrder(id, params, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetSalesOrderStatus = useCallback(async (params, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getSalesOrderStatus(params, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddSalesOrder = useCallback(async (data, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.addSalesOrder(data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddOutRequest = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.addOutRequest(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateSalesOrder = useCallback(async (id, data, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateSalesOrder(id, data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateSalesOrderStatus = useCallback(async (id, status_id, params) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateSalesOrderStatus(id, status_id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCheckSalesOrderPVP = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.checkSalesOrderPVP(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateOutRequestStatus = useCallback(async (id, status_id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateOutRequestStatus(id, status_id)

      return response
    } catch(error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddDetail = useCallback(async (id, data, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.addDetail(id, data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddOutRequestDetail = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.addOutRequestDetail(id, data)

      return response
    } catch(error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDetail = useCallback(async (id, detail_id, data, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateDetail(id, detail_id, data, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdatePrice = useCallback(async (id, detail_id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateDetailPrice(id, detail_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateOutRequestDetail = useCallback(async (id, detail_id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateOutRequestDetail(id, detail_id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteDetail = useCallback(async (id, detail_id, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.deleteDetail(id, detail_id, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteOutRequestDetail = useCallback(async (id, detail_id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.deleteOutRequestDetail(id, detail_id)

      return response
    } catch(error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCloneSalesOrder = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.cloneSalesOrder(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateOutOrder = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.createOutOrder(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetSalesOrderFiles = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getSalesOrderFiles(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetSalesOrderFile = useCallback(async (id, file_id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getSalesOrderFile(id, file_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddSalesOrderFile = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.addSalesOrderFile(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteSalesOrderFile = useCallback(async (id, file_id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.deleteSalesOrderFile(id, file_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleReactivateOutOrder = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.reactivateOutOrder(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleReactivateOutOrderAll = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.reactivateOutOrderAll()

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])


  const handleGetComprobantesAsociados = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getComprobantesAsociados(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintSalesOrder = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.printSalesOrder(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleRemoveReserved = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.removeReserved(data)
      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreatePartial = useCallback(async (sales_order_id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.createPartial(sales_order_id)
      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPendingDetail = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getPendingDetail(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPendingRent = useCallback(async (params, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getPendingRent(params, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetPendingRentDetail = useCallback(async (params, token) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getPendingRentDetail(params, token)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancelSalesOrderSpecial = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.cancelSalesOrderSpecial(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancelacionParcial = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.cancelacionParcial(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAuthMultipleProfitability = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.authMultipleProfitability(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUnauthMultipleProfitability = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.unauthMultipleProfitability(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdatePurchaseOrder = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updatePurchaseOrder(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateObservations = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateObservations(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleUpdateDispatchObservations = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.updateDispatchObservations(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetNotes = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await SalesOrders.getNotes(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleAddNote = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await SalesOrders.addNote(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleGetSalesOrders,
    handleGetSalesOrder,
    handleAddSalesOrder,
    handleAddOutRequest,
    handleUpdateSalesOrder,
    handleUpdateOutRequestStatus,
    handleGetSalesOrderStatus,
    handleUpdateSalesOrderStatus,
    handleCheckSalesOrderPVP,
    handleAddDetail,
    handleAddOutRequestDetail,
    handleUpdateDetail,
    handleUpdatePrice,
    handleUpdateOutRequestDetail,
    handleDeleteDetail,
    handleDeleteOutRequestDetail,
    handleCloneSalesOrder,
    handleCreateOutOrder,
    handleGetSalesOrderFiles,
    handleGetSalesOrderFile,
    handleAddSalesOrderFile,
    handleDeleteSalesOrderFile,
    handleReactivateOutOrder,
    handleReactivateOutOrderAll,
    handleGetComprobantesAsociados,
    handlePrintSalesOrder,
    handleRemoveReserved,
    handleCreatePartial,
    handleGetPendingDetail,
    handleGetPendingRent,
    handleGetPendingRentDetail,
    handleCancelSalesOrderSpecial,
    handleCancelacionParcial,
    handleAuthMultipleProfitability,
    handleUnauthMultipleProfitability,
    handleUpdatePurchaseOrder,
    handleUpdateObservations,
    handleUpdateDispatchObservations,
    handleGetNotes,
    handleAddNote,
  }
}

export default useSalesOrders