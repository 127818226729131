import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx/xlsx.mjs'
import moment from 'moment'

import { useClickOutside, useWaybill, useDispatchOrders } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter } from './partials'
import useGetColumns from './columns'
import NewDispatchModal from './partials/NewDispatchModal'

import routes from 'app/routing/routes'

export const ESTADOS_ORDEN_DESPACHO = {
  PENDIENTE: 1,
  EN_DISTRIBUCION: 2,
  CANCELADO: 3
}

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC',
  dispatch_order_status_id_filter: [ESTADOS_ORDEN_DESPACHO.PENDIENTE]
}

export const labelColor = (order_delivery_status_id) => {
  switch (order_delivery_status_id) {
    case ESTADOS_ORDEN_DESPACHO.PENDIENTE:
      return "estado-celeste"
    case ESTADOS_ORDEN_DESPACHO.EN_DISTRIBUCION:
      return "estado-azul"
    case ESTADOS_ORDEN_DESPACHO.CANCELADO:
      return "estado-rojo"
    default:
      return ""
  }
}

const DispatchOrders = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  const handleRowSelection = useCallback((rowId, statusId, shippingCarrierServiceId) => {
    if (selectedRows.length === 0) {
      setSelectedRows([{ id: rowId, status_id: statusId, shipping_carrier_service_id: shippingCarrierServiceId, checked: true }])

      return
    }

    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.some(i => i.shipping_carrier_service_id !== shippingCarrierServiceId)) {
        return prevSelectedRows
      }

      if (prevSelectedRows.some(i => i.id === rowId)) {
        return prevSelectedRows.filter(i => i.id !== rowId)
      } else {
        return [...prevSelectedRows, { id: rowId, status_id: statusId, checked: true }]
      }
    })
  }, [selectedRows])

  const [newDispatchModal, setNewDispatchModal] = useState(false)

  const { COLUMNS } = useGetColumns(selectedRows, handleRowSelection)

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [filter, setFilter] = useState("")
  const [totalCount, setTotalCount] = useState(0)

  const { /*handleGetDispatchOrders,*/ handleCancelDispatchOrder, handleConfirmDispatch } = useWaybill()
  const { handleGetDispatchOrders, handleGetDispatchOrderDetail } = useDispatchOrders()

  const getData = useCallback(async () => {
    try {
      const response = await handleGetDispatchOrders({ ...queryMD, filter })

      const dataColored = response.data?.result.map(d => ({ ...d, label_color: labelColor(d.dispatch_order_status_id) }))
      setData(dataColored)

      // setData(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      toast.error(error.message)
    }
  }, [handleGetDispatchOrders, queryMD, filter])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }
    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: actionEdit
    })

    if (currentRow?.dispatch_order_status_id === ESTADOS_ORDEN_DESPACHO.PENDIENTE) {
      actions.push({
        label: "Despachar",
        icon: "bi bi-truck text-primary",
        action: actionDispatch
      })
      actions.push({
        label: "Cancelar",
        icon: "bi bi-x-circle text-danger",
        action: actionCancel
      })
    }

    if (currentRow?.dispatch_order_status_id != ESTADOS_ORDEN_DESPACHO.CANCELADO) {
      actions.push({
        label: "Hoja de ruta",
        icon: "bi bi-download text-success",
        action: ()=>actionPrint(currentRow?.id)
      })
    }

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.LOGISTICS_DISPATCH_ORDERS}/${currentRow.id}`);
    }
  }

  const actionPrint = async (id) => {
    if (id) {
      try {
        const response = await handleGetDispatchOrderDetail(id, { pageNumber: 1 })

        if (response?.data?.result) {
          const datos = response.data.result.map(d => {
            let data = {
              "Despacho": d.dispatch_order_id,
              "F. Creación": moment(d.created_at).format('DD/MM/YYYY'),
              "O. envío": d.order_delivery_id,
              "Domicilio (envío)": `${d.address_ship?.domicilio}`,
              "Localidad (envío)": d.address_ship?.localidad_completa,
              "CP (envío)": d.address_ship?.postal_code,
              "Provincia (envío)": d.address_traza?.province,
              "Domicilio (traza)": `${d.address_traza?.domicilio}`,
              "Localidad (traza)": d.address_traza?.localidad_completa,
              "CP (traza)": d.address_traza?.postal_code,
              "Provincia (traza)": d.address_traza?.province,
              "Transportista": d.shipping_carrier_service?.shipping_carrier_name,
              "Servicio": d.shipping_carrier_service?.shipping_carrier_service,
            }

            return data
          })

          var ws = XLSX.utils.json_to_sheet(datos)
          var wb = XLSX.utils.book_new()

          XLSX.utils.book_append_sheet(wb, ws, "Hoja de Ruta")
          XLSX.writeFile(wb, `Despacho ${id} - ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
        }
      } catch (error) {
        setData([])
        toast.error(error.message)
      }
    }
  }

  const actionDispatch = async () => {
    if (currentRow) {
      const result = await Swal.fire({
        title: `Confirmar`,
        text: `¿Confirma el despacho #${currentRow.id}?`,
        icon:"warning",
        showDenyButton:true,
        confirmButtonText:"Si, confirmar",
        denyButtonText:"No, cancelar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true
      })

      if (result.isConfirmed) {
        try {
          const response = await handleConfirmDispatch(currentRow.id)
          toast.success("El despacho fue procesado correctamente", { theme: "colored" })
          getData();

        } catch (error) {
          toast.error(error.message)
        }
      }
    }
  }

  const actionCancel = async () => {
    if (currentRow) {
      const result = await Swal.fire({
        title: `¿Está seguro de cancelar la orden de despacho #${currentRow.id}?`,
        text: "Esta acción no se puede revertir",
        icon: 'warning',
        showDenyButton: true,
        confirmButtonText: 'Si, cancelar',
        denyButtonText: 'No, cerrar',
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (result.isConfirmed) {
        try {
          await handleCancelDispatchOrder(currentRow.id)
          toast.success('Orden de despacho cancelada correctamente')
        } catch (error) {
          toast.error(error.message)
        } finally {
          getData()
        }
      }
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => (
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event=>handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
      )
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery:queryMD ,
    setObQuery:setQueryMD
  };

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <>
      <NewDispatchModal show={newDispatchModal} onHide={() => setNewDispatchModal(false)} handleGetDispatchOrders={getData} />

      <TableContainer
        title='Órdenes de Despacho'
        filter={<Filter  queryMD={queryMD} setQueryMD={setQueryMD} />}
        createButton={
          <>
            <Button variant='primary' onClick={() => setNewDispatchModal(true)}>
              <i className='bi bi-plus-lg me-2' />
              Crear
            </Button>
          </>
        }
      >
        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} handleContextMenu={handleContextMenu} responsive={false} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default DispatchOrders