import { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import  { useStock, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { DepotMovementStatusI } from 'interfaces'

import { COLUMNS } from './detailStockColumns'

const initialQuery = {
  pageNumber: 1,
  pageSize: undefined,
}

export const DepotMovementDetailStockModal = ({ show, onHide, movement, detail, handleGetDepotMovement, handleGetDepotMovementDetailDetail }) => {
  const { id } = useParams()

  const { loading, handleGetDepotMovementDetail, handleDeleteDepotMovementDetailById } = useStock()
  const [detailStock, setDetailStock] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getDepotMovementDetail = useCallback(async () => {
    try {
      const params = {
        ...query,
        product_var_id: detail?.id,
      }
      const response = await handleGetDepotMovementDetail(id, params)
      const data = response.data.result

      setDetailStock(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setDetailStock([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [id, detail, handleGetDepotMovementDetail])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    if (movement.depot_movement_status_id == DepotMovementStatusI.EN_PREPARACION) {
      actions.push({
        label: 'Eliminar unidad',
        icon: 'bi bi-trash text-danger',
        action: () => deleteDepotMovementDetailById(currentRow.id)
      })
    }

    return actions
  }

  const deleteDepotMovementDetailById = async (detail_id) => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a eliminar el detalle #${detail_id} ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, eliminar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteDepotMovementDetailById(id, detail_id)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getDepotMovementDetail()
      handleGetDepotMovement()
      handleGetDepotMovementDetailDetail()
    }
  }
  /* Fin menu contextual */

  const columns = useMemo(() => {
    let cols = [
      ...COLUMNS,
    ]

    if (movement.depot_movement_status_id == DepotMovementStatusI.EN_PREPARACION) {
      cols.push({
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
      })
    }

    return cols
  }, [COLUMNS])

  const paginationOptions = {
    queryMD: query,
    setQueryMD: setQuery,
    totalCount: totalCount,
  }

  useEffect(() => {
    if (show) {
      getDepotMovementDetail()
    }
  }, [show, getDepotMovementDetail])

  if (!show) return null

  return (
    <Modal show={show} onHide={onHide} backdrop='static' size='lg' centered scrollable>
      <Modal.Header
        closeButton={() => {
          setDetailStock([])
          setTotalCount(0)
          onHide()
        }}
      >
        <Modal.Title>Detalle por unidades de #{detail?.id} | {detail?.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TableContainer>
          <CustomTable columns={columns} data={detailStock} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
        </TableContainer>

        <ContextMenu
          ref={contextRef}
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
        />
      </Modal.Body>

      <Modal.Footer>
        <div className='row'>
          <div className="col-12 text-end">
            <Button
              type='button'
              variant='secondary'
              onClick={() => {
                setDetailStock([])
                setTotalCount(0)
                onHide()
              }}
              className='me-3'
              disabled={loading}
            >
              <i className='bi bi-slash-circle' />
              Cerrar
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}