import { useState, useEffect, useCallback } from 'react'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import { helix } from 'ldrs'

import { DetailContainer } from 'containers'
import { useStock } from 'hooks'

import Filter from './Filter'
import { Link } from 'react-router-dom'
import routes from 'app/routing/routes'
import { Tooltip } from 'components'
import EventosModal from './EventosModal'

const initialQuery = {
  serial_number: '',
}

const BuscadorSerie = () => {
  const { loading, handleGetArticuloPorSerie, handleGetEventos } = useStock()
  const [query, setQuery] = useState(initialQuery)
  const [serie, setSerie] = useState(null)

  const [eventosModal, setEventosModal] = useState(false)

  const getSerie = useCallback(async () => {
    try {
      let params = {
        ...query,
      }
      const response = await handleGetArticuloPorSerie(params)
      const data = response.data.result

      setSerie(data)
    } catch (error) {
      setSerie(null)
      toast.error(error.message)
    }
  }, [handleGetArticuloPorSerie, query])

  useEffect(() => {
    getSerie()
  }, [getSerie])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <DetailContainer title='Buscador por serie'>
      <Filter loading={loading} query={query} setQuery={setQuery} />

      {loading && !serie && <l-helix color="var(--bs-primary)" style={{position: "absolute", left: "50%", marginTop:"100px"}}></l-helix>}

      {serie &&
        <>
          <div className='separator mb-6'></div>

          <div className='row fs-6'>
            <div className='col-6'>
              <p>ID Stock: <strong>#{serie?.id}</strong></p>
              <p>Artículo: <strong>[{serie?.product_var_id}] {serie?.product_var_name}</strong></p>
              <p>Nro. de serie: <strong>{serie?.serial_number}</strong></p>
              <p>GTIN: <strong>{serie?.gtin}</strong></p>
              <p>Depósito: <strong>{serie?.storage_space_name}</strong></p>
              <p>Lote: <strong>{serie?.lot_number}</strong> - Venc.: {moment(serie?.expiration_date).format('MM/YYYY')}</p>
            </div>

            <div className='col-1 text-center'>
              <div style={{ borderLeft: '1px solid #ddd', height: '100%', margin: '0 0 0 30px' }}></div>
            </div>

            <div className='col-5'>
              <div className='row'>
                <div className='col-4'>
                  <p>
                    <strong>Disponible: {serie?.a}</strong>
                  </p>

                  <p>
                    <strong>No disponible: {serie?.na}</strong>
                  </p>

                  <p>
                    <strong>Reservado: {serie?.r}</strong>
                  </p>

                  <p>
                    <strong>Pickeado: {serie?.p}</strong>
                  </p>

                  <p>
                    <strong>Egreso: {serie?.o}</strong>
                  </p>
                </div>

                <div className='col d-flex align-items-center justify-content-center'>
                  <Button type='button' variant='primary' onClick={() => setEventosModal(true)} disabled={loading}>
                    <i className='bi bi-bar-chart-steps me-1' />
                    Ver eventos
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className='row mt-6'>
            <p>
              <strong>Últ. ingreso: </strong>

              <strong>
                <Tooltip text='Ir a orden de ingreso'>
                  <Link to={`${routes.LOGISTIC_INCOME}/${serie?.in_order_id}`} state={{ in_request_id: serie?.in_request_id }}>
                    Orden de ingreso #{serie?.in_order_id}
                  </Link>
                </Tooltip>
              </strong>
            </p>

            {serie?.out_order_id &&
              <p>
                <strong>Últ. {serie?.r == 1 ? 'reserva:' : serie?.p == 1 ? 'picking:' : serie?.o == 1 ? 'egreso:' : '' } </strong>

                <strong className='text-danger'>
                  <Tooltip text='Ir a orden de egreso'>
                    <Link to={`${routes.OUT_ORDERS}/${serie.out_order_id}`}>
                      Orden de egreso #{serie?.out_order_id}
                    </Link>
                  </Tooltip>
                </strong>
              </p>
            }
          </div>
        </>
      }

      <EventosModal serie={serie} show={eventosModal} onClose={() => setEventosModal(false)} />
    </DetailContainer>
  )
}

export default BuscadorSerie