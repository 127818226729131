import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';

import { TransactionalDocumentTypeI } from 'interfaces'
import { currencyFormat } from 'utilities';

const types = {
  [TransactionalDocumentTypeI.FACTURA]: 'Factura',
  [TransactionalDocumentTypeI.NOTA_DEBITO]: 'Nota de débito',
  [TransactionalDocumentTypeI.NOTA_CREDITO]: 'Nota de crédito',
  [TransactionalDocumentTypeI.REMITO]: 'Remito',
}

const useGetColumns = () => {

  const COLUMNS = [
    {
      Header: <p className='m-0 p-0 text-center'>ID</p>,
      id: 'id',
      accessor: (row) => (
        <p className='m-0 p-0'>
          {row.id}
        </p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0 text-center'>Fecha</p>,
      id: 'fecha',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.fecha ? moment(row.fecha).format("DD/MM/YYYY") : '-'}
        </p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0'>Tipo</p>,
      id: 'tipo_comprobante',
      accessor: (row) => (
        <p className='m-0 p-0'>
          {row.tipo_comprobante}
        </p>
      )
    },
    {
      Header: <p className='m-0 p-0 text-center'>Número</p>,
      id: 'nro_completo',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.nro_completo}
        </p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0'>Cliente / Proveedor</p>,
      id: 'razon_social',
      accessor: (row) => (
        <div>
          {row.razon_social?.split(';')?.length > 1
            ?
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip id={`${row.id_comprobante}-tooltip-clientes`}>
                    <div>
                      {row.razon_social.split(';').map((item, index) => (
                        <div key={index} className='text-start'>{item.trim()}</div>
                      ))}
                    </div>
                  </Tooltip>
                }
              >
                <i className='bi bi-info-circle text-warning ms-2' />
              </OverlayTrigger>
            : <strong>{row.razon_social}</strong>
          }
        </div>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0 text-center'>IDs Pedidos</p>,
      id: 'sales_order_ids',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.sales_order_ids?.split(',')?.join(', ')}
        </p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0 text-center'>CAE</p>,
      id: 'cae',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.cae || '-'}
        </p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0 text-end'>Total</p>,
      id: 'total',
      accessor: (row) => (
        <p className='m-0 p-0 text-end'>
          {currencyFormat.format(row?.total || 0)}
        </p>
      ),
      hasOrder: true
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns