import { useState, useCallback, useEffect } from 'react'
import { Form, Formik } from 'formik'
import { Input, SelectField } from 'components'
import { Button } from 'react-bootstrap'

import { useAgentTypes } from 'hooks'
import { toast } from 'react-toastify'

const initialValues = {
  from: 'CLIENTES',
  id: '',
  text: '',
  localidad: '',
  gln: '',
  has_traceability: '',
  agent_type_id_filter: [],
  is_enabled: '',
}

const LinkShipmentAddressFilters = ({ loading, query, setQuery }) => {
  const { handleGetAgentTypes } = useAgentTypes()
  const [agents, setAgents] = useState([])

  const getAgents = useCallback(async () => {
    try {
      const response = await handleGetAgentTypes()
      const data = response.result.map(d => ({
        value: d.id,
        label: d.name,
      }))

      setAgents(data)
    } catch (error) {
      setAgents([])
      toast.error(error.message)
    }
  }, [handleGetAgentTypes])

  const filterValues = (values) => {
    setQuery({
      ...query,
      ...values,
      from: values?.from || undefined,
      id: values?.id || undefined,
      text: values?.text || undefined,
      localidad: values?.localidad || undefined,
      gln: values?.gln || undefined,
      has_traceability: values?.has_traceability || undefined,
      agent_type_id_filter: values?.agent_type_id_filter || undefined,
      is_enabled: values?.is_enabled || undefined,
      pageNumber: 1,
      pageSize: 10,
    })
  }

  useEffect(() => {
    getAgents()
  }, [getAgents])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={filterValues}
      enableReinitialize
    >
      {({ values, errors, handleSubmit }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleSubmit()
            }
          }}
        >
          <div className='row'>
            <div className='col-2'>
              <SelectField
                id='from'
                name='from'
                options={[
                  { value: 'CLIENTES', label: 'Clientes' },
                  { value: 'PACIENTES', label: 'Pacientes' },
                  { value: 'SIN_RELACION', label: 'Sin relación' },
                ]}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>De</p>
            </div>

            <div className='col-2'>
              <Input id='id' name='id' disabled={loading} />
              <p className='text-muted'>Nro.</p>
            </div>

            <div className='col-6'>
              <Input id='text' name='text' disabled={loading} />
              <p className='text-muted'>Nombre / Domicilio</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='has_traceability'
                name='has_traceability'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Sí' },
                  { value: '0', label: 'No' },
                ]}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Trazabilidad</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-5'>
              <Input id='localidad' name='localidad' disabled={loading} />
              <p className='text-muted'>Localidad</p>
            </div>

            <div className='col-3'>
              <Input id='gln' name='gln' disabled={loading} />
              <p className='text-muted'>GLN</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='agent_type_id_filter'
                name='agent_type_id_filter'
                options={agents}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
                isMulti
              />
              <p className='text-muted'>Tipo(s) de agente(s)</p>
            </div>
          </div>

          <div className='row'>
            {/* <div className='col-2'>
              <SelectField
                id='integrates_verifarma'
                name='integrates_verifarma'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Sí' },
                  { value: '0', label: 'No' },
                ]}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Verifarma</p>
            </div> */}

            <div className='col-2'>
              <SelectField
                id='is_enabled'
                name='is_enabled'
                options={[
                  { value: '', label: 'Todos' },
                  { value: '1', label: 'Sí' },
                  { value: '0', label: 'No' },
                ]}
                placeholder='Seleccionar'
                marginTop={'-20px'}
                disabled={loading}
              />
              <p className='text-muted'>Activo</p>
            </div>
          </div>

          <div className='row'>
            <div className='col text-end'>
              <Button type='button' variant='primary' onClick={handleSubmit} disabled={loading}>
                <i className='bi bi-search' />
                {loading ? 'Buscando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default LinkShipmentAddressFilters