import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useUsers } from 'hooks'

import { DetailContainer } from 'containers'
import { InputBS, SelectField } from 'components'

import './styles.css'

const initialValues = {
  id: '',
  name: '',
  email: '',
  roles: '',
  roles_ids: '',
  password: '',
  avatar: '',
}

const UsuarioDetalle = () => {
  const { id } = useParams()
  const [title, setTitle] = useState(`#${id}`)
  const [editing, setEditing] = useState(false)

  const { loading, handleObtenerUsuario, handleObtenerRoles } = useUsers()

  const [data, setData] = useState(initialValues)
  const [roles, setRoles] = useState([])

  const getUser = useCallback(async () => {
    try {
      const response = await handleObtenerUsuario(id)
      let result = response.data.result
      const selectedRoles = response.data.result.roles_ids.split(';').map(r => Number(r))

      result.roles = selectedRoles

      setTitle(`#${id} | ${result.name}`)
      setData(result)
    } catch (error) {
      setTitle(`#${id}`)
      setData({})
      toast.error(error.message)
    }
  }, [handleObtenerUsuario, id])

  const getRoles = useCallback(async () => {
    try {
      const params = {}
      const response = await handleObtenerRoles(params)
      const result = response.data.result.map(r => ({
        value: r.id,
        label: r.name
      }))

      setRoles(result)
    } catch (error) {
      setRoles([])
      toast.error(error.message)
    }
  }, [handleObtenerRoles])

  const changeAvatar = () => {
    try {
      console.log(`Cambiar avatar de usuario #${id}`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    getUser()
    getRoles()
  }, [getUser, getRoles])

  return (
    <DetailContainer title={title}>
      <Formik
        initialValues={data}
        // validationSchema={{}}
        enableReinitialize
      >
        {({ values, errors }) => (
          <Form>
            <div className='row my-6'>
              <div className='col-6 text-center'>
                <div className="image-button-container">
                  <div className='image-container'>
                    <img
                      className='rounded-top custom-image'
                      src={`/media/avatars/${values.avatar}`}
                      alt="Logo"
                    />

                    <div className="hover-text">Haga clic para actualizar la imagen</div>
                  </div>

                  <Button
                    type='button'
                    variant='primary'
                    disabled
                    className='w-100 custom-button'
                    onClick={changeAvatar}
                  >
                    Cambiar imagen
                  </Button>
                </div>
              </div>

              <div className='col-6'>
                <div className='row'>
                  <div className='col-2'>
                    <InputBS id='id' name='id' label='ID' disabled />
                  </div>

                  <div className='col'>
                    <InputBS
                      id='name'
                      name='name'
                      label='Nombre'
                      placeholder='Nombre del usuario'
                      disabled={!editing && loading}
                    />
                  </div>
                </div>

                <div className='row mt-6'>
                  <div className='col'>
                    <InputBS
                      id='email'
                      name='email'
                      type='email'
                      label='Email'
                      placeholder='Email del usuario'
                      disabled={!editing && loading}
                    />
                  </div>
                </div>

                <div className='row mt-6'>
                  <div className='col'>
                    <InputBS
                      id='password'
                      name='password'
                      type='password'
                      label='Contraseña'
                      placeholder='Contraseña del usuario'
                      disabled
                    />
                  </div>
                </div>

                <div className='row mt-6'>
                  <div className='col'>
                    <SelectField
                      id='roles'
                      name='roles'
                      options={roles}
                      placeholder='Rol(es) del usuario'
                      isMulti
                      // disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {JSON.stringify(data)}
    </DetailContainer>
  )
}

export default UsuarioDetalle