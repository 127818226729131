import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, FormGroup, FormLabel, Modal, Table } from "react-bootstrap";
import '../article.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormikContext } from "formik";
import {Logistic} from 'services'
import { FireTruckOutlined } from "@mui/icons-material";
import { ZebraScanner } from "components";
import { parseGS1DataMatrix } from "utilities/gs1DataMatrix";
import moment from 'moment';
import { useIncomeOrder } from "hooks";

const defaultCounters={
  picking:0,
  ok:0,
  error:0
}


export function ArticleScanModal({ show, onHide, in_order_id, storageSpaceId, storageSpace, requestArticles }) {

    const [scanCodes, setScanCodes] = useState([]);
    const { values, setFieldValue } = useFormikContext();
    const [refrigeratedAlert, setRefrigeratedAlert]  = useState(false);
    const [errorTrazables, setErrorTrazables] = useState([]);
    const [transaccionesNoConfirmadas, setTransaccionesNoConfirmadas] = useState([]);
    const [countPicking, setCountPicking] = useState(0);
    const [counters, setCounters] = useState(defaultCounters);
    const scanCodesRef = useRef(scanCodes);
    const {handleGetTransaccionesNoConfirmadas, loading} = useIncomeOrder();
    

    const loadTransaccionesNoConfirmadas = async()=>{
      const resp = await handleGetTransaccionesNoConfirmadas(in_order_id)
      setTransaccionesNoConfirmadas(resp?.mapped?.map(d=>({
        gtin:d.gtin, 
        serial_number:d.serial_number, 
        lot_number:d.lot_number,
        product_var_name:d.product_var_name
      })))
    }

    const handleNewTag = (value, err)=>{
        if(!value){
          toast.error(err.message, {theme: "colored"})
          return;
        }
         setScanCodes(scanCodes=>[value, ...scanCodes]);       
        
        // const newScanCodes = [...scanCodes];
        // newScanCodes.unshift(value);
        
        // setScanCodes(newScanCodes)
        // values.refreshIncomeOrder();
    }
    useEffect(() => {
      scanCodesRef.current = scanCodes;
    }, [scanCodes]);

    const updateTagView = (value)=>{
      // console.log(value)
        if(value.err){
          const newUpdate = [...scanCodesRef.current];
          const cambio = newUpdate.find(a => a.serial_number==value.serial_number);
          // console.log(cambio)
          if(cambio){
            cambio.loading=false;
            cambio.result=value.result;
            cambio.message=value.err?.message;
            setScanCodes(newUpdate);
            setCounters(counters=>({...counters, error: counters.error + 1}))
          } 
        }
        else{
          const newUpdate = [...scanCodesRef.current];
          const cambio = newUpdate.find(a => a.serial_number==value.serial_number);
          // console.log(cambio)
          if(cambio){
            cambio.loading=false;
            cambio.result=value.result;
            setScanCodes(newUpdate);
            if(value.result=='OK'){
              setCounters(counters=>({...counters, ok: counters.ok + 1}))
            }              
            else{
              setCounters(counters=>({...counters, error: counters.error + 1}))
            }
          
            
            
          }  
        }
    }

    const loadAnmatValidate = async()=>{
      const resp = await Logistic.anmatValidate({ in_order_id });
      console.log(resp.data)
    }
    

/*****************************************************************************************/

    const barcodesRef = useRef([]);
    const processingQueue = useRef(false);
    const canAddCode = useRef(true);
    const {handleCreateIncomeOrderDetail} = useIncomeOrder();

    const processEnter = (cadena)=>{
  
      if(canAddCode.current){
        canAddCode.current=false;
        const buffCadena=cadena;
        cadena="";
        const dataTransformada = buffCadena.replace(/Shift/g, "")
            .replace(/CapsLock/g, '') 
            .replace(/Alt0029/g, "\u001D")
            .replace(/'/g, '-')
  
        const data = parseGS1DataMatrix(dataTransformada)
        data.scan_code=dataTransformada;
  
        if(!data.lot_number){
          toast.error("El código GS1 es parcial, se debe pickear con el botón 'GS1 Parcial!'")
          canAddCode.current=true;
          return;
        }

        if (!barcodesRef.current.map(c=>c.serial_number).includes(data.serial_number)) {
            const _product = requestArticles.filter(a=>a.gtin==data.gtin);

            if(!_product || _product.length<1){
                toast.error("El código escaneado es de un gtin no perteneciente al pedido")
                canAddCode.current=true;
                return;
            }

            if(!_product[0].traced){
              toast.error(`El artículo ${_product[0].label} no está marcado como trazable en el sistema`)
              return;
            }
            
            const transfer = {
                qty: 1,
                lot_number: data.lot_number,
                expiration_date: data.expiration_date?moment(data.expiration_date, 'YYMMDD').format("YYYY-MM-DD"):'',
                gtin: data.gtin,
                product_var_id: _product[0]?.value,
                product_var_name: _product[0]?.label,
                serial_number: data.serial_number?data.serial_number:undefined,
                storage_space_id: storageSpaceId,
                in_order_id: in_order_id,
                loading:true,
                code:data.scan_code
            }
            const product=transaccionesNoConfirmadas.filter(t=>t.gtin==transfer.gtin);
            if(product && product.length)
              transfer.product_var_name = product[0].product_var_name;
  
            if(!transfer.lot_number){
                const aux=transaccionesNoConfirmadas.filter(t=>t.serial_number==transfer.serial_number && t.gtin==transfer.gtin);
                if(aux && aux.length){
                  transfer.lot_number=aux[0].lot_number;
                  transfer.expiration_date=aux[0].expiration_date;
                }
                else{


                  
                }
            }
  
            handleNewTag(transfer, null)
            setCountPicking(countPicking=>countPicking+1)
            setCounters(counters=>({...counters, picking: counters.picking + 1}))
            const asyncJob = getAsyncJob(transfer);
            enqueue(asyncJob);
        }
        
        canAddCode.current=true;
      }
    }




    const nextJob = () => {

        if (processingQueue.current) return;
        const next = barcodesRef.current.shift();
        // if the array is empty shift() will return undefined
        if (next) {
          processingQueue.current = true;
          next
            .job()
            .then((value) => {
            //   console.log(value);
              next.resolve(value);
              updateTagView(value)
              processingQueue.current = false;
              nextJob();
            })
            .catch((error) => {
            //   console.error(error);
              next.resolve(error);
              updateTagView(error)
              processingQueue.current = false;
              nextJob();
            });
        }
    }

    const enqueue = (job) => {
        // we'll wrap the job in a promise and include the resolve 
        // and reject functions in the job we'll enqueue, so we can 
        // control when we resolve and execute them sequentially
        new Promise((resolve, reject) => {
            barcodesRef.current.push({ job, resolve, reject });
        });
        // we'll add a nextJob function and call it when we enqueue a new job;
        // we'll use _isBusy to make sure we're executing the next job sequentially
        nextJob();
    }

    const getAsyncJob = (item) => {
        return () =>
          new Promise((resolve, reject) => {
            const transport = { ...item, in_order_id: in_order_id }
            handleCreateIncomeOrderDetail(transport).then(res=>resolve(res)).catch(err=>reject({...transport, err}))
          });
    };



/****************************************************************************************** */





    useEffect(()=>{
      if(show){
        setScanCodes([])
        setRefrigeratedAlert(false)
        setErrorTrazables([])
        setCountPicking(0)
        loadTransaccionesNoConfirmadas()
        setCounters(defaultCounters)
        // loadAnmatValidate()
      }        
    }, [show])

    useEffect(()=>{
        /* cool_type_id
          1   -> ambiente
          >=2 -> Frio
        */
        // const _artRefrigerated = scanCodes.filter(a=>a.refrigerated==1) 
        // if(_artRefrigerated.length>0 && storageSpace && storageSpace.cool_type_id==1){
        //   setRefrigeratedAlert(true)
        //   toast.warn("El artículo requiere almacenamiento en frío", {theme: "colored"})
        // }
        
        // const _errTrazable = scanCodes.filter(a=>a.traced==0 && a.serial_number && a.serial_number.trim() != '') 
        // if(_errTrazable.length){
        //   setErrorTrazables(_errTrazable)
        // }
        
    }, [scanCodes])
    
    return (
      <>
            <Modal size="xl" show={show} onHide={onHide} backdrop='static' >

              <Modal.Body className="">
                <h3>Picking</h3> 
                <table className='mt-5'>
                    <tbody>
                      <tr>
                        <td style={{width:"200px"}}>Espacio de almacenamiento:</td>
                        <td>{storageSpace?.name}</td>
                      </tr> 
                      <tr>
                        <td style={{width:"150px"}}>Cantidad pickeada:</td>
                        <td>{counters?.picking}</td>
                      </tr> 
                      <tr>
                        <td style={{width:"150px"}}>Cantidad ok:</td>
                        <td>{counters?.ok}</td>
                      </tr> 
                      <tr>
                        <td style={{width:"150px"}}>Cantidad error:</td>
                        <td>{counters?.error}</td>
                      </tr> 
                    </tbody>
                </table>

                {/* { refrigeratedAlert==true && <div className="text-danger"> El espacio de almacenamiento no es de frío </div>} */}
                
                <ZebraScanner 
                  // handleNewTag={handleNewTag} 
                  // in_order_id={in_order_id} 
                  // storageSpaceId={storageSpaceId} 
                  // requestArticles={requestArticles} 
                  // updateTagView={updateTagView} 
                  processEnter = {processEnter}  
                  disabled={loading}
                />

                { errorTrazables && errorTrazables.length>0 &&
                  <>
                    <p className="text-danger m-0 p-0"> Artículos con nro. de serie que NO están marcados como trazables</p>
                    <ul>
                      {errorTrazables.map((a, index) => (
                        <li key={index} className="text-danger">{a.product_var_name}</li>
                      ))}
                    </ul>
                  </>
                }
                <div>
                { true && 
                    <FormGroup>
                      <FormLabel className="mt-10">Escaneados</FormLabel>
                      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        <Table striped hover>
                          <thead>
                            <tr>
                              <td>Producto</td>
                              <td>Código</td>
                              <td>Serie</td>
                              <td>Lote</td>
                              <td>Vencimiento</td>
                              <td></td>
                              <td style={{maxWidth:"150px"}}></td>
                            </tr>
                          </thead>
                          <tbody>{scanCodes?.map((v, idx)=>(
                            <tr key={idx}>
                              <td>{v.product_var_name || v.name}</td>
                              <td>{v.gtin}</td>
                              <td>{v.serial_number || 'No trazable'}</td>
                              <td>{v.lot_number || 'No trazable'}</td>
                              <td>{v.expiration_date || 'No trazable'}</td>
                              {(v.refrigerated && storageSpace?.cool_type_id==1 ?
                                  <td><i className="bi bi-snow text-danger fs-1 fw-bold"></i></td>
                                  :
                                  <td></td>)
                              }
                              {/* TODO colocar tooltip para que se sepa porque está rojo o verde*/}

                              { v.loading? 
                                  <td style={{maxWidth:"150px"}}><div className='spinner-border text-success'></div></td>
                                :
                                  v.result=='OK'?
                                    (v.serial_number ? <td> <i className="bi bi-check-circle text-success fs-1 fw-bold"></i></td> : <td></td>)
                                  :
                                    (v.result=='DUPLICATED'?
                                      <td className="text-danger" style={{maxWidth:"150px"}}>Ya ingresado</td>
                                      :
                                      // <td><i className="bi bi-slash-circle text-danger fs-1 fw-bold"></i></td>
                                      <td className="text-danger" style={{maxWidth:"150px"}}>{v.message}</td>
                                    )
                              }

                            </tr>
                          ))}
                          </tbody>
                        </Table>
                      </div>
                    </FormGroup>
                }
                </div>

              </Modal.Body>

              <Modal.Footer style={{borderTop: "0 none"}}>
                <Button variant="secondary" onClick={onHide}>Salir</Button>
              </Modal.Footer>
          </Modal>
    </>
    )
  }
  