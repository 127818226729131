import service from "../../service";

const PATH = {
  customerSuppliers: "/customer-suppliers",
  listCosts: "/customer-suppliers/list-costs",
  clientesParaRemitir: '/customer-suppliers/clientes-para-remitir',
}

export class CustomerSuppliers {
  static getCustomerSuppliers = async (token, query, params) => {
    const response = await service.get(`${PATH.customerSuppliers}`, {
      params,
    });

    return response;
  };

  static getCustomerSupplier = async (id, token) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}`);

    return response;
  };

  static createCustomerSupplier = async (data, token) => {
    const response = await service.post(PATH.customerSuppliers, data);

    return response;
  };

  static updateCustomerSupplier = async (id, data, token) => {
    const response = await service.patch(`${PATH.customerSuppliers}/${id}`, data);

    return response;
  };

  static getCustomerSupplierShipmentAddresses = async (id, params, token) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/shipment-addresses`, {
      params,
    })

    return response
  }

  static getCustomerSupplierDetailShipmentAddresses = async (id, params) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/detail/shipment-addresses`, {
      params,
    })
    return response
  }

  static linkCustomerSupplierDetailShipmentAddressToVerifarma = async (id, shipment_address_id) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/detail/shipment-addresses/${shipment_address_id}/link-verifarma`)
    return response
  }

  static unlinkCustomerSupplierDetailShipmentAddressFromVerifarma = async (id, shipment_address_id) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/detail/shipment-addresses/${shipment_address_id}/unlink-verifarma`)
    return response
  }

  static addCustomerSupplierShipmentAddress = async (id, data, token) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/shipment-address`, data);
    return response;
  }

  static linkShipmentAddressToCustomerSupplier = async (id, shipment_address_id) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/shipment-address/${shipment_address_id}`)
    return response
  }

  static updateCustomerSupplierShipmentAddress = async (id, shipment_address_id, data) => {
    const response = await service.patch(`${PATH.customerSuppliers}/${id}/shipment-address/${shipment_address_id}`, data)
    return response
  }

  static deleteCustomerSupplierShipmentAddress = async (id, shipment_address_id) => {
    const response = await service.delete(`${PATH.customerSuppliers}/${id}/shipment-address/${shipment_address_id}`);

    return response;
  }

  static getCustomerSupplierPatients = async (id, params) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/patients`, {
      params,
    })

    return response
  }

  static addCustomerSupplierPatient = async (id, patient_id, token) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/patient/${patient_id}`);
    return response;
  }

  static deleteCustomerSupplierPatient = async (id, patient_id, token) => {
    const response = await service.delete(`${PATH.customerSuppliers}/${id}/patient/${patient_id}`);
    return response;
  }

  static getListCosts = async (params) => {
    const response = await service.get(PATH.listCosts, {
      params,
    });

    return response;
  }

  static getListCost = async (id) => {
    const response = await service.get(`${PATH.listCosts}/${id}`);
    return response;
  }

  static addListCost = async (data) => {
    const response = await service.post(PATH.listCosts, data);
    return response;
  }

  static updateListCost = async (id, data) => {
    const response = await service.patch(`${PATH.listCosts}/${id}`, data);
    return response;
  }

  static getCustomerSupplierFiles = async (id, params) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/files`, {
      params,
    })
    return response
  }

  static getCustomerSupplierFile = async (id, file_id) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/files/${file_id}`, { responseType: 'blob' })
    return response
  }

  static addCustomerSupplierFile = async (id, data) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/file`, data)
    return response
  }

  static getCustomerSupplierTaxesData = async (id) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/taxes`)
    return response
  }

  static updateTaxesData = async (id, data) => {
    const response = await service.patch(`${PATH.customerSuppliers}/${id}/taxes`, data)
    return response
  }

  static getCustomerSupplierMultilateralAgreement = async (id, params) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/multilateral-agreement`, {
      params,
    })

    return response
  }

  static addMultilateralAgreement = async (id, data) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/multilateral-agreement`, data)
    return response
  }

  static updateMultilateralAgreement = async (id, multilateral_agreement_id, data) => {
    const response = await service.patch(`${PATH.customerSuppliers}/${id}/multilateral-agreement/${multilateral_agreement_id}`, data)
    return response
  }

  static getIIBB = async (id) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/iibb`)
    return response
  }

  static addIIBBException = async (id, data) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/iibb-exceptions`, data)
    return response
  }

  static updateIIBBException = async (id, iibb_exception_id, data) => {
    const response = await service.patch(`${PATH.customerSuppliers}/${id}/iibb-exceptions/${iibb_exception_id}`, data)
    return response
  }

  static getDistributor = async (id) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/distribuidor`)
    return response
  }

  static getSuppliers = async (id, params) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/proveedores`, {
      params
    })
    return response
  }

  static addSupplier = async (id, data) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/proveedores`, data)
    return response
  }

  static deleteSupplier = async (id, supplier_id) => {
    const response = await service.delete(`${PATH.customerSuppliers}/${id}/proveedores/${supplier_id}`)
    return response
  }

  static getDiscounts = async (id, params) => {
    const response = await service.get(`${PATH.customerSuppliers}/${id}/discounts`, {
      params,
    })
    return response
  }

  static addDiscount = async (id, data) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/discounts`, data)
    return response
  }

  static updateDiscount = async (id, data) => {
    const response = await service.patch(`${PATH.customerSuppliers}/${id}/discounts/${data.id}`, data)
    return response
  }

  static enableCustomerSupplier = async (id) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/enabled`, {})
    return response
  }
  static disableCustomerSupplier = async (id) => {
    const response = await service.post(`${PATH.customerSuppliers}/${id}/disabled`, {})
    return response
  }

  static getClientesParaRemitir = async (params) => {
    const response = await service.get(PATH.clientesParaRemitir, {
      params
    })
    return response
  }

  static addClienteParaRemitir = async (data) => {
    const response = await service.post(PATH.clientesParaRemitir, data)
    return response
  }

  static updateClienteParaRemitir = async (id, data) => {
    const response = await service.patch(`${PATH.clientesParaRemitir}/${id}`, data)
    return response
  }

  static deleteClienteParaRemitir = async (id) => {
    const response = await service.delete(`${PATH.clientesParaRemitir}/${id}`)
    return response
  }
}