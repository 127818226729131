import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useWaybill, useClickOutside } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import useGetColumns from './columns'
import Header from './Header'
import Actions from './Actions'
import Filter from './Filter'

import { initialValues, dataAdapter } from './constants'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const ShipmentOrderDetail = () => {
  const { id } = useParams()

  const { loading, handleGetShipmentOrder, handleGetShipmentOrderDetail } = useWaybill()
  const [cargando, setCargando] = useState(false)
  const [shipmentOrder, setShipmentOrder] = useState(initialValues)
  const [detail, setDetail] = useState([])
  const [detailCount, setDetailCount] = useState(0)
  const [detailQuery, setDetailQuery] = useState(initialQuery)

  const { COLUMNS } = useGetColumns()

  const getShipmentOrder = useCallback(async () => {
    try {
      setCargando(true)
      const response = await handleGetShipmentOrder(id)

      const data = dataAdapter(response.data.result)
      setShipmentOrder(data)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setCargando(false)
    }
  }, [id, handleGetShipmentOrder])

  const getDetail = useCallback(async () => {
    try {
      setCargando(true)

      let params = {}

      if (detailQuery && detailQuery.pageNumber) {
        params = {
          ...detailQuery
        }
      }

      const response = await handleGetShipmentOrderDetail(id, params)
      const data = response.data.result
      const count = response.data.metadata.count

      setDetail(data)
      setDetailCount(count)
    } catch (error) {
      setDetail([])
      setDetailCount(0)
      toast.error(error.message)
    } finally {
      setCargando(false)
    }
  }, [id, handleGetShipmentOrderDetail, detailQuery])

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    // Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    return actions
  }
  /* Fin de menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => availableActions().length > 0 && <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: detailCount,
    obQuery: detailQuery,
    setObQuery: setDetailQuery,
  }

  useEffect(() => {
    getShipmentOrder()
  }, [getShipmentOrder])

  useEffect(() => {
    getDetail()
  }, [getDetail])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <DetailContainer
      title={`Orden de envío #${id}`}
      statusV2={<Header shipmentOrder={shipmentOrder} />}
      buttons={<Actions loading={loading || cargando} shipmentOrder={shipmentOrder} />}
    >
      <TableContainer>
        <Filter loading={loading || cargando} query={detailQuery} setQuery={setDetailQuery} />

        {(loading || cargando) && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={detail || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} queryMD={detailQuery} setQueryMD={setDetail} />
      </TableContainer>

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </DetailContainer>
  )
}

export default ShipmentOrderDetail