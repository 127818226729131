import { useState, useEffect } from "react"
import { Button, Form, FormControl, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import moment from 'moment'
import { helix } from 'ldrs'

import { useArticles } from "hooks";

import { decimalFormatter } from "utilities";
import { calculosVenta } from "utilities/calculosVenta"

import './article.css';

const  ArticlesModal = ({ show, onClose, onSave, salesOrder }) => {
  const { loading, handleSearchArticle, handleGetArticles } = useArticles()
  const [articlesOptions, setArticlesOptions] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [txtSearch, setTxtSearch] = useState('')

  const handleKeyDown = async (event) => {
    console.log('keyCode:',  event.keyCode)
    console.log('value: ', event.target?.value)
    console.log('txtSearch: ', txtSearch)
    console.log('condición 1: ', event.keyCode === 13 && event.target?.value?.length > 0)
    console.log('condición 2: ', event.target?.name === 'btnSearch' && txtSearch.length > 0)

    if ((event.keyCode === 13 && event.target?.value?.length > 0) || (event.target?.name === 'btnSearch' && txtSearch.length > 0)) {
      const queryMD = {
        text: txtSearch,
        pageNumber: 1,
        pageSize: 1000,
        // add_stock: 1,
        // add_price: 1,
        // add_cost: 1,
        customer_id: salesOrder?.customer_supplier_id || undefined, // Se usa para traer los descuentos del cliente, ya que customer_supplier_id se usa para obtener el stock
        // business_unit_id:salesOrder?.business_unit_id || undefined,
        // add_in_order_pending_qty: 1,
        // add_missing_stock: 1
      }

      const data = await handleSearchArticle({ params: queryMD })

      if (data && data.result) {
        const val = data.result.map(r => ({ ...r, selected: false, qty: r.unit ? parseInt(r.unit) : 1, boxes: 1 }))
        setArticlesOptions(val)
      }
    }
  }

  const handleSave = async () => {
    const queryMD = {
      ids: articlesOptions.filter(a => a.selected === true).map(i => i.id).join(','),
      pageNumber: 1,
      pageSize: 1000,
      add_stock: 1,
      add_price: 1,
      add_cost: 1,
      customer_id: salesOrder?.customer_supplier_id || undefined, // Se usa para traer los descuentos del cliente, ya que customer_supplier_id se usa para obtener el stock
      business_unit_id: salesOrder?.business_unit_id || undefined,
      // add_in_order_pending_qty: 1,
      // add_missing_stock: 1
    }
    const data = await handleGetArticles({ queryMD })

    if (data && data.result && data.result.length) {
      const ob = data.result

      const arts = ob.map(a => {
        const seleccionados = articlesOptions.filter(a => a.selected === true)
        const actual = seleccionados.filter(s=> s.id == a.id)[0]
        const calcValues = calculosVenta(a.actual_pvp, actual.boxes, a.vat_rate, a.list_cost, actual.discount)

        const data = {
          product_var_id:a.id,
          name:a.name,
          line:null,
          pvp: a.actual_pvp,
          pvp_original: a.actual_pvp,
          list_cost: a.list_cost,
          qty: Number(actual.boxes),
          units: actual.qty,
          discount: actual.discount?parseFloat(actual.discount): 0,
          vat: a.vat,
          vat_rate: a.vat_rate,
          vat_amount: calcValues.vat_amount,
          unit_vat_amount:calcValues.unit_vat_amount,
          price:calcValues.unit_price,
          profitability:calcValues.profitability,
          profitability_rate:calcValues.profitability_rate,
          subtotal: calcValues.subtotal ? parseFloat(calcValues.subtotal) : null,
          total: calcValues.total ? parseFloat(calcValues.total) : null,
        }

        return data
      })

      const invalid = arts.filter(a => a.qty === '' || !a.qty)
      if (invalid.length > 0) {
        return
      }

      const total = arts.reduce((acc, article) => acc + (article.total || 0), 0)

      onSave({ articles: arts, total: parseFloat(total) })
      setTxtSearch('')
      onClose()
    }
  }

  const handleQuantityChange = (productId, event) => {
    let value = event.target.value.replace(/\D/g, '')
    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, boxes: value, selected: value ? true : false } : product)
    setArticlesOptions(updatedProducts)
  }

  const handleDiscountChange = (productId, e) => {
    let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value

    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, discount: value} : product)

    setArticlesOptions(updatedProducts)
  }

  const handleSelectionChange = (productId) => {
    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, selected: !product.selected } : product)
    setArticlesOptions(updatedProducts)
  }

  useEffect(() => {
    if (show) {
      setArticlesOptions([])
      setSelectedRows([])
      setTxtSearch('')
      helix.register()
    }
  }, [show])

  if (!show) return null

  return (
    <Modal size="xl" show={show} onHide={onClose} backdrop='static' scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Agregar un artículo al pedido</Modal.Title>
      </Modal.Header>

      <Modal.Body className="">
        <div className='row'>
          <div className='col-10'>
            <FormLabel>ID / Articulo / Droga</FormLabel>
            <FormControl
              type="text"
              onKeyDown={handleKeyDown}
              autoComplete="off"
              placeholder="ID o Nombre del artículo/droga"
              value={txtSearch}
              onChange={(e) => setTxtSearch(e.target.value)}
              autoFocus
            />
          </div>

          <div className='col mt-6'>
            <Button onClick={handleKeyDown} name="btnSearch">
              <i className="bi bi-search me-2" />
              Buscar
            </Button>
          </div>
        </div>

        <div className="row mt-10">
          <div className='col mt-5'>
            {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", top: "180px" }}></l-helix>}

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th className="text-center">Stock</th>
                  <th className="text-center">Precio</th>
                  <th className="text-center">Últ. Act. PP</th>
                  <th className="text-center" style={{ width: '100px' }}>Cajas</th>
                  <th className="text-center" style={{ width: '100px' }}>Unidades</th>
                  <th className="text-center">Descuento</th>
                  <th className="text-center" style={{ width: '100px' }}>Seleccionar</th>
                </tr>
              </thead>

              <tbody>
                {articlesOptions.map(product => (
                  <tr key={product.id}>
                    <td className="align-middle">{product.id}</td>
                    <td className="align-middle">
                      {product.name}
                      {product?.observations
                        ?
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="waves-print-tooltip">
                                <div className=''>
                                  <p>{product.observations}</p>
                                </div>
                              </Tooltip>
                            }
                          >
                            <span className="ms-5">
                              <i className="fa-regular fa-circle-question text-primary" />
                            </span>
                          </OverlayTrigger>
                        : null
                      }
                    </td>

                    <td className="align-middle text-center">
                      {product.Disponible}

                      <OverlayTrigger
                        overlay={
                          <Tooltip width="300">
                            <div className=''>
                              <table className='table table-rounded table-striped'>
                                <thead>
                                  <tr className=' text-center'>
                                    <th>Pedido Compra</th>
                                    <th>Faltante</th>
                                    <th>Reservado</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className='align-middle text-center'>
                                    <td>{product.PendienteIngreso || 0}</td>
                                    <td>{product.FaltaStock || 0}</td>
                                    <td>{product.Reservado || 0}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Tooltip>
                        }
                      >
                        <span className="ms-5">
                          <i className="fa-regular fa-circle-question text-primary" />
                        </span>
                      </OverlayTrigger>
                    </td>

                    <td className='align-middle text-center'>$ {decimalFormatter.format(product.pvp)}</td>

                    <td className='align-middle text-center'>{product?.FechaActuPrecio ? moment(product.FechaActuPrecio).format("DD/MM/YYYY") : ""}</td>

                    <td className="align-middle">
                      <Form.Control
                        type="number"
                        maxLength={5}
                        value={product.boxes || ''}
                        onChange={(e) => handleQuantityChange(product.id, e)}
                        className={(product.selected && !product.boxes) ? 'border-danger' : ''}
                        style={{ width: '100px' }}
                      />
                    </td>

                    <td className="align-middle">
                      <Form.Control
                        type="number"
                        maxLength={5}
                        value={(product.boxes && product.qty)? product.boxes * product.qty : ''}
                        className={(product.selected && !product.qty) ? 'border-danger' : ''}
                        style={{ width: '100px' }}
                        disabled
                      />
                    </td>

                    <td className="align-middle">
                      <FormControl
                        type="text"
                        autoComplete="off"
                        maxLength={8}
                        value={product.discount || ''}
                        onChange={(e) => handleDiscountChange(product.id, e)}
                        className={''}
                        style={{ width: '80px' }}
                      />
                    </td>

                    <td className="align-middle text-center">
                      <Form.Check
                        type="checkbox"
                        checked={product.selected}
                        onChange={() => handleSelectionChange(product.id)}
                        className="chk-selector"
                        style={{ width: '100px' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "0 none" }}>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Salir
        </Button>

        <Button variant="primary" onClick={handleSave} disabled={loading}>
          <i className="bi bi-database-add me-2" />
          Agregar artículo
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ArticlesModal