import { FC, useEffect, useMemo, useState } from 'react'
import { useFormikContext } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { useGetColumns } from './constants';
import { KTCard, KTCardBody } from '_metronic/helpers';
import { ArticleScanModal } from './ArticleScanModal';
import { ArticleAddModal } from './ArticleAddModal';
import { ArticleEditModal } from './ArticleEditModal';

import { Alert, Button, Dropdown, FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import moment from "moment";
import ArticleTable from './ArticleTable';
import useDepots from 'hooks/Inventory/useDepots';
import { useIncomeOrder } from 'hooks';

import Select from "react-select"
import { GS1ParcialModal } from './GS1ParcialModal';


const initialObQuery = {
  pageNumber:"1", 
  pageSize:"10"
}

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '38px',
    width:'200px'
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1.1rem',
    fontWeight: '500'
  }),
  menu: (base) => ({
    ...base,
    width: '200px'
  }),
  multiValue: (base) => ({
      ...base,
      fontSize: '1.1rem',
  }),
};

const ArticlesTab = ({ editEnable}) => {
  const {id} = useParams();
  const { values, setFieldValue } = useFormikContext();
  const { COLUMNS } = useGetColumns()
  const [showScanModal, setShowScanModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showGS1ParcialModal, setShowGS1ParcialModal] = useState(false)
  const [defaultStorageSpace, setDefaultStorageSpace] = useState(null)
  const [storageAlert, setStorageAlert] = useState(false)
  const [requestArticles, setRequestArticles] = useState([]);
  const [existsTraced, setExistsTraced] = useState(false);
  const [detailForEdit, setDetailForEdit] = useState({});
  const [qtyPending, setQtyPending] = useState(0);
  const [storageOptions, setStorageOptions] = useState([]);
  const {handleGetStorageSpaces} = useDepots();
  const {handleConfirmIncomeOrder, loading} = useIncomeOrder();

  

  const updateDetail = async(data)=>{
      try{
        const resp = await values.updateDetail(data)
        toast.success("Registro actualizado correctamente", {theme: "colored"})
      }
      catch(err){
        toast.error(err.message, {theme: "colored"})
      }
  }
  const updateDetailStorageSpace = async(data)=>{
    try{
      const resp = await values.updateDetailStorageSpace(data)
      toast.success("Registro actualizado correctamente", {theme: "colored"})
    }
    catch(err){
      toast.error(err.message, {theme: "colored"})
    }
}
  

  const duplicateDetail = async(row)=>{
    try{
      const { in_order_id, product_var_id, lot_number} = row;
      const resp = await values.duplicateDetail({in_order_id, product_var_id, lot_number});      
      values.refreshIncomeOrder();
      toast.success("Registro duplicado correctamente", {theme: "colored"})
    }
    catch(err){
      console.log(err)
      toast.error(err.message, {theme: "colored"})
    }
  }
  const deleteDetail = async(row)=>{
    try{
      const {id, in_order_id} = row;

      const resp = await Swal.fire({
        title:"Advertencia",
        text:"Confirma la eliminación del registro?",
        icon:"warning",
        showDenyButton:true,
        denyButtonText:"No, cancelar",
        confirmButtonText:"Si, eliminar registro",
        customClass: {confirmButton:'btn btn-primary', denyButton:"btn btn-secondary"},
        reverseButtons: true
        
      })

      if(resp.isConfirmed){
          // pastel
          const { in_order_id, product_var_id, lot_number, storage_space_id} = row;
          const resp = await values.deleteDetail({in_order_id, product_var_id, lot_number, storage_space_id})  
          values.refreshIncomeOrder();
          toast.success("Registro eliminado correctamente", {theme: "colored"})
      }
    }
    catch(err){
      toast.error(err.message, {theme: "colored"})
    }
  }

  const deleteSerialDetail = async(data)=>{
    try{

      const resp = await Swal.fire({
        title:"Advertencia",
        text:"Confirma la eliminación del registro?",
        icon:"warning",
        showDenyButton:true,
        denyButtonText:"No, cancelar",
        confirmButtonText:"Si, eliminar registro",
        customClass: {confirmButton:'btn btn-primary', denyButton:"btn btn-secondary"},
        reverseButtons: true
        
      })

      if(resp.isConfirmed){

          const resp = await values.deleteSerialDetail(data)  
          values.refreshIncomeOrder();
          toast.success("Registro eliminado correctamente", {theme: "colored"})
      }
    }
    catch(err){
      toast.error(err.message, {theme: "colored"})
    }
  }

  const addDetail = async(data)=>{
    try{
      const resp = await values.addDetail(data);      
      values.refreshIncomeOrder();
      toast.success("Registro agregado correctamente", {theme: "colored"})
    }
    catch(err){
      console.log(err)
      toast.error(err.message, {theme: "colored"})
    }
  }


  const onCloseScanModal = (data)=>{
    if(data && data.id){
      //setRequest(data)
    }
    values.refreshIncomeOrder()
    setShowScanModal(false)
  }

  const onCloseAddModal = (data)=>{
    if(data && data.product_var_id){
      //setRequest(data)
      const transport = {...data, in_order_id:id}
      addDetail(transport)

    }
    // const newDetail = [...values.detail];
    // setFieldValue('detail', [...newDetail, defaultDetail])
    setShowAddModal(false)
  }

  const onCloseGS1ParcialModal = (data)=>{
    
    // if(data && data.product_var_id){
    //   //setRequest(data)
    //   const transport = {...data, in_order_id:id}

    //   addDetail(transport)

    // }
    // const newDetail = [...values.detail];
    // setFieldValue('detail', [...newDetail, defaultDetail])
    values.refreshIncomeOrder();
    setShowGS1ParcialModal(false)
    
  }

  const editRow=(row)=>{
    const detail = {
      id:row.detail_id?row.detail_id[0]:null,
      qty:row.qty,
      storage_space_id:row.storage_space_id,
      lot_number:row.lot_number,
      expiration_date:row.expiration_date?moment(row.expiration_date).format("YYYY-MM-DD"):"",
      product_var_traced:row.product_var_traced,
      product_var_name:row.product_var_name,
      product_var_id:row.product_var_id,
      series:row.series,
      refrigerated:row.refrigerated
    }

    setDetailForEdit(detail);
    setShowEditModal(true)
  }

  const onCloseEditModal = (data)=>{
    if(!data){      
      setShowEditModal(false);
      return;
    }
    if(data.product_var_traced!=1){
      if(data && data.id){
        const {product_var_traced, product_var_name, product_var_id, series, ...rest} = data;
        updateDetail(rest)
      }
    }
    else{
      if(data && data.lot_number && data.expiration_date){
        const {product_var_traced, product_var_name, id, series, expiration_date, qty, ...rest} = data;
        updateDetailStorageSpace(rest);
      }
    }
    setShowEditModal(false)
  }

  
  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      width:"180px",
      accessor: (row) => (
        <div style={{width:"120px"}}>
          {editEnable && 
            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Editar</Tooltip>} >
              <span className="">
                <i className="bi bi-pencil-square text-primary fs-3" style={{ cursor: 'pointer' }} onClick={()=>editRow(row)}/>
              </span>
            </OverlayTrigger>}
          {false && 
            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Duplicar</Tooltip>}>
              <span className="ms-3">
                <i className="bi bi-layers text-primary fs-3" style={{ cursor: 'pointer' }} onClick={()=>duplicateDetail(row)}/>
              </span>
            </OverlayTrigger>}
          {editEnable && 
            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Eliminar</Tooltip>}>
              <span className="ms-3">
                <i className="bi bi-trash text-danger fs-3" style={{ cursor: 'pointer' }} onClick={()=>deleteDetail(row)}/>
              </span>
            </OverlayTrigger>}
        </div>
      )
    }
  ], [COLUMNS])

  const loadStorages = async()=>{
    let customer_supplier_id=1;

    if(values && values.order_type_id && values.order_type_id==2){//pedido de ingreso
      customer_supplier_id=values.supplier_id;
    }
    const _storage = await handleGetStorageSpaces(customer_supplier_id);
    if(_storage.length<1 && values.order_type_id==2){
      setStorageAlert(true)
    }
    else{
      setStorageAlert(false)
    }
    
    setStorageOptions(_storage.map(s=>({...s, value:s.id, label:s.name})));
  }

  const handlePick = ()=>{
    // if(!values?.default_storage_space_id)
    if(!defaultStorageSpace)
    {
      toast.error("Primero seleccionar espacio de almacenamiento", {theme: "colored"})
      return
    }
    setShowScanModal(true)
  }

  useEffect(()=>{
    // console.log(values)
      if(values && values.in_order_id){
          setStorageAlert(false)
          const _art = values.request_articles?.map(a=>({
              value:a.product_var_id, 
              label:a.product_var_desc, 
              traced:a.traced,
              refrigerated:a.refrigerated,
              gtin:a.gtin
            }))
          setRequestArticles(_art || [])

          const _traced = values.request_articles?.filter(a=>a.traced==1);

          if(_traced.length>0){
            setExistsTraced(true)
          }
          else{
            setExistsTraced(false)
          }

          const _pending = values.detail_grouped?.filter(a=>a.qty_pending>0);

          if(_pending.length>0){
            setQtyPending(true)
          }
          else{
            setQtyPending(false)
          }

      }
      console.log(values)
  }, [values])

  //--> /in-order/:id/confirm

  useEffect(()=>{
    loadStorages();
  }, [])



  return (
    <>



      <ArticleScanModal show={showScanModal} onHide={onCloseScanModal} in_order_id={id} storageSpaceId={defaultStorageSpace?.value} 
        storageSpace={ storageOptions.filter(s=>s.value==defaultStorageSpace?.value)[0]} requestArticles={requestArticles} />

      <GS1ParcialModal  show={showGS1ParcialModal} onHide={onCloseGS1ParcialModal} requestArticles={requestArticles} 
        default_storage_space_id={defaultStorageSpace?.value} storageOptions={storageOptions} in_order_id={id} />

      <ArticleAddModal  show={showAddModal} onHide={onCloseAddModal} requestArticles={requestArticles} 
        default_storage_space_id={defaultStorageSpace?.value} storageOptions={storageOptions} articlesGrouped={values.detail_grouped} out_order_id={values.out_order_id} />


      <ArticleEditModal  show={showEditModal} onHide={onCloseEditModal} detailForEdit={detailForEdit} articlesGrouped={values.detail_grouped}
        deleteSerialDetail={deleteSerialDetail} storageOptions={storageOptions} default_storage_space_id={defaultStorageSpace?.value}/>

      <div className="row">
        <div className='col text-end mb-4'>
          <div className="d-flex justify-content-end">

          { values.order_status_id== 7 &&
            <div className="col text-end mt-5">
              { values.order_type_id!=5 ?
                <>
                  <Button variant='primary' onClick={()=>handlePick()} className='me-4' disabled={loading}><i className='bi bi-qr-code-scan me-2 mb-1'></i>Pick!</Button>
                  <Button variant='primary' onClick={()=>setShowGS1ParcialModal(true)} className='me-4' disabled={loading}><i className='bi bi-capslock me-2 mb-1'></i>GS1 Parcial</Button>
                </> :
                <>
                </>
              }
              { qtyPending ?
                <Button variant='primary' onClick={()=>setShowAddModal(true)} disabled={loading}><i className='bi bi-hand-index-thumb me-2 mb-1'></i>Ingreso Manual</Button>
                :
                <></>
              }
            </div>
          }

          </div>
        </div>  
      </div>

      <div className='row'>

        { storageAlert? 
          <div className='row'>
            <Alert variant='danger'>
              El proveedor no posee espacio de almacenamiento disponible
            </Alert>
          </div>
          :
          <></>
        }

        <div className='col justify-content-end mb-4'>
          {editEnable && 
          <>
            <label className="form-label">Espacio de almacenamiento</label>
            {/* <SelectField 
              id="default_storage_space_id"
              name="default_storage_space_id"
              options={storageOptions} 
              onChange={option=>{
                setDefaultStorageSpaceId(option?.value)
              }} 
              /> */}
            <Select 
                  options={storageOptions}
                  styles={customStyles}
                  value={defaultStorageSpace}
                  onChange={e=>{
                    setDefaultStorageSpace(storageOptions.find(o=>o.value==e.value))
                  }}
                />
          </>
          }
        </div>
        
      </div>


      <KTCard>
          <KTCardBody className='TableContainer'>
              <div className="">
                <ArticleTable columns={columns} data={values?.detail_grouped || []}/>
              </div>
          </KTCardBody>
      </KTCard>


    </>
  )
}

export default ArticlesTab