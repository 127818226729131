import { useState, useCallback, useMemo, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useStock } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import { COLUMNS } from './eventosColumns'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC'
}

const EventosModal = ({ serie, show, onClose }) => {
  const { loading, handleGetEventos } = useStock()

  const [eventos, setEventos] = useState([])
  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getEventos = useCallback(async () => {
    try {
      let params = {
        stock_id: serie ? serie.id : undefined,
      }

      if (query) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetEventos(params)
      const data = response.data.result
      const count = response.data.metadata.count

      setEventos(data)
      setTotalCount(count)
    } catch (error) {
      setEventos([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [serie, handleGetEventos, query])

  const handleClose = () => {
    setEventos([])
    setQuery(initialQuery)
    setTotalCount(0)
    onClose()
  }

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    if (show && serie) {
      getEventos()
    }
  }, [show, serie, getEventos])

  if (!show && !serie) return null

  return (
    <Modal show={show} onHide={handleClose} backdrop='static' size='xl' centered scrollable>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Eventos del stock #{serie?.id} | [{serie?.product_var_id}] {serie?.product_var_name} (Serie: {serie?.serial_number}) </Modal.Title>
      </Modal.Header>

      <Modal.Body className='pt-0'>
        <TableContainer>
          <CustomTable columns={COLUMNS} data={eventos} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} />
        </TableContainer>
      </Modal.Body>

      <Modal.Footer>
        <div className='col text-end'>
          <Button type='button' variant='secondary' className='me-3' onClick={handleClose} disabled={loading}>
            <i className='bi bi-slash-circle' />
            Cerrar
          </Button>

          {/* <Button type='button' variant='primary' disabled={loading}>
            <i className='bi bi-search me-1' />
            {loading ? 'Buscando...' : 'Buscar'}
          </Button> */}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default EventosModal