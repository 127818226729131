import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useStock, useDepots } from 'hooks'
import { SelectField, TextareaBS } from 'components'
import routes from 'app/routing/routes'

const initialValues = {
  owner: '',
  origin: '',
  destiny: '',
  observations: '',
}

const CreateDepotMovementModal = ({ show, onClose }) => {
  const navigate = useNavigate()

  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { loading, handleCreateDepotMovement } = useStock()

  const { handleGetStorageBusinessUnits } = useDepots()
  const [entities, setEntities] = useState([])
  const [ownerOptions, setOwnerOptions] = useState([])
  const [storageOptions, setStorageOptions] = useState([])

  const getStorages = useCallback(async () => {
    const storage = await handleGetStorageBusinessUnits()
    setEntities(storage)

    // Filtrar y obtener dueños únicos según customer_suppliers_id y business_unit_id
    const uniqueOwners = Array.from(new Map(storage.map(item => [item.customer_suppliers_id, item])).values())

    // Obtener todos los Dueños y sus respectivos almacenes
    const owners = uniqueOwners.map(item => ({
      value: item.customer_suppliers_id,
      label: item.customer_supplier_name,
      business_unit_id: item.business_unit_id
    }))

    setOwnerOptions(owners)

    // Obtener todos los almacenes sin filtrar por la Unidad de Negocio
    const allStorageOptions = storage.map(s => ({
      ...s,
      value: s.id,
      label: s.name
    }))

    setStorageOptions(allStorageOptions)
  }, [handleGetStorageBusinessUnits])

  const handleChangeOwner = (e) => {
    setStorageOptions(
      entities
        .filter(item => item.customer_suppliers_id === e.value)
        .map(s => ({
          ...s,
          value: s.id,
          label: s.name
        }))
    )
  }

  const onSave = async (values) => {
    const { owner, origin, destiny, observations } = values

    if (!owner) {
      toast.error('Seleccionar dueño')
      return
    }
    if (!origin) {
      toast.error('Seleccionar almacen de origen')
      return
    }
    if (!destiny) {
      toast.error('Seleccionar almacen de destino')
      return
    }
    if (!observations) {
      toast.error('Completar el motivo del movimiento')
      return
    }

    try {
      let data = {
        storage_space_id_origin: origin,
        storage_space_id_dest: destiny,
        observations:observations
      }
      const response = await handleCreateDepotMovement(data)
      const depot_movement_id = response.data.id

      toast.success(response.data?.message)
      onClose()
      navigate(`${routes.INVENTORY_MOVEMENTS}/${depot_movement_id}`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (show) {
      getStorages()
    }
  }, [show, getStorages])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Nuevo movimiento interno de stock</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialFormikValues}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className="row">
                  <div className='col'>
                    <SelectField
                      id='owner'
                      name='owner'
                      options={ownerOptions}
                      label='Dueño'
                      placeholder='Seleccionar dueño'
                      onChange={handleChangeOwner}
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className='col'>
                    <SelectField
                      id='origin'
                      name='origin'
                      options={storageOptions}
                      label='Almacen Origen'
                      placeholder='Seleccionar almacen de origen'
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className='col'>
                    <SelectField
                      id='destiny'
                      name='destiny'
                      options={storageOptions}
                      label='Almacen Destino'
                      placeholder='Seleccionar almacen de destino'
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="row mt-5">
                  <div className='col'>
                    <TextareaBS
                      id='observations'
                      name='observations'
                      label='Motivo'
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='row'>
                  <div className='col-12 text-end'>
                    <Button
                      type='button'
                      variant='secondary'
                      onClick={onClose}
                      className='me-3'
                      disabled={loading}
                    >
                      <i className="bi bi-slash-circle" />
                      Cancelar
                    </Button>

                    <Button type='submit' variant='primary' disabled={loading}>
                      <i className='bi bi-save me-2' />
                      {loading ? 'Cargando...' : 'Guardar'}
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default CreateDepotMovementModal