import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useTypes, usePaymentConditions, useDocumentationTypes, useVatConditions, useBillingTypes, usePriceUpdate } from 'hooks'

import { AddressModal } from 'containers/Modals'
import { FormTable, Label, InputBS, SelectField, TextareaBS, Checkbox } from 'components'

import { RoleI } from 'interfaces'

const GeneralTab = ({ editing, handleSubmit }) => {
  const { currentUser } = useAuth()
  const intl = useIntl()
  const { values } = useFormikContext()

  const [modal, setModal] = useState(false)

  const { handleGetDocumentationTypes } = useDocumentationTypes()
  const { handleGetTypes } = useTypes()
  const { handleGetPaymentConditions } = usePaymentConditions()
  const { handleGetVatConditions } = useVatConditions()
  const { handleGetBillingTypes } = useBillingTypes()
  const { handleGetPriceUpdates } = usePriceUpdate()

  const [documentationTypes, setDocumentationTypes] = useState([])
  const [vatConditions, setVatConditons] = useState([])
  const [types, setTypes] = useState([])
  const [paymentConditions, setPaymentConditions] = useState([])
  const [billingTypes, setBillingTypes] = useState([])
  const [priceUpdates, setPriceUpdates] = useState([])

  const getDocumentationTypes = useCallback(async () => {
    try {
      const response = await handleGetDocumentationTypes('token')
      setDocumentationTypes((response.result).map(item => { return { value: item.id, label: item.type }}))
    } catch (error) {
      setDocumentationTypes([])
      toast.error(error.message)
    }
  }, [handleGetDocumentationTypes])

  const getVatConditions = useCallback(async () => {
    try {
      const response = await handleGetVatConditions()
      setVatConditons((response.data.result).map(item => { return { value: item.id, label: item.label }}))
    } catch (error) {
      setVatConditons([])
      toast.error(error.message)
    }
  }, [handleGetVatConditions])

  const getTypes = useCallback(async () => {
    try {
      const response = await handleGetTypes('token')
      setTypes((response.result).map(item => { return { value: item.id, label: item.type }}))
    } catch (error) {
      setTypes([])
      toast.error(error.message)
    }
  }, [handleGetTypes])

  const getPaymentConditions = useCallback(async () => {
    try {
      let params = {
        is_enabled: 1,
      }
      const response = await handleGetPaymentConditions(params)
      setPaymentConditions((response.result).map(item => { return { value: item.id, label: item.label }}))
    } catch (error) {
      setPaymentConditions([])
      toast.error(error.message)
    }
  }, [handleGetPaymentConditions])

  const getBillingTypes = useCallback(async () => {
    try {
      const response = await handleGetBillingTypes()
      setBillingTypes(response.data.result.map(item => { return { value: item.id, label: item.label }}))
    } catch (error) {
      setBillingTypes([])
      toast.error(error.message)
    }
  }, [handleGetBillingTypes])

  const getPriceUpdates = useCallback(async () => {
    try {
      const response = await handleGetPriceUpdates()
      setPriceUpdates(response.data.result.map(item => { return { value: item.id, label: item.label }}))
    } catch (error) {
      setPriceUpdates([])
      toast.error(error.message)
    }
  }, [handleGetPriceUpdates])

  useEffect(() => {
    getDocumentationTypes()
    getVatConditions()
    getTypes()
    getPaymentConditions()
    getBillingTypes()
    getPriceUpdates()
  }, [getDocumentationTypes, getVatConditions, getTypes, getPaymentConditions, getBillingTypes, getPriceUpdates])

  return (
    <>
      <AddressModal
        show={modal}
        onClose={() => setModal(false)}
        values={values}
        onSave={data => {
          console.log(data)
          let new_values = {
            ...values,
            address: {
              ...values.address,
              ...data.address,
              origin: data.address.manual ? 'MANUAL' : 'GOOGLE',
            },
          }
          setModal(false)
          handleSubmit(new_values)
        }}
      />

      <div className='row mb-8'>
        <div className='col-4'>
          <InputBS id='cod_abrev' name='cod_abrev' label={'Abrev.'} disabled={!editing} />
        </div>

        <div className='col'>
          <InputBS id='enrollment_date' name='enrollment_date' type='date' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.ENROLLMENT_DATE' })} disabled />
        </div>

        <div className='col'>
          <InputBS id='updated_at' name='updated_at' type='date' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.UPDATED_AT' })} disabled />
        </div>

        <div className='col'>
          <InputBS id='cancel_date' name='cancel_date' type='date' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.CANCEL_DATE' })} disabled />
        </div>
      </div>

      <div className='row mb-8'>
        <div className='col-4'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='is_customer' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.BUSINESS_RELATIONSHIP' })} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_customer' disabled={!editing} />
              <Label htmlFor='is_customer' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.BUSINESS_RELATIONSHIP.CUSTOMER' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_supplier' disabled={!editing} />
              <Label htmlFor='is_supplier' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.BUSINESS_RELATIONSHIP.SUPPLIER' })} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_distributor' disabled={!editing} />
              <Label htmlFor='is_distributor' label={'Distribuidor'} styles='mb-0 ms-4' />
            </div>

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='cliente_para_remitir' disabled={!editing} />
              <Label htmlFor='cliente_para_remitir' label={'Cliente para remitir'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        <div className='col-8'>
          <InputBS id='business_name' name='business_name' label={intl.formatMessage({ id: 'COLUMN.CUSTOMER_SUPPLIERS.BUSINESS_NAME' })} disabled={!editing} />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <SelectField
            id='documentation_type_id'
            name='documentation_type_id'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.DOCUMENT_TYPE' })}
            options={documentationTypes}
            placeholder='Seleccione un tipo'
            required
            disabled={!editing}
          />
        </div>

        <div className='col-3'>
          <InputBS id='documentation' name='documentation' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.DOCUMENT' })} disabled={!editing} maxLength='11' />
        </div>

        <div className='col-3'>
          <SelectField
            id='vat_condition_id'
            name='vat_condition_id'
            label='Condición frente al IVA'
            options={vatConditions}
            placeholder='Seleccione una condición'
            required
            disabled={!editing}
          />
        </div>

        <div className='col-3'>
          <SelectField
            id='type_id'
            name='type_id'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.TYPE' })}
            options={types}
            placeholder='Seleccione un tipo'
            disabled={!editing}
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='is_big_company' label={'Gran empresa'} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='is_big_company' disabled={!editing} />
              <Label htmlFor='is_big_company' label={'Gran empresa'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        {Boolean(values.is_big_company) &&
          <div className='col-6'>
            <SelectField
              id='company_system'
              name='company_system'
              label='Sistema'
              options={[
                { value: 'SCA', label: 'SCA: Sistemas de Circulación Abierta' },
                { value: 'ADC', label: 'ADC: Agentes de Depósito Colectivo o similares' },
              ]}
              placeholder='Seleccione un sistema'
              disabled={!editing}
            />
          </div>
        }

        <div className='col-3'>
          <SelectField
            id='payment_condition_id'
            name='payment_condition_id'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.PAYMENT_CONDITION' })}
            options={paymentConditions}
            placeholder='Seleccione una condición'
            disabled={!editing}
          />
        </div>

        {/* <div className={`col-3 ${Boolean(values.is_big_company) ? 'mt-8' : ''}`}>
          <SelectField
            id='billing_type_id'
            name='billing_type_id'
            label={'Facturación'}
            options={billingTypes}
            placeholder='Seleccione una facturación'
            disabled={!editing}
          />
        </div>

        <div className={`col-3 ${Boolean(values.is_big_company) ? 'mt-8' : ''}`}>
          <SelectField
            id='price_update_id'
            name='price_update_id'
            label={'Actualización de precio'}
            options={priceUpdates}
            placeholder='Seleccione una opción'
            disabled={!editing}
          />
        </div> */}
      </div>

      <div className='separator my-8' />

      <div className='mb-8 d-flex flex-row justify-content-between align-items-center'>
        <h4 className='m-0'>Dirección comercial</h4>
        {currentUser.roles.some(r => r.id != RoleI.COORDINACION) && <Button className='BSButton' variant='outline-primary' onClick={() => setModal(true)}>Editar dirección</Button>}
      </div>

      {values?.address?.id &&
        <FormTable
          columns={[
            { header: 'Domicilio', headerClassName:'text-muted' },
            { header: 'Localidad' },
            { header: 'CP' },
            { header: 'Provincia' },
            { header: 'Origen' },
            { header: '', styles: 'text-center' },
          ]}
        >
          <tr>
            <td className='align-middle'>{values.address.route} {values.address.street_number}</td>
            <td className='align-middle'>{values.address.locality}</td>
            <td className='align-middle'>{values.address.postal_code ? `${values.address.postal_code}${values.address.postal_code_suffix}` : '-'}</td>
            <td className='align-middle'>{values.address.administrative_area_level_1}</td>
            <td className='align-middle'>{values.address.origin}</td>
            <td className='align-middle text-center'>
              {currentUser.roles.some(r => r.id != RoleI.COORDINACION) &&
                <button type='button' className='btn btn-icon' onClick={() => setModal(true)}>
                  <i className='fas fa-pencil text-primary'></i>
                </button>
              }
            </td>
          </tr>
        </FormTable>
      }
      <div className='separator my-8' />
      <h4 className='m-0'>Datos de contacto</h4>

      <div className='row my-8'>
        <div className='col-4'>
          <InputBS id='email_notifications' name='email_notifications' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.EMAIL' })} disabled={!editing} />
        </div>

        <div className='col-2'>
          <InputBS id='number_notifications_suffix' name='number_notifications_suffix' label='Sufijo' disabled={!editing} />
        </div>

        <div className='col-4'>
          <InputBS id='number_notifications' name='number_notifications' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.WHATS_APP' })} disabled={!editing} />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-12'>
          <TextareaBS id='observations' name='observations' label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.OBSERVATIONS' })} disabled={!editing} />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-2'>
          <SelectField
            id='is_enabled'
            name='is_enabled'
            label={intl.formatMessage({ id: 'FORM.CUSTOMER_SUPPLIERS.GENERAL.IS_ENABLED' })}
            options={[ { value: 0, label: 'NO' }, { value: 1, label: 'SÍ' } ]}
            disabled={!editing}
          />
        </div>
      </div>
    </>
  )
}

export default GeneralTab