import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useCustomerSuppliers } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './SuppliersColumns'
import Filter from './SuppliersFilter'
import { SupplierModal } from '.'

import { RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const SuppliersTab = ({ handleGetCustomerSupplier }) => {
  const { currentUser } = useAuth()

  const { values } = useFormikContext()

  const [showModal, setShowModal] = useState(false)

  const { handleGetSuppliers, handleAddSupplier, handleDeleteSupplier } = useCustomerSuppliers()
  const [suppliers, setSuppliers] = useState([])

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const getSuppliers = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetSuppliers(values.id, params)
      setSuppliers(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setSuppliers([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetSuppliers, values.id, query])

  const addSupplier = async (supplier) => {
    try {
      const response = await handleAddSupplier(values.id, supplier)
      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setShowModal(false)
      handleGetCustomerSupplier()
      getSuppliers()
    }
  }

  const deleteSupplier = async (supplier) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere eliminar la relación con el proveedor #${supplier.supplier_id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, eliminar',
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteSupplier(values.id, supplier.supplier_id)
        toast.success(response.data.message, { theme: "colored" })
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetCustomerSupplier()
      getSuppliers()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)
    if (e) {
      e.preventDefault()
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    // actions.push({
    //   label: 'Ver proveedor',
    //   icon: 'bi bi-eye text-primary',
    //   action: () => navigate(`${routes.CUSTOMER_SUPPLIERS}/${currentRow?.supplier_id}`, { state: { tab: 'general' }})
    // })

    actions.push({
      label: "Eliminar",
      icon: "bi bi-trash text-danger",
      action: () => deleteSupplier(currentRow)
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  useEffect(() => {
    getSuppliers()
  }, [getSuppliers])

  return (
    <>
      <SupplierModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={(value) => {
          console.log(value)
          setShowModal(false)
          addSupplier(value)
        }}
      />

      {currentUser.roles.some(r => r.id != RoleI.COORDINACION) &&
        <div className='text-end'>
          <Button variant='primary' type='button' onClick={() => setShowModal(true)}>Añadir proveedor</Button>
        </div>
      }

      <TableContainer>
        <Filter handleData={setSuppliers} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />

        <CustomTable columns={columns} data={suppliers} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default SuppliersTab