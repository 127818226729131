import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Table } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useStock, useClickOutside } from 'hooks'

import { DetailContainer, TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { COLUMNS } from './columns'
import Actions from './Actions'
import { ArticleScanModal } from './ArticleScanModal'
import { ArticleLotNumberModal } from './ArticleLotNumberModal'
import { DepotMovementDetailStockModal } from './DepotMovementDetailStockModal'

import { DepotMovementStatusI } from 'interfaces'

const labelColor = (statusId) => {
  switch (statusId) {
    case DepotMovementStatusI.EN_PREPARACION:
      return 'estado-amarillo'
    case DepotMovementStatusI.CONFIRMADO:
      return 'estado-verde'
    case DepotMovementStatusI.CANCELADO:
      return 'estado-rojo'
    default:
      return 'estado-gris'
  }
}

const initialQuery = {
  pageNumber: 1,
  pageSize: 10
}

const DepotMovementDetail = () => {
  const { id } = useParams()

  const { loading, handleGetDepotMovement } = useStock()
  const [movement, setMovement] = useState({})

  const { loading: loadingDetail, handleGetDepotMovementDetailGrouped } = useStock()
  const [detail, setDetail] = useState([])

  const { loading: loadingActions, handleCreateDepotMovementDetail, handleDeleteDepotMovementDetail } = useStock()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [showLotNumberModal, setShowLotNumberModal] = useState(false)
  const [showScanModal, setShowScanModal] = useState(false)
  const [showDetailStock, setShowDetailStock] = useState(false)
  const [detailStock, setDetailStock] = useState({})

  const getDepotMovement = useCallback(async () => {
    try {
      const response = await handleGetDepotMovement(id)
      const data = response.data.result

      setMovement(data)
    } catch (error) {
      setMovement([])
      toast.error(error.message)
    }
  }, [id, handleGetDepotMovement])

  const getDepotMovementDetail = useCallback(async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetDepotMovementDetailGrouped(id, params)
      const data = response.data.result

      setDetail(data)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      setDetail([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [id, query, handleGetDepotMovementDetailGrouped])

  const onCloseScanModal = async (data) => {
    try {
      getDepotMovement()
      getDepotMovementDetail()
    } catch (err) {
      toast.error(err.message)
    } finally {
      setShowScanModal(false)
    }
  }

  const onCloseLotNumberModal = async (data) => {
    try {
      if (!data) {
        return
      }

      const transfer = {
        qty: +data.qty,
        lot_number: data.lot_number,
        // expiration_date: data.expiration_date,
        gtin: data.gtin,
        product_var_id: data.product_var_id,
        movement_id: movement.id,
        serial_number: data.serial_number
      }
      const resp = await handleCreateDepotMovementDetail(transfer)
      getDepotMovement()
      getDepotMovementDetail()
      toast.success("Registro actualizado correctamente")
    } catch (err) {
      toast.error(err.message)
    } finally {
      setShowLotNumberModal(false)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({x:0, y:0})
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    if (movement.depot_movement_status_id == DepotMovementStatusI.EN_PREPARACION || movement.depot_movement_status_id == DepotMovementStatusI.CONFIRMADO) {
      actions.push({
        label: "Ver detalle",
        icon: "bi bi-eye text-primary",
        action: () => {
          setDetailStock(currentRow)
          setShowDetailStock(true)
        }
      })
    }

    if (movement.depot_movement_status_id == DepotMovementStatusI.EN_PREPARACION) {
      actions.push({
        label: 'Eliminar detalle',
        icon: 'bi bi-trash-fill text-danger',
        action: () => deleteDepotMovementDetail(currentRow)
      })
    }

    return actions
  }

  const deleteDepotMovementDetail = async (row) => {
    try {
      const resp = await Swal.fire({
        title: "Advertencia",
        text: `Va a eliminar el detalle del artículo "#${row.id} | ${row.name}" ¿confirma?`,
        icon: "warning",
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, eliminar",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteDepotMovementDetail(id, row.id)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      getDepotMovement()
      getDepotMovementDetail()
    }
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor: "pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery
  }

  useEffect(() => {
    getDepotMovement()
    getDepotMovementDetail()
  }, [getDepotMovement, getDepotMovementDetail])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <DetailContainer
      title={`Movimiento interno de stock #${id}`}
      statusV2={
        <>
          <span className={`badge ${labelColor(movement.depot_movement_status_id)} justify-content-center fw-normal`} style={{ width: "180px" }}>
            {movement.depot_movement_status}
          </span>
        </>
      }
      buttons={<Actions loading={loading} movement={movement} handleGetDepotMovement={getDepotMovement} handleGetDepotMovementDetail={getDepotMovementDetail} />}
    >
      <>
        <ArticleScanModal show={showScanModal} onHide={onCloseScanModal} movement_id={id} getStockDetail={getDepotMovement} />
        <ArticleLotNumberModal loading={loadingActions} show={showLotNumberModal} onHide={onCloseLotNumberModal} movement={movement} />

        <DepotMovementDetailStockModal
          show={showDetailStock}
          onHide={() => {
            setShowDetailStock(false)
            setDetailStock({})
          }}
          movement={movement}
          detail={detailStock}
          handleGetDepotMovement={getDepotMovement}
          handleGetDepotMovementDetailDetail={getDepotMovementDetail}
        />

        <div className="row">
          <div className='col-5'>
            <Table className='mt-4'>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>ORIGEN</td>
                </tr>

                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Unidad de Negocio:</td>
                  <td style={{}} className='p-1'>{movement?.origin_business_unit}</td>
                </tr>

                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Dueño:</td>
                  <td className='p-1'>{movement?.origin_customer_supplier}</td>
                </tr>

                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Nombre:</td>
                  <td className='p-1'>{movement?.origin_name}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          <div className='col-2 d-flex align-items-center justify-content-center'>
            <i className='bi bi-arrow-left-right text-primary fs-3' />
          </div>

          <div className='col-5'>
            <Table className='mt-4'>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>DESTINO</td>
                </tr>

                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Unidad de Negocio:</td>
                  <td style={{}} className='p-1'>{movement?.dest_business_unit}</td>
                </tr>

                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Dueño:</td>
                  <td className='p-1'>{movement?.dest_customer_supplier}</td>
                </tr>

                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Nombre:</td>
                  <td className='p-1'>{movement?.dest_name}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        <div className='row'>
          <div className='col'>
            <Table className='mt-4'>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold', width: '140px' }} className='p-1'>Observaciones:</td>
                  <td className='p-1'>{movement?.observations}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>

        {movement.depot_movement_status_id === DepotMovementStatusI.EN_PREPARACION &&
          <div className="row mb-8">
            <div className="col justify-content-end mt-5 d-flex">
              <Button variant='primary' onClick={() => setShowScanModal(true)} className='me-2' disabled={loading}>
                <i className='bi bi-qr-code-scan me-2 mb-1' />
                Pick!
              </Button>

              <Button variant='primary' onClick={() => setShowLotNumberModal(true)} className='me-2' disabled={loading}>
                <i className='bi bi-hand-index-thumb me-2 mb-1' />
                Carga manual
              </Button>
            </div>
          </div>
        }

        <TableContainer>
          {(loading || loadingDetail) && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

          <CustomTable columns={columns} data={detail} paginationOptions={paginationOptions} queryMD={query} setQueryMD={setQuery} handleContextMenu={handleContextMenu} />
        </TableContainer>

        <ContextMenu
          ref={contextRef}
          showContextMenu={showContextMenu}
          setShowContextMenu={setShowContextMenu}
          actions={availableActions()}
          currentPos={currentPos}
        />
      </>
    </DetailContainer>
  )
}

export default DepotMovementDetail