import { useState, useCallback } from 'react'

import { Stock } from 'services'

const useStock = () => {
  const [loading, setLoading] = useState(false)

  // -- Eventos
  const handleGetEventos = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Stock.getEventos(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  // -- Depot movements
  const handleGetDepotMovements = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Stock.getDepotMovements(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDepotMovement = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Stock.getDepotMovement(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDepotMovementStatus = useCallback(async () => {
    try {
      setLoading(true)
      const response = await Stock.getDepotMovementStatus()

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDepotMovementDetail = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Stock.getDepotMovementDetail(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleGetDepotMovementDetailGrouped = useCallback(async (id, params) => {
    try {
      setLoading(true)
      const response = await Stock.getDepotMovementDetailGrouped(id, params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateDepotMovement = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Stock.createDepotMovement(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCancelDepotMovement = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Stock.cancelDepotMovement(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateDepotMovementDetail = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Stock.createDepotMovementDetail(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleCreateDepotMovementDetailWithReader = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Stock.createDepotMovementDetailWithReader(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteDepotMovementDetail = useCallback(async (id, product_var_id) => {
    try {
      setLoading(true)
      const response = await Stock.deleteDepotMovementDetail(id, product_var_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteDepotMovementDetailById = useCallback(async (id, detail_id) => {
    try {
      setLoading(true)
      const response = await Stock.deleteDepotMovementDetailById(id, detail_id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleConfirmDepotMovement = useCallback(async (data) => {
    try {
      setLoading(true)
      const response = await Stock.createDepotMovementConfirm(data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleDeleteInternalMovementDetail = useCallback(async (params) => {
    try {
      setLoading(true);

      const response = await Stock.deleteDepotMovementDetail(params);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGenerarRemitoInterno = useCallback(async (id) => {
    try {
      setLoading(true)
      const response = await Stock.generarRemitoInterno(id)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintRemito = useCallback(async (id, copias) => {
    try {
      setLoading(true)
      const response = await Stock.printRemito(id, copias)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handlePrintInternalWaybill = useCallback(async (id, copias) => {
    try {
      setLoading(true);
      const response = await Stock.printInternalWaybill(id, copias);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const handleGetArticuloPorSerie = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await Stock.getArticuloPorSerie(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    // -- Eventos
    handleGetEventos,
    // -- Depot movements
    handleGetDepotMovements,
    handleGetDepotMovement,
    handleGetDepotMovementStatus,
    handleGetDepotMovementDetail,
    handleGetDepotMovementDetailGrouped,
    handleCreateDepotMovement,
    handleCreateDepotMovementDetailWithReader,
    handleCancelDepotMovement,
    handleCreateDepotMovementDetail,
    handleDeleteDepotMovementDetail,
    handleDeleteDepotMovementDetailById,
    handleConfirmDepotMovement,
    handleGenerarRemitoInterno,
    handlePrintRemito,
    handlePrintInternalWaybill,
    handleDeleteInternalMovementDetail,
    handleGetArticuloPorSerie
  }
}

export default useStock