import routes from "app/routing/routes"
import { Link } from "react-router-dom"

import { Tooltip } from "components"

import { RoleColorsI } from "interfaces"

import { rolesNames } from ".."

const getContrastYIQ = (hexColor) => {
  // Convierte el color hexadecimal a RGB
  const hex = hexColor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  // Calcula el valor YIQ
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Si el valor es mayor que 128, elige negro; de lo contrario, elige blanco
  return yiq >= 128 ? 'black' : 'white';
}

const COLUMNS = [
  {
    Header: 'ID',
    id: 'id',
    accessor: (row) => (
      <Tooltip text='Ver detalle'>
        <Link to={`${routes.USERS}/${row.id}`}>
          {row.id}
        </Link>
      </Tooltip>
    ),
  },
  {
    Header: '',
    id: 'avatar',
    accessor: (row) => (
      <img className='rounded' width={30} height={30} src={`/media/avatars/${row.avatar}`} alt="Logo" />
    ),
  },
  {
    Header: 'Nombre',
    id: 'name',
    accessor: (row) => row.name,
  },
  {
    Header: 'Email',
    id: 'email',
    accessor: (row) => row.email,
  },
  {
    Header: <p className='m-0 p-0 text-center'>Roles</p>,
    id: 'roles',
    accessor: (row) => (
      <div className='text-center'>
        {row.roles.split(';').map((r, i) => (
          <p key={`role-${i}`} className='m-0 p-0 mb-2 p-1 rounded' style={{ color: getContrastYIQ(RoleColorsI[r]), backgroundColor: RoleColorsI[r]}}>{rolesNames(r)}</p>
        ))}
      </div>
    )
  },
  {
    Header: <p className='p-0 m-0 text-center'>Activo</p>,
    id: 'is_active',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge badge-${Boolean(row.is_active) ? 'success' : 'danger'}`}>{Boolean(row.is_active) ? 'Sí' : 'No'}</span>
      </div>
    )
  }
]

export {
  COLUMNS
}