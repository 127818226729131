import React, { useEffect, useState } from "react";
import { Button, FormLabel, Modal } from "react-bootstrap";
import Select from 'react-select';
import { currentDate } from "utilities";
import { useLogisticStock, useOutOrders } from "hooks";
import moment from 'moment';
import {customStyles} from "../../constants"
import { toast } from "react-toastify";
import '../article.css'
import 'react-toastify/dist/ReactToastify.css';
import { useFormikContext } from "formik";

const defaultArticle={
  product_var_id:"",
  product_var_name:"",
  qty:"",
  lot_number:"",
  expiration_date:"",
  serial_number:"",
  refrigerated:""
}

const labelStyle = {
  fontSize: "1.05rem",
  fontWeight: "500",
  color: "var(--bs-gray-800)"
}

export function ArticleAddModal({ show, onHide, requestArticles, default_storage_space_id, storageOptions, articlesGrouped, out_order_id}) {

    const [newArticle, setNewArticle] = useState(defaultArticle);
    const [traceableArticle, setTraceableArticle] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [refrigeratedAlert, setRefrigeratedAlert]  = useState(false);
    const [existingLots, setExistingLots] = useState([]);
    const [existingSerialNumbers, setExistingSerialNumbers] = useState([]);
    const [expDateDisabled, setExpDateDisabled] = useState(false)
    const [outOrderStock, setOutOrderStock] = useState(false)
    const {handleGetStockDetail} = useLogisticStock()
    const {handleGetOutStockStatus} = useOutOrders();
    const {values} = useFormikContext();
    
    const getExistingLots = async(product_var_id)=>{
        try{

            if(product_var_id){

              if(values?.order_type_id==5){
                if(out_order_id){
                  setExistingLots(outOrderStock.filter(s=>s.product_var_id==product_var_id)
                      .filter((obj, index, self) => index === self.findIndex((o) => o.lot_number === obj.lot_number) )
                      .map(s=>({...s, value:s.lot_number, label:s.lot_number}))
                  )
                }
              }
              else{
                const _resp = await handleGetStockDetail({product_var_id})
                setExpDateDisabled(false)
                if(_resp?.result){
                  const _lotes = _resp.result
                    .map(l=>({
                      product_var_id:l.product_var_id, 
                      lot_number:l.lot_number, 
                      expiration_date:l.expiration_date
                    }))
                  const _cargadosEnEstaOrden =articlesGrouped
                    .filter(a=>a.product_var_id==product_var_id)
                    .map(l=>({
                      product_var_id:l.product_var_id, 
                      lot_number:l.lot_number, 
                      expiration_date:l.expiration_date
                    }))

                  setExistingLots([..._lotes, ..._cargadosEnEstaOrden])
                }
                else{
                  setExistingLots([])
                }    
              }
           
            }
        }
        catch(err){
          toast.error("Error cargando lotes")
        }
    }

    const handleChange = (e)=>{
        let {name, value} = e.target;
        if(name=='qty'){
          value = value.replace(/\D/g, '');
        }
        if(name=='lot_number'){
          value = value?.toUpperCase()
        }
        setNewArticle({...newArticle, [name]:value})
    }

    const onBlurLot = (ev)=>{
        let aux = existingLots.filter(s=>s.lot_number==ev?.target?.value)
        if(aux && aux.length){
            //puede haber mas del un row con el mismo lote, que esten en distintos espacios.. la fecha debería ser la misma, tomo el 0
            setNewArticle({...newArticle, expiration_date:aux[0].expiration_date? moment(aux[0].expiration_date).format('YYYY-MM-DD'):""})
            setExpDateDisabled(true)
        }
        else{
          setExpDateDisabled(false)
        }
    }

    const getValueDepot = () => {
      if (storageOptions) {
        return storageOptions.find(option => option.value === newArticle.storage_space_id);
      } else {
        return "";
      }
    };

    const getValueLot = () => {
      if (existingLots) {
        return existingLots.find(option => option.value === newArticle.lot_number);
      } else {
        return "";
      }
    };
    const getValueSerialNumber = () => {
      if (existingSerialNumbers) {
        return existingSerialNumbers.find(option => option.value === newArticle.serial_number);
      } else {
        return "";
      }
    };
    
    const onChangeDepot = (option) => {
      setNewArticle({...newArticle, storage_space_id:option.value});
      if(newArticle.refrigerated==1 && option.cool_type_id==1){
        setRefrigeratedAlert(true)
      }
      else{
        setRefrigeratedAlert(false)
      }
    };
    
    const onChangeLot = (option) => {

      setExistingSerialNumbers([])
      if(values?.order_type_id==5){
        setNewArticle({
          ...newArticle, 
          lot_number:option.value, 
          expiration_date:option.expiration_date?moment(option.expiration_date).format('YYYY-MM-DD'):'',
          serial_number:null
        });
        if(out_order_id){
          setExistingSerialNumbers(outOrderStock.filter(s=>s.product_var_id==newArticle.product_var_id)
              // .filter((obj, index, self) => index === self.findIndex((o) => o.lot_number === obj.lot_number) )
              .filter((s) => s.lot_number == option.value )
              .map(s=>({...s, value:s.serial_number, label:s.serial_number}))
          )
        }
      }
      else{
        setNewArticle({
          ...newArticle, 
          lot_number:option.value, 
          expiration_date:option.expiration_date?moment(option.expiration_date).format('YYYY-MM-DD'):''
        });
      }
    };

    const onChangeSerialNumber = (option) => {
      setNewArticle({
        ...newArticle, 
        serial_number:option.value
      });
    };

    const getValueProduct = () => {
      if (storageOptions) {
        return requestArticles.find(option => option.value == newArticle.product_var_id);
      } else {
        return "";
      }
    };

    const onChangeProduct = (option) => {

        if(!option.traced){
          setNewArticle({...newArticle, qty:"", serial_number:"", product_var_id:option.value, refrigerated:option.refrigerated, lot_number:"", expiration_date:""})

        }
        else{
          setNewArticle({...newArticle, qty:1, product_var_id:option.value, refrigerated:option.refrigerated})
        }
        setTraceableArticle(option.traced)

        const _depot = getValueDepot() 

        if(option.refrigerated==1 && _depot && _depot.cool_type_id==1){
          setRefrigeratedAlert(true)
        }
        else{
          setRefrigeratedAlert(false)
        }

        getExistingLots(option?.value)

    };


    const handleSubmit = ()=>{
        setSubmitted(true)
        const {product_var_id, qty,lot_number,expiration_date,serial_number, storage_space_id} = newArticle;

        if(!product_var_id || !qty || !lot_number || !expiration_date || (traceableArticle && !serial_number) ||
          product_var_id=='' || qty=='' || lot_number=='' || expiration_date=='' || (traceableArticle && serial_number=='') ||
          !storage_space_id){
            return;
        }

        const {product_var_name, refrigerated, ...data} = newArticle
        onHide(data)
    }

    const getOutOrderStock = async()=>{
      const _oo = await handleGetOutStockStatus(out_order_id)
      if(_oo?.data?.result)
        setOutOrderStock(_oo.data.result)
    }

    useEffect(()=>{
      if(outOrderStock){

      }
    }, [outOrderStock])


    useEffect(()=>{

        setNewArticle({...defaultArticle, storage_space_id:default_storage_space_id});
        setSubmitted(false);
        setTraceableArticle(false)
        setRefrigeratedAlert(false)
        setExistingLots([])
        setExpDateDisabled(false)

        if(show && out_order_id){
          getOutOrderStock()
        }
    }, [show])


    return (
      <>
            <Modal size="md" show={show} onHide={onHide}  backdrop='static' >

            <Modal.Body className="">
              <h3>Agregar Artículo</h3>                       

                    <div className="row mt-10">
                      <div className="col">
                        <FormLabel style={labelStyle}>Artículo</FormLabel>
                        <Select
                          options={requestArticles}
                          value={getValueProduct()}
                          onChange={onChangeProduct}
                          styles={customStyles}
                          className={`${submitted && (!newArticle.product_var_id || newArticle.product_var_id=='')?"border border-danger":""}`}
                        />

                      </div>
                    </div>
                    <div className="row mt-10">
                      <div className='col'>                                  
                        <FormLabel style={labelStyle}>Espacio de almacenamiento</FormLabel>
                        <Select
                          options={storageOptions}
                          value={getValueDepot()}
                          onChange={onChangeDepot}
                          styles={customStyles}
                          className={`${submitted && (!newArticle.storage_space_id || newArticle.storage_space_id=='')?"border border-danger":""}`}
                        />
                      </div>
          
                      <div className="col">
                        <label htmlFor='qty' className='form-label'>Cantidad</label>
                        <input type="text" 
                          className={`form-control ${submitted && (!newArticle.qty || newArticle.qty=='') ?"border border-danger":""} mt-1`}
                          id='qty' name="qty" maxLength={5} 
                          value={newArticle.qty || ''} autoComplete="off" onChange={handleChange} disabled={traceableArticle}/>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        
          
                      { refrigeratedAlert==true && <div className="text-danger"> El espacio de almacenamiento no es de frío </div>}

                      </div>
                    </div>
                    { values?.order_type_id!=5 ?
                      <>
                      <div className="row mt-10">
                        <div className="col">
                          <label htmlFor='lot_number' className='form-label' maxLength={30}>Lote</label>
                          <input type="text"
                            className={`form-control ${submitted && (!newArticle.lot_number || newArticle.lot_number=='')?"border border-danger":""}`}
                            id='lot_number' name="lot_number" autoComplete="off" 
                            value={newArticle.lot_number || ''} 
                            onChange={handleChange}
                            onBlur={onBlurLot} 
                          />
                        </div>
                        <div className="col">
                          <label htmlFor='expiration_date' className='form-label'>Vencimiento</label>
                          <input type="date" 
                            className={`form-control ${submitted && (!newArticle.expiration_date || newArticle.expiration_date=='')?"border border-danger":""}`}
                            id='expiration_date' 
                            value={newArticle.expiration_date || ''} onChange={handleChange} name="expiration_date"  min={currentDate}
                            disabled={expDateDisabled}
                          />                          
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="row mt-10">
                        <div className="col">
                          <label htmlFor='lot_number' className='form-label' maxLength={30}>Lote</label>
                          <Select
                            options={existingLots}
                            value={getValueLot()}
                            onChange={onChangeLot}
                            styles={customStyles}
                            className={`${submitted && (!newArticle.lot_number || newArticle.lot_number=='')?"border border-danger":""}`}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor='expiration_date' className='form-label'>Vencimiento</label>
                          <input type="date" 
                            className={`form-control ${submitted && (!newArticle.expiration_date || newArticle.expiration_date=='')?"border border-danger":""}`}
                            id='expiration_date' 
                            value={newArticle.expiration_date || ''} onChange={handleChange} name="expiration_date"  min={currentDate}
                            disabled={true}
                          />                          
                        </div>
                      </div>
                    </>
                    }

                    { traceableArticle == 1 && 
                      <div className="row mt-10">
                        {values?.order_type_id!=5 ?
                            <div className="col">
                              <label htmlFor='serial_number'>Nro. Serie</label>
                              <input type="text" 
                                className={`form-control ${submitted && (!newArticle.serial_number || newArticle.serial_number=='')?"border border-danger":""}`}
                                maxLength={30}
                                id='serial_number' name="serial_number" autoComplete="off" 
                                value={newArticle.serial_number || ''} onChange={handleChange} />
                            </div>
                            :
                            <div className="col">
                              <label htmlFor='serial_number'>Nro. Serie</label>
                              <Select
                                options={existingSerialNumbers}
                                value={getValueSerialNumber()}
                                onChange={onChangeSerialNumber}
                                styles={customStyles}
                                className={`${submitted && (!newArticle.serial_number || newArticle.serial_number=='')?"border border-danger":""}`}
                                isDisabled={!newArticle.lot_number}
                              />
                            </div>
                        }
                      </div>
                    }
            </Modal.Body>

            <Modal.Footer style={{borderTop: "0 none"}}>
              <Button variant="secondary" onClick={onHide}>Salir</Button>
              <Button type="button" variant="primary" onClick={handleSubmit}>Guardar</Button>
            </Modal.Footer>


          </Modal>
    </>
    )
  }
  