import { useState, useRef, useEffect } from "react"
import { Modal, Button, FormGroup, FormLabel, Table } from "react-bootstrap"
import { toast } from 'react-toastify'

import { useArticles, useLogisticStock, useStock } from "hooks"

import { ZebraScanner } from "components"

import { parseGS1DataMatrix } from "utilities/gs1DataMatrix"

export function ArticleScanModal({ show, onHide, movement_id, getStockDetail }) {
  const [scanCodes, setScanCodes] = useState([])
  const {handleGetArticlesV2} = useArticles()
  const { handleCreateDepotMovementDetail } = useStock()
  const { handleGetStockDetail} = useLogisticStock()

  const handleNewTag = (value, err) => {
    if (!value) {
      toast.error(err.message)
      return;
    }

    scanCodeRef.current.push(value)
    setScanCodes(scanCodes => [value, ...scanCodes])
  }

  /************************* Funciones lector zebra **********************************************************************************/
  const barcodesRef = useRef([])
  const scanCodeRef = useRef([])
  const processingQueue = useRef(false)
  const canAddCode = useRef(true)

  const processEnter = async (cadena) => {
    if (canAddCode.current) {
      canAddCode.current = false
      const buffCadena = cadena
      cadena = ""

      const dataTransformada = buffCadena
        .replace(/Shift/g, "")
        .replace(/CapsLock/g, '')
        .replace(/Alt0029/g, "\u001D")
        .replace(/'/g, '-')

      const data = parseGS1DataMatrix(dataTransformada)
      data.scan_code = dataTransformada

      // if(!data.lot_number){
      //   toast.error("El código GS1 es parcial, se debe pickear con el botón 'GS1 Parcial!'")
      //   canAddCode.current=true;
      //   return;
      // }

      let _resp = await handleGetArticlesV2({ gtin: data.gtin })
      let _articulo = null;

      if (_resp?.data?.result) {
        _articulo = _resp.data.result[0]
      }

      if (!scanCodeRef.current.map(c => c.serial_number).includes(data.serial_number)) {
        const _stockDetail = await handleGetStockDetail({
          serial_number:data.serial_number,
          gtin:data.gtin
        })

        const transfer = {
          qty: 1,
          lot_number: data.lot_number ? data.lot_number : _stockDetail?.lot_number,
          // expiration_date: data.expiration_date?moment(data.expiration_date, 'YYMMDD').format("YYYY-MM-DD"):'',
          gtin: data.gtin,
          product_var_id: _articulo?.id || null,
          product_var_name: _articulo?.name || '',
          serial_number: data.serial_number ? data.serial_number : undefined,
          loading: true,
        }

        handleNewTag(transfer, null)

        const asyncJob = getAsyncJob(transfer)
        enqueue(asyncJob)
      }

      canAddCode.current = true
    }
  }

  const nextJob = () => {
      if (processingQueue.current) return

      const next = barcodesRef.current.shift()
      // if the array is empty shift() will return undefined

      if (next) {
        processingQueue.current = true
        next
          .job()
          .then((value) => {
            next.resolve(value)
            processingQueue.current = false
            nextJob()
          })
          .catch((error) => {
            toast.error(error?.err?.message)
            next.resolve(error)
            processingQueue.current = false
            nextJob();
          })
      }
  }

  const enqueue = (job) => {
    // we'll wrap the job in a promise and include the resolve
    // and reject functions in the job we'll enqueue, so we can
    // control when we resolve and execute them sequentially
    new Promise((resolve, reject) => {
      barcodesRef.current.push({ job, resolve, reject })
    })
    // we'll add a nextJob function and call it when we enqueue a new job;
    // we'll use _isBusy to make sure we're executing the next job sequentially
    nextJob()
  }

  const getAsyncJob = (item) => {
    return () =>
      new Promise((resolve, reject) => {
        const {product_var_name, loading, ...rest} = item

        const transport = { ...rest, movement_id }
        handleCreateDepotMovementDetail(transport)
          .then(res => resolve(res))
          .catch(err => reject({ ...transport, err }))
      })
  }
  /**********************************************************************************************************/

  useEffect(() => {
    setScanCodes([])
  }, [show])

  return (
    <Modal size="xl" show={show} onHide={onHide} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Picking</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/* <h3>Picking</h3> */}

        {/* <BarCodeReader  handleNewTag={handleNewTag} movement_id={movement_id} storage_space_id={1}/> */}
        <ZebraScanner processEnter = {processEnter} />

        <div>
          {true &&
            <FormGroup>
              <FormLabel className="mt-10">Escaneados</FormLabel>
              <Table striped hover>
                <thead>
                  <tr>
                    <td>Producto</td>
                    <td>Código</td>
                    <td>Serie</td>
                    <td>Lote</td>
                    {/* <td>Vencimiento</td> */}
                  </tr>
                </thead>

                <tbody>{scanCodes?.map((v, idx) => (
                  <tr key={idx}>
                    <td>{v.product_var_name}</td>
                    <td>{v.gtin}</td>
                    <td>{v.serial_number || 'No trazable'}</td>
                    <td>{v.lot_number || 'No trazable'}</td>
                    {/* <td>{v.vencimiento || 'No trazable'}</td> */}
                  </tr>
                ))}
                </tbody>
              </Table>
            </FormGroup>
          }
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "0 none" }}>
        <Button variant="secondary" onClick={() => onHide()}>
          <i className="bi bi-slash-circle" />
          Salir
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
