import moment from 'moment';
import { OutOrderStatusI, RoleI, TransactionalDocumentTypeI } from 'interfaces'
import { useAuth } from 'app/modules/auth';
const types = {
  [TransactionalDocumentTypeI.FACTURA]: 'Factura',
  [TransactionalDocumentTypeI.NOTA_DEBITO]: 'Nota de débito',
  [TransactionalDocumentTypeI.NOTA_CREDITO]: 'Nota de crédito',
  [TransactionalDocumentTypeI.REMITO]: 'Remito',
}

const useGetColumns = (selectedRows, handleRowSelection) => {

  const { currentUser } = useAuth()

  const COLUMNS = [
    {
      Header: "OE",
      id: 'id',
      accessor: "id",
      hasOrder:true
    },
    {
      Header:  () => (<div style={{ textAlign: "center" }}>Fecha</div>),
      id: 'dispatch_date',
      accessor: (row) => (
        <div className='text-center'>
          {row?.dispatch_date ? moment(row.dispatch_date).format("DD/MM/YYYY") : '-'}
        </div>
      ),
      hasOrder:true
    },
    {
      Header: 'Pedido',
      id: 'sales_order_id',
      accessor: (row) => (
        <>
          {row.sales_order_id}
        </>
      )
    },
    {
      Header: "Cliente",
      accessor: 'customer_supplier_business_name',
      hasOrder:true
    },
    {
      Header: "Remito",
      accessor: 'remito_nro_cbt_completo',
      hasOrder:true
    },
    {
      Header:   () => (<div style={{ textAlign: "center" }}>Total</div>),
      id: 'total',
      accessor: (row) => (
        <div className='text-end'>
          $ {Number(row.total).toFixed(2)}
        </div>
      ),
      hasOrder:true
    },


  ]

  return {
    COLUMNS
  }
}

export default useGetColumns