import service from '../../service'

const PATH = {
  shipmentOrder: '/waybills/shipment-order',
  shipmentOrderGrouped: '/waybills/shipment-order-grouped',
  dispatchOrder: '/waybills/dispatch-order',
  createDispatchOrder: '/waybills/shipment-order/create-dispatch-order'
}

export class Waybill {
  // -- Shipment orders
  static getShipmentOrders = async (params) => {
    const response = await service.get(PATH.shipmentOrder, {
      params
    })

    return response
  }

  static getShipmentOrder = async (id) => {
    const response = await service.get(`${PATH.shipmentOrder}/${id}`)
    return response
  }

  static getShipmentOrderDetail = async (id, params) => {
    const response = await service.get(`${PATH.shipmentOrder}/${id}/detail`, {
      params
    })
    return response
  }

  static updateShipmentOrderShippingCarrierService = async (id, shipping_carrier_service_id) => {
    const response = await service.post(`${PATH.shipmentOrder}/${id}/update-shipping-carrier-service`, { shipping_carrier_service_id })
    return response
  }

  static printDispatchGuide = async (id, params) => {
    const response = await service.get(`${PATH.shipmentOrder}/${id}/print`, {
      params,
      responseType: 'blob',
    })
    return response
  }

  // -- Dispatch orders
  static getDispatchOrders = async (params) => {
    const response = await service.get(PATH.dispatchOrder, {
      params
    })

    return response
  }

  static getDispatchOrder = async (params) => {
    const {id, queryString} = params

    const response = await service.get(`${PATH.dispatchOrder}/${id}?${queryString}`)
    return response
  }

  static createDispatchOrder = async (data) => {
    const response = await service.post(PATH.createDispatchOrder, data)
    return response
  }

  static createDispatchOrderGrouped = async (data) => {
    const response = await service.post(PATH.dispatchOrder, data)
    return response
  }
  
  static cancelDispatchOrder = async (id) => {
    const response = await service.patch(`${PATH.dispatchOrder}/${id}/cancel`)
    return response
  }


  static removeOrderDeliveryFromDispatch = async (params) => {
    const {dispatch_order_id, detail_id} = params;
    const response =  await service.delete(`${PATH.dispatchOrder}/${dispatch_order_id}/detail/${detail_id}`)
    return response
  }

  static confirmDispatch = async (id) => {
    const response = await service.post(`${PATH.dispatchOrder}/${id}/confirm`, {})
    return response
  }

  static addToDispatch = async (params) => {
    console.log(params)
    const {dispatch_order_id, ...rest} = params;
    const response = await service.post(`${PATH.dispatchOrder}/${dispatch_order_id}/add-order-delivery`, rest)
    return response
  }

  static getDispatchOrdersPending = async () => {
    const response = await service.get(`${PATH.shipmentOrderGrouped}/pending`)
    return response
  }


  static markOrderAsDelivered = async (data) => {
    const response = await service.post(`${PATH.shipmentOrder}/deliver`, data)
    return response
  }

  
  static updateDelivery = async (data) => {
    const response = await service.post(`${PATH.shipmentOrder}/update-delivery`, data)
    return response
  }
  



}