
import {  Input, InputBS, SelectField, Textarea } from 'components'
import { useFormikContext } from 'formik'
import { useCustomerSuppliers, useRequest } from 'hooks'
import { useEffect, useState } from 'react'
import moment from 'moment'
import InputMask from 'react-input-mask';

const GeneralTab = ({request}) => {
    const [loading] = useState(false)
    const currentDate = new Date().toISOString().split('T')[0];
    const {handleGetCustomerSupplierShipmentAddresses} = useCustomerSuppliers();
    const [listSupplierAddresses, setListSupplierAddresses] = useState([])
    const [waybillNumberError, setWaybillNumberError] = useState([])
    const [invoiceNumberError, setInvoiceNumerError] = useState([])

    const {values, setFieldValue} = useFormikContext();

    const handleChangeWaybill = (e)=>{
        let {value} = e.target;
        value = value.replace(/\D/g, '');
        setWaybillNumberError(false)
        setFieldValue('waybill_number', value)
        // setNewInvoice({...newInvoice, supplier_invoice:value})

        // setNewArticle({...newArticle, [name]:value})

    }

    const loadAddresses = async (customer_supplier_id) => {
        const data = await handleGetCustomerSupplierShipmentAddresses(customer_supplier_id);
        setListSupplierAddresses(data?.data?.result.map(d => ({...d, label: d.address?.formatted_address, value: d.id })))
    }

    useEffect(() => {
        if (values && listSupplierAddresses.length == 0) {
            if (values.distributor_id) {
                loadAddresses(values.distributor_id)
            } else if (values.supplier_id) {
                loadAddresses(values.supplier_id);
            }
        }
    }, [values.distributor_id, values.supplier_id])

    return (
        <>
            <div className='row mb-5'>
                <div className='col text-start pt-4 '>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{width:"150px"}}>{values.order_type_label}</td>
                                <td className='fw-bold'>{values.in_request_id}</td>
                            </tr>
                            <tr>
                                <td>{values.order_type_id==5?'Cliente' : 'Proveedor'}</td>
                                <td className='fw-bold'>{values.supplier_name}</td>
                            </tr>
                            { values.order_type_id!=5?
                                <>
                                    <tr>
                                        <td>Distribuidor</td>
                                        <td className='fw-bold'>{values.distributor_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Condición de pago</td>
                                        <td className='fw-bold'>{values.payment_condition_label}</td>
                                    </tr>
                                    <tr>
                                        <td>Fecha pedido</td>
                                        <td className='fw-bold'>{values.issue_date?moment(values.issue_date).format("DD/MM/YYYY"):''}</td>
                                    </tr>
                                </>
                                :
                                <></>
                            }
                            <tr>
                                <td>Fecha ingreso</td>
                                <td className='fw-bold'>{values.income_date?moment(values.income_date).format("DD/MM/YYYY"):''}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* <div className='col text-start pt-4'>
                    <h5>Pedido {values?.in_request_id}</h5>
                </div> */}
            </div>

            {/* <div className='row mb-10 mt-5'>
                <div className='col'>
                    <InputBS label='Proveedor' id='supplier_name' name='supplier_name' disabled/>
                </div>
                <div className='col'>
                    <InputBS label='Condición de pago' id='payment_condition_label' name='payment_condition_label' disabled/>
                </div>
                <div className="col">
                    <InputBS label='Fecha pedido' id='issue_date' name='issue_date' disabled type="date"/>
                </div>
                <div className='col'>
                    <InputBS label='Fecha Ingreso' id='income_date' name='income_date' disabled type="date"/>
                </div>
            </div> */}

            <div className='row mb-5'>
                <div className='col'>
                    <InputBS
                        id='waybill_number'
                        name='waybill_number'
                        label='Remito'
                        maxLength={20}
                        // onBlur={() => (values.waybill_number.length < 12 && values.waybill_number.length > 1) ? setWaybillNumberError(true) : setWaybillNumberError(false)}
                    />
                    {/* <label className='form-label'>Remito</label>
                    <InputMask className="form-control"
                        mask="R-9999-999999999" maskChar={null}
                        value={values.waybill_number || ""}
                        onChange={handleChangeWaybill}
                        // beforeMaskedValueChange={this.beforeMaskedValueChange}
                        onBlur={()=>values.waybill_number.length<12?setWaybillNumberError(true):setWaybillNumberError(false)}
                    /> */}
                    {/* {waybillNumberError==true && <p className='text-danger'>El remito debe tener 12 o 13 dígitos</p>} */}
                </div>
                <div className='col'>
                    <SelectField isMulti={false} label={'Dirección'} name="shipment_address_id" options={listSupplierAddresses} />
                </div>
            </div>

            <div className='row mb-5'>
                <div className="col-3">
                    <InputBS
                        id='supplier_invoice'
                        name='supplier_invoice'
                        label='Factura'
                        maxLength={20}
                        // onBlur={()=>(values.supplier_invoice.length<12 && values.supplier_invoice.length>1)?setInvoiceNumerError(true):setInvoiceNumerError(false)}
                    />
                    {/* <label className='form-label'>Factura</label>
                    <InputMask className="form-control"
                        mask="A-99999-99999999" maskChar={null}
                        value={values.supplier_invoice || ""}
                        onChange={(e)=>setFieldValue('supplier_invoice', e.target.value)}
                        // beforeMaskedValueChange={this.beforeMaskedValueChange}
                    /> */}
                    {/* {invoiceNumberError==true && <p className='text-danger'>La factura debe tener 12 o 13 dígitos</p>} */}
                </div> 
                <div className='col-3'>
                    <InputBS label='Fecha Factura' id='supplier_invoice_date' name='supplier_invoice_date' type='date' max={currentDate}/>
                </div>
                <div className='col-6'>
                    <InputBS label='Transportista' id='carrier' name='carrier' type='text'/>
                </div>
            </div>


            <div className='row mb-20'>
                <div className="col">
                    <label htmlFor='notes' className='form-label'>Notas</label>
                    <Textarea id='notes' name='notes'/>
                </div>
                <div className="col">
                    <label htmlFor='internal_notes' className='form-label'>Notas internas</label>
                    <Textarea id='internal_notes' name='internal_notes'/>
                </div>
            </div>
        </>
    )
}

export default GeneralTab;