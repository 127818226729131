import React from 'react'
import moment from 'moment'

const Header = ({ data }) => {
  if (!data) return null

  return (
    <>
      <span className={`badge ${data?.label_color} justify-content-center fw-normal`} style={{ width: "150px" }}>{data.dispatch_order_status_label}</span>

      <div className='mt-7'>
        <div className='mt-7'>
          <div className='mt-7'>
            <table>
              <tbody>
                <tr>
                  <td style={{ width: "50px" }}>Transportista:</td>
                  <td><span className='fw-bold ps-5'>{data?.shipping_carrier_service_name}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Cant. órdenes de envío:</td>
                  <td><span className='fw-bold ps-5'>{data?.cant_envios}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Cant. órdenes de egreso:</td>
                  <td><span className='fw-bold ps-5'>{data?.cant_ordenes_egreso}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Fecha creación:</td>
                  <td><span className='fw-bold ps-5'>{moment(data.created_at).format('DD/MM/YYYY HH:mm')}</span></td>
                </tr>
                <tr>
                  <td style={{ width: "200px" }}>Fecha despacho:</td>
                  <td><span className='fw-bold ps-5'>{data?.dispatch_at ? moment(data.dispatch_at).format('DD/MM/YYYY HH:mm') : '-'}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header