import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useWaves } from 'hooks'

import { Input, SelectField } from 'components'

export const ESTADOS_ORDEN_ENVIO = {
  PENDIENTE_DESPACHO: 1,
  EN_DESPACHO: 2,
  EN_DISTRIBUCION: 4,
  ENTREGADA: 5,
  CANCELADO: 3
}

const optionsStatus = [
  { label: "Pendiente de despacho", value: ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO },
  { label: "En despacho", value: ESTADOS_ORDEN_ENVIO.EN_DESPACHO },
  { label: "En distribución", value: ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION },
  { label: "Entregada", value: ESTADOS_ORDEN_ENVIO.ENTREGADA },
  { label: "Cancelado", value: ESTADOS_ORDEN_ENVIO.CANCELADO }
]

const initialValues = {
  id: '',
  dispatch_order_id: '',
  out_order_ids: '',
  sales_order_ids: '',
  from_date: '',
  to_date: '',
  text: '',
  direccion: '',
  localidad: '',
  provincia: '',
  shipping_carrier_service_id_filter: [],
  order_delivery_status_id: [ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO]
}

const Filter = ({ loading, queryMD, setQueryMD }) => {
  const { currentUser, setCurrentUser } = useAuth()
  const [initialFormikValues, setInitialFormikValues] = useState(initialValues)

  const { handleGetShippingCarrierServices } = useWaves()
  const [carriers, setCarriers] = useState([])

  const filterData = async (values) => {
    let params = {
      ...queryMD,
      ...values,
      id: values?.id ? values.id : undefined,
      text: values?.text ? values.text : undefined,
      from_date: values?.from_date ? values.from_date : undefined,
      to_date: values?.to_date ? values.to_date : undefined,
      shipping_carrier_service_id_filter: values?.shipping_carrier_service_id_filter ? values.shipping_carrier_service_id_filter : undefined,
      dispatch_order_id: values?.dispatch_order_id ? values.dispatch_order_id : undefined,
      out_order_ids: values?.out_order_ids ? values.out_order_ids : undefined,
      sales_order_ids: values?.sales_order_ids ? values.sales_order_ids : undefined,
      order_delivery_status_id: values?.order_delivery_status_id ? values.order_delivery_status_id : undefined,
    }

    setCurrentUser((prevCurrentUser) => ({
      ...prevCurrentUser,
      shipment_orders: { ...params }
    }))

    setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
  }

  const getCarriers = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'ASC',
      }
      const response = await handleGetShippingCarrierServices(params)
      const data = response.data.result.map(d => ({
        value: d.id,
        label: `${d?.shipping_code ? `[${d.shipping_code}] ` : ''}${d.shipping_carrier_name} - ${d.shipping_carrier_service} (${d.shipping_type_name})`
      }))

      setCarriers(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetShippingCarrierServices])

  useEffect(() => {
    getCarriers()
  }, [getCarriers])

  useEffect(() => {
    if (currentUser?.user_id) {
      const { shipment_orders } = currentUser;

      setInitialFormikValues({
        ...initialValues,
        id: shipment_orders?.id || '',
        dispatch_order_id: shipment_orders?.dispatch_order_id || '',
        sales_order_ids: shipment_orders?.sales_order_ids || '',
        out_order_ids: shipment_orders?.out_order_ids || '',
        from_date: shipment_orders?.from_date || '',
        to_date: shipment_orders?.to_date || '',
        direccion: shipment_orders?.direccion || '',
        localidad: shipment_orders?.localidad || '',
        provincia: shipment_orders?.provincia || '',
        shipping_carrier_service_id_filter: shipment_orders?.shipping_carrier_service_id_filter || [],
        order_delivery_status_id: shipment_orders?.order_delivery_status_id ? shipment_orders.order_delivery_status_id : [ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO],
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, resetForm, submitForm }) => {
        return (
          <Form>
            <div className='row'>
              <div className='col-2'>
                <Input id='id' name='id' disabled={loading} />
                <p className='text-muted'>Nro.</p>
              </div>

              <div className='col-2'>
                <Input id='dispatch_order_id' name='dispatch_order_id' placeholder='Ej: 1234' disabled={loading} />
                <p className='text-muted'>Orden de despacho</p>
              </div>

              <div className='col-2'>
                <Input id='out_order_ids' name='out_order_ids' placeholder='Ej: 1234, 4321' disabled={loading} />
                <p className='text-muted'>IDs O. Egreso</p>
              </div>

              <div className='col-2'>
                <Input id='sales_order_ids' name='sales_order_ids' placeholder='Ej: 4321, 1234' disabled={loading} />
                <p className='text-muted'>IDs Pedidos</p>
              </div>

              <div className='col-2'>
                <Input id='from_date' name='from_date' type='date' disabled={loading} />
                <p className='text-muted'>Desde</p>
              </div>

              <div className='col-2'>
                <Input id='to_date' name='to_date' type='date' disabled={loading} />
                <p className='text-muted'>Hasta</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-3'>
                <Input id='direccion' name='direccion' disabled={loading} />
                <p className='text-muted'>Nombre / Dirección / GLN</p>
              </div>

              <div className='col-3'>
                <Input id='localidad' name='localidad' disabled={loading} />
                <p className='text-muted'>Localidad</p>
              </div>

              <div className='col-3'>
                <Input id='provincia' name='provincia' disabled={loading} />
                <p className='text-muted'>Provincia</p>
              </div>

              <div className='col-3'>
                <SelectField
                  id='order_delivery_status_id'
                  name='order_delivery_status_id'
                  options={optionsStatus}
                  placeholder='Seleccione un estado'
                  marginTop={'-20px'}
                  isMulti
                  disabled={loading}
                />
                <p className='text-muted'>Estado</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-6'>
                <SelectField
                  id='shipping_carrier_service_id_filter'
                  name='shipping_carrier_service_id_filter'
                  options={carriers}
                  placeholder='Seleccione una opción'
                  marginTop={'-20px'}
                  isMulti
                  disabled={loading}
                />
                <p className='text-muted'>Transportista</p>
              </div>
            </div>

            <div className='row'>
              <div className='col-12 text-end'>
                <Button
                  type='button'
                  variant="secondary"
                  onClick={() => {
                    resetForm({ values: initialValues })
                    submitForm()
                  }}
                  className='me-3'
                  disabled={loading}
                >
                  <i className="bi bi-zoom-out mb-1" />
                  Limpiar
                </Button>

                <Button type='submit' variant="primary" disabled={loading}>
                  <i className="bi bi-search mb-1" />
                  {loading ? 'Cargando...' : 'Buscar'}
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter