import { Link } from 'react-router-dom'
import moment from 'moment'

import routes from 'app/routing/routes'

const useGetColumns = (selectedRows = [], handleRowSelection) => {
  const COLUMNS =  [
    // {
    //   Header: 'ID',
    //   id: 'id',
    //   accessor: (row) => (
    //     <div>
    //       {row.id}
    //     </div>
    //   )
    // },
    {
      Header: <p className='m-0 p-0 text-center'>Orden de envío</p>,
      id: 'order_delivery_id',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.LOGISTICS_SHIPMENT_ORDERS}/${row.order_delivery_id}`}>
            {row.order_delivery_id}
          </Link>
        </div>
      )
    },
    {
      Header: <p className='m-0 p-0 text-center'>Pedido</p>,
      id: 'sales_order_id',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.SALES_ORDERS}/${row.sales_order_id}`}>
            {row.sales_order_id}
          </Link>
        </div>
      )
    },
    {
      Header: <p className='m-0 p-0 text-center'>OE</p>,
      id: 'out_order_id',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.OUT_ORDERS}/${row.out_order_id}`}>
            {row.out_order_id}
          </Link>
        </div>
      ),
    },
    {
      Header: 'Dirección de envío',
      id:"address_ship",
      accessor: (row) => (
        <div>
          {row?.address_ship?.id
            ? <p className='p-0 m-0'>{`${row.address_ship.domicilio}, ${row.address_ship.localidad_completa}`}</p>
            :
              <>
                <p className='p-0 m-0'>{row.address_ship?.name}</p>
                <p className='p-0 m-0'>{row.address_ship?.domicilio}</p>
                <p className='p-0 m-0'>{row.address_ship?.localidad_completa}</p>
              </>
          }
        </div>
      ),
    },
    // {
    //   Header: 'Dirección',
    //   id:"address_traza",
    //   accessor: (row) => (
    //     <div>
    //       {row?.address_traza?.formatted_address
    //         ? <p className='p-0 m-0'>{row.address_traza.formatted_address}</p>
    //         :
    //           <>
    //             <p className='p-0 m-0'>{row.address_traza?.name}</p>
    //             <p className='p-0 m-0'>{row.address_traza?.domicilio}</p>
    //             <p className='p-0 m-0'>{row.address_traza?.localidad_completa}</p>
    //           </>
    //       }
    //     </div>
    //   ),
    // },
    {
      Header: 'Servicio',
      id: 'shipping_carrier_service',
      accessor: (row) => (
        <div>
          {row.shipping_carrier_service.shipping_carrier_service}{row.shipping_carrier_service?.shipping_code ? ` (${row.shipping_carrier_service.shipping_code})` : ''}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>¿Refrigerado?</div>,
      id: 'refrigerated',
      accessor: (row) => (
        <div className='text-center'>
          {Boolean(row.shipping_carrier_service.refrigerated) ? <i className='bi bi-snow text-primary' /> : <span className='badge badge-danger'>NO</span>}
        </div>
      )
    },
    {
      Header: <div className=''>AFIP</div>,
      id: 'afip',
      accessor: (row) => (
        <div>
          {row.afip?.afip_nro_cbt_completo}
        </div>
      ),
    },
    {
      Header: <div className=''>Remito</div>,
      id: 'remito',
      accessor: (row) => (
        <div>
          {row.remito?.remito_nro_cbt_completo}
        </div>
      ),
    },
  ]

  return {
    COLUMNS
  }
}

export default useGetColumns