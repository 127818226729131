import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { CustomTable, Input, Tooltip } from 'components'
import {ESTADOS_PEDIDO_COMPRA} from '../List/index'


const ArticlesTab = ({ loading }) => {
  const { values } = useFormikContext()

  const columns =  [
          {
            Header: "Artículo",
            accessor: 'product_var_desc',
          },
          {
            Header: "GTIN",
            accessor: 'gtin',
          },
          // {
          //   Header: "Subtotal",
          //   id: 'subtotal',
          //   accessor: (row) => (
          //     <span>{currencyFormat.format(row.subtotal)}</span>
          //   )
          // },
          {
            Header: "Cantidad",
            id: 'original_qty',
            accessor: (row) => (
              <span>{row.original_qty}</span>
            )
          },
          {
            Header: "Devolver",
            id: 'qty',
            accessor: (row, index) => (
              <Input 
                onInput={(e) => { e.target.value = e.target.value.replace(/\D/g, '') }}
                name={`detail[${index}].qty`} 
                id={`detail[${index}].qty`} 
                className={`form-control ${(values.detail[index].qty > values.detail[index].original_qty || values.detail[index].qty < 0 ) ? 'border-danger' : ''}`}
                style={{width:"100px"}}
                disabled={loading || values?.order_status_id != ESTADOS_PEDIDO_COMPRA.BORRADOR}
                />
            )
          },
          {
            Header: "",
            id: 'icons',
            accessor: (row) => (
              <div className='d-flex'>
                {Boolean(row.refrigerated) && <Tooltip text='Requiere frío'><span className='bi bi-snow text-primary fs-3 me-2' /></Tooltip>}
                {Boolean(row.traced) && <Tooltip text='Artículo trazable'><span className='bi bi-broadcast-pin text-primary fs-3 me-2' /></Tooltip>}
              </div>
            )
          }
    ]

    useEffect(()=>{
console.log(loading)
    }, [loading])
   
  return (
    <>
      <div className='row mb-8 align-center'>
        <div className={`${values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) ? 'd-flex flex-row align-items-center justify-content-between' : ''}`}>
          {values?.detail.length > 0 && values?.detail.some(d => Boolean(d.outdated_pvp)) &&
            <div className='col text-start mb-8 fw-bold flex-grow-1'>
              Por favor, elimine los artículos con PVP desactualizado e ingreselos nuevamente.
            </div>
          }

          <div className='col text-end mb-8' style={{ flex: 'none' }}>

          </div>
        </div>
      </div>
      { (loading) && (
          <div className="custom-overlay-layer d-flex justify-content-center align-items-center">
            <div className="spinner-border text-success" role="status"></div>
          </div>
      )}
      <CustomTable columns={columns} data={values?.detail}  />


    </>
  )
}

export default ArticlesTab