import { Link } from 'react-router-dom'
import moment from 'moment'

import { Tooltip } from 'components'

import { ShipmentOrderStatusI } from 'interfaces'
import { currencyFormat } from 'utilities'

import routes from 'app/routing/routes'
import '../styles.css'

const useGetColumns = (selectedRows = [], handleRowSelection) => {
  const COLUMNS =  [
    {
      Header: '',
      id: 'selected',
      accessor: (row) => (
        <input
          name="selected"
          type="checkbox"
          value={row.id}
          onChange={() => handleRowSelection(row.id, row.order_delivery_status_id, row.shipping_carrier_id, row)}
          checked={selectedRows.some(i => i.id === row.id)}
          // disabled={row.order_delivery_status_id !== ShipmentOrderStatusI.PENDIENTE_DESPACHO || selectedRows.some(i => i.shipping_carrier_service_id !== row.shipping_carrier_service_id)}
          disabled={
            !Boolean(row.todas_las_ordenes_remitidas) ||
            row.order_delivery_status_id !== ShipmentOrderStatusI.PENDIENTE_DESPACHO ||
            selectedRows.some(i => i.shipping_carrier_id !== row.shipping_carrier_id)
          }
        />
      ),
    },
    {
      Header: '',
      id: 'todas_las_ordenes_remitidas',
      accessor: (row) => (
        <div className='row'>
          {!Boolean(row.todas_las_ordenes_remitidas) &&
            <div className='col'>
              <Tooltip text={`${row.out_order_count > 1 ? `Hay una o más Órdenes` : 'Orden'} de egreso sin remitir`}>
                <i className='bi bi-info-circle text-danger' />
              </Tooltip>
            </div>
          }

          {selectedRows.some(i => i.shipping_carrier_id !== row.shipping_carrier_id) &&
            <div className='col'>
              <Tooltip text='El transportista es distinto de la selección(es) hecha(s)'>
                <i className='bi bi-info-circle text-warning' />
              </Tooltip>
            </div>
          }
        </div>
      )
    },
    {
      Header: <p className='m-0 p-0'>Nro.</p>,
      id: 'id',
      accessor: (row) => (
        <Tooltip text='Ver detalle'>
          <Link to={`${routes.LOGISTICS_SHIPMENT_ORDERS}/${row.id}`}>
            {row.id}
          </Link>
        </Tooltip>
      ),
      hasOrder: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>Fecha</p>,
      id: 'created_at',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {moment(row.created_at).format('DD/MM/YYYY HH:mm')}
        </p>
      ),
      hasOrder: true
    },
    {
      Header: <p className='m-0 p-0'>Nombre / Dirección</p>,
      id: 'ship_name',
      accessor: (row) => (
        <>
          <strong className='m-0 p-0'>{row.ship_name}</strong>

          <p className='m-0 p-0 mt-4'>{row?.ship_domicilio?.trim()}</p>
          <p className='m-0 p-0 mt-2'>GLN: {row?.ship_gln || '-'}</p>
        </>
      ),
    },
    {
      Header: <p className='m-0 p-0'>Localidad</p>,
      id: 'ship.localidad',
      accessor: (row) => (
        <p className='m-0 p-0'>{row?.ship_localidad?.trim()}</p>
      ),
    },
    {
      Header: <p className='m-0 p-0'>Provincia</p>,
      id: 'ship.provincia',
      accessor: (row) => (
        <p className='m-0 p-0'>{row?.ship_provincia}</p>
      ),
    },
    {
      Header: <p className='m-0 p-0'>Transportista</p>,
      id: 'shipping_carrier_service_id',
      accessor: (row) => (
        <p className='m-0 p-0'>
          {row.shipping?.shipping_code ? `[${row.shipping?.shipping_code}] ` : ''}<strong>{row.shipping.name}</strong> - {row.shipping.service} ({row.shipping.shipping_type})
        </p>
      ),
    },
    {
      Header: <p className='m-0 p-0 text-center'>Orden de despacho</p>,
      id: 'dispatch_order_id',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row?.dispatch_order_id
            ?
              <Tooltip text='Ir a Despacho'>
                <Link to={`${routes.LOGISTICS_DISPATCH_ORDERS}/${row.dispatch_order_id}`}>
                  {row.dispatch_order_id}
                </Link>
              </Tooltip>
            : '-'
          }
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: <p className='m-0 p-0 text-center'>Cant. de órdenes</p>,
      id: 'out_order_count',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.out_orders_ids.split(',').length}
        </p>
      ),
    },
  ]

  const COLUMNS_2 = [
    {
      Header: <div className='text-end'>Total</div>,
      id: 'total',
      accessor: (row) => (
        <div className='text-end'>
          {currencyFormat.format(row.total)}
        </div>
      )
    },
    {
      Header: <div className="text-center">Estado</div>,
      id: 'order_status_label',
      className:'col-status',
      accessor: (row) => (
        <>
          <span className={`badge ${row.label_color} w-100 justify-content-center m-0`}>
            {row.order_delivery_status_label}
          </span>
        </>
      ),
    },
    {
      Header: "",
      id: 'icons',
      accessor: (row) => (
        <div className='d-flex'>
          {Boolean(row.has_refrigeration) && <Tooltip text='Requiere frío'><span className='bi bi-snow text-primary fs-3 me-2' /></Tooltip>}
          {Boolean(row.has_traced) && <Tooltip text='Artículos trazables'><span className='bi bi-broadcast-pin text-primary fs-3 me-2' /></Tooltip>}
          {Boolean(row.tiene_redespacho) && <Tooltip text='Tiene redespacho'><span className='bi bi-arrow-repeat text-primary fs-3 me-2' /></Tooltip>}
        </div>
      )
    },
  ]

  return {
    COLUMNS,
    COLUMNS_2
  }
}

export default useGetColumns