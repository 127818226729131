import { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useTransactionalDocuments } from 'hooks'

const FacturaDiferidaModal = ({ documentInfo, show, onHide }) => {
  const { loading, handleEmitirDiferido, handlePrintFactura } = useTransactionalDocuments()
  const [info, setInfo] = useState(null)
  const [facturaGenerada, setFacturaGenerada] = useState(false)

  const onClose = () => {
    setFacturaGenerada(false)
    onHide()
  }

  const handleSubmit = async () => {
    try {
      // console.log(documentInfo?.id)

      await handleEmitirDiferido(info?.id_real)
      setFacturaGenerada(true)
      toast.success(`Se generó la factura del Pedido ${info?.sales_order_id}`)
    } catch (error) {
      setFacturaGenerada(false)
      toast.error(error.message)
    }
  }

  const printFactura = async () => {
    try {
      const response = await handlePrintFactura(info?.tipo_comprobante_codigo_interno, info?.id_real)

      if (response.status === 200) {
        toast.success('¡Comprobante descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `comprobante-${info.id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

        onClose()
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar el comprobante')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (show && documentInfo) {
      console.log(documentInfo)
      setInfo(documentInfo)
    } else {
      setInfo(null)
    }
  }, [show, documentInfo])

  if (!show && !documentInfo) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Factura diferida - Pedido #{info?.sales_order_ids || 'No disponible'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='text-center mb-8'>
          <i className='bi bi-info-circle text-warning fs-2' />
          <h1 className='fs-2'>Advertencia</h1>
        </div>
        <p className='fs-5'>Va a realizar la factura diferida del <strong>Pedido #{info?.sales_order_ids || 'No disponible'}</strong>, ¿confirma?</p>
      </Modal.Body>

      <Modal.Footer>
        <div className='row'>
          <div className='col-12 text-end'>
            <Button type='button' variant='secondary' onClick={onClose} className='me-3' disabled={loading}>
              <i className='bi bi-slash-circle' />
              Cerrar
            </Button>

            {!facturaGenerada
              ?
                <Button type='button' variant='primary' onClick={handleSubmit} disabled={loading}>
                  <i className='bi bi-save me-2' />
                  Facturar
                </Button>
              :
                <Button type='button' variant='primary' onClick={printFactura} disabled={loading}>
                  <i className='bi bi-printer' />
                  Imprimir
                </Button>
            }
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default FacturaDiferidaModal