import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useBusinessUnits, useSellers, usePaymentConditions, useBillingTypes } from 'hooks'
import { InputBS, Label, SelectField, Checkbox, Input } from 'components'

import { EditPurchaseOrderModal } from './'
import { SalesOrderStatusI, RoleI } from 'interfaces'

const GeneralTab = ({ editing, handleGetSalesOrder }) => {
  const { currentUser } = useAuth()
  const { values } = useFormikContext()
  const intl = useIntl()

  const [editPurchaseOrder, setEditPurchaseOrder] = useState(false)

  const { handleGetBusinessUnits } = useBusinessUnits()
  const [businessUnits, setBusinessUnits] = useState([])

  const { handleGetSellers } = useSellers()
  const [sellers, setSellers] = useState([])

  const { handleGetPaymentConditions } = usePaymentConditions()
  const [paymentConditions, setPaymentConditions] = useState([])

  const { handleGetBillingTypes } = useBillingTypes()
  const [billingTypes, setBillingTypes] = useState([])

  const getBusinessUnits = useCallback(async () => {
    try {
      const response = await handleGetBusinessUnits({ order_by: 'ASC' })
      setBusinessUnits(response.data.result)
    } catch (error) {
      setBusinessUnits([])
      toast.error(error.message)
    }
  }, [handleGetBusinessUnits])

  const getSellers = useCallback(async () => {
    try {
      const response = await handleGetSellers('token', '?is_enabled=1')
      setSellers(response.data.result)
    } catch (error) {
      setSellers([])
      toast.error(error.message)
    }
  }, [handleGetSellers])

  const getPaymentConditions = useCallback(async () => {
    try {
      let params = {
        // is_enabled: 1
      }
      const response = await handleGetPaymentConditions(params)
      setPaymentConditions(response.result)
    } catch (error) {
      setPaymentConditions([])
      toast.error(error.message)
    }
  }, [handleGetPaymentConditions])

  const getBillingTypes = useCallback(async () => {
    try {
      let params = {
        is_active: 1
      }
      const response = await handleGetBillingTypes(params)
      setBillingTypes(response.data.result.map(d => ({ value: d.id, label: d.label })))
    } catch (error) {
      setBillingTypes([])
      toast.error(error.message)
    }
  }, [handleGetBillingTypes])

  useEffect(() => {}, [values.budget_id, values.customer_supplier_id, values.customer_supplier_name])

  useEffect(() => {
    getBusinessUnits()
    getSellers()
    getPaymentConditions()
    getBillingTypes()

    return () => {}
  }, [getBusinessUnits, getSellers, getPaymentConditions, getBillingTypes])

  return (
    <>
      {/* Fecha creación, actualización + usuario de la acción */}
      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='user_creation_name' name='user_creation_name' label='Creado por' disabled />
        </div>

        <div className='col-3'>
          <InputBS id='created_at' name='created_at' label='Fecha creación' disabled />
        </div>

        {values.updated_by_user_id &&
          <>
            <div className='col-3'>
              <InputBS id='user_update_name' name='user_update_name' label='Actualizado por (últ. vez)' disabled />
            </div>

            <div className='col-3'>
              <InputBS id='updated_at' name='updated_at' label='Últ. fecha actualización' disabled />
            </div>
          </>
        }
      </div>

      <div className='separator my-8'></div>

      <div className='row mb-8'>
        <div className='col-3'>
          <InputBS id='customer_supplir_business_name' name='customer_supplier_business_name' label='Cliente' disabled />
        </div>

        <div className='col-3'>
          <SelectField
            id='business_unit_id'
            name='business_unit_id'
            label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.BUSINESS_UNIT' })}
            options={[{ value: '', label: 'Seleccione una unidad de negocio' }, ...businessUnits.map(item => ({ value: item.id, label: item.name }))]}
            disabled={true}
          />
        </div>

        <div className='col-3'>
          <SelectField
            id='seller_id'
            name='seller_id'
            label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.SELLER' })}
            options={[{ value: '', label: 'Seleccione un vendedor' }, ...sellers.map(item => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }))]}
            disabled={!editing}
          />
        </div>

        <div className='col-3'>
          <SelectField
            id='payment_condition_id'
            name='payment_condition_id'
            label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.PAYMENT_CONDITION' })}
            options={[{ value: '', label: 'Seleccione una condición de pago' }, ...paymentConditions.map(item => ({ value: item.id, label: item.label }))]}
            disabled={!editing}
          />
        </div>
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <InputBS id='issue_date' name='issue_date' type='date' label={intl.formatMessage({ id: 'FORM.BUDGETS.GENERAL.ISSUE_DATE' })} disabled />
        </div>

        <div className='col-3'>
          <div className='d-flex flex-column justify-content-between align-items-start'>
            <Label htmlFor='bidding' label={'Extras'} />

            <div className='d-flex flex-row justify-content-between align-items-center'>
              <Checkbox id='bidding' disabled={!editing} />
              <Label htmlFor='bidding' label={'Licitación'} styles='mb-0 ms-4' />
            </div>
          </div>
        </div>

        {Boolean(values.bidding) &&
          <div className='col-3'>
            <InputBS id='bidding_value' name='bidding_value' label='Nro. Licitación' disabled={!editing} />
          </div>
        }
      </div>

      <div className='row my-8'>
        <div className='col-3'>
          <Label htmlFor='purchase_order' label='Nro. Orden de compra' />
          {
            !editPurchaseOrder &&
            values.sales_order_status_id !== SalesOrderStatusI.CANCELADO &&
            currentUser.roles.some(r => (r.id == RoleI.ADMIN || r.id == RoleI.VENTAS || r.id == RoleI.VENTAS_GTE)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => {
                  setEditPurchaseOrder(editPurchaseOrder ? false : true)
                }}
              />
          }

          <Input id='purchase_order' name='purchase_order' disabled={!editing} />
        </div>

        <EditPurchaseOrderModal show={editPurchaseOrder} onClose={() => setEditPurchaseOrder(false)} handleGetSalesOrder={handleGetSalesOrder} />

        <div className='col-3'>
          <SelectField
            id='billing_type_id'
            name='billing_type_id'
            label='Facturación'
            options={billingTypes}
            // onChange={e => {
            //   if (Number(e.target.value) !== BillingTypesI.DIFERIDA) {
            //     setFieldValue('billing_date', '')
            //   }

            //   setFieldValue('billing_type_id', Number(e.target.value))
            // }}
            disabled={!editing}
          />
        </div>

        {/* {values.billing_type_id === BillingTypesI.DIFERIDA &&
          <div className='col-3'>
            <InputBS id='billing_date' name='billing_date' type='date' label='Fecha' disabled={!editing} />
          </div>
        } */}
      </div>
    </>
  )
}

export default GeneralTab