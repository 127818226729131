import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'

import { useOutOrders } from 'hooks/Sales'
import { Input, SelectField } from 'components'
import { Button } from 'react-bootstrap'

import { OutOrderStatusI } from 'interfaces'

const todosMenosParciales = (status) => {
  return [
    ...status.filter(s => s.id != OutOrderStatusI.RESERVA_PARCIAL).map(s => s.id) // 12: Reserva parcial
  ]
}

const initialValues = {
  id_text: '',
  from_date: '',
  to_date: '',
  nombre: '',
}

const labelColor = (out_order_status_id) => {
  switch (out_order_status_id) {
    case OutOrderStatusI.PENDIENTE_PREPARACION:
    case OutOrderStatusI.PENDIENTE_DESPACHO:
      return "estado-celeste";
    case OutOrderStatusI.EN_PREPARACION:
    case OutOrderStatusI.EN_DESPACHO:
    case OutOrderStatusI.EN_DISTRIBUCION:
      return "estado-azul";
    // case OutOrderStatusI.EN_PREPARACION:
    //   return "estado-gris";
    case OutOrderStatusI.CANCELADO:
      return "estado-rojo";
    case OutOrderStatusI.COORDINACION_ENTREGA:
      return "estado-amarillo";
    case OutOrderStatusI.ENTREGADA:
      return "estado-verde";
    case OutOrderStatusI.EN_REVISION:
    case OutOrderStatusI.RESERVA_PARCIAL:
      return "estado-naranja";
    default:
      return ""
  }
}

const Filter = ({  setData, queryMD, setQueryMD, setTotalCount }) => {
  const { currentUser, setCurrentUser } = useAuth()

  const [formikInitialValues, setFormikInitialValues] = useState(initialValues)

  const { loading, handleGetOutOrders } = useOutOrders()

  const filterData = async (values) => {
    let params = {
      ...values,
      ...queryMD,
      id_text: values?.id_text ? values.id_text : undefined,
      from_date: values?.from_date ? values.from_date : undefined,
      to_date: values?.to_date ? values.to_date : undefined,
      nombre: values?.nombre ? values.nombre : undefined,
      getDetail: 1
    }

    try {
      // const response = await handleGetOutOrders(params)
      // const data = response.data.result.map(d => ({
      //   ...d,
      //   label_color: labelColor(d.out_order_status_id)
      // }))

      // setCurrentUser((prevCurrentUser) => ({
      //   ...prevCurrentUser,
      //   out_orders: { ...params },
      // }))

      setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
      // setData(data)
      // setTotalCount(response.data.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }


  useEffect(() => {
    if (currentUser?.user_id) {
      setFormikInitialValues({
        ...initialValues,
        id_text: currentUser?.out_orders?.id_text || '',
        from_date: currentUser?.out_orders?.from_date || '',
        to_date: currentUser?.out_orders?.to_date || '',
        nombre: currentUser?.out_orders?.nombre || '',
      })
    }
  }, [currentUser.user_id])

  return (
    <Formik
      initialValues={formikInitialValues}
      onSubmit={filterData}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row mb-8'>
            <div className='col-2'>
              <Input id='id_text' name='id_text' disabled={loading} />
              <p className='text-muted'>Nro. OE / ID Pedido</p>
            </div>
            <div className='col-4'>
              <Input id='nombre' name='nombre' disabled={loading} />
              <p className='text-muted'>ID / Cliente</p>
            </div>
            <div className='col'>
              <Input id='from_date' name='from_date' type='date' disabled={loading} />
              <p className='text-muted'>Desde F. entrega</p>
            </div>
            <div className='col'>
              <Input id='to_date' name='to_date' type='date' disabled={loading} />
              <p className='text-muted'>Hasta F. entrega</p>
            </div>


          </div>


          <div className='row mb-8'>
            <div className='col-12 text-end'>
              <Button
                type='button'
                variant="secondary"
                onClick={() => {
                  resetForm()
                  submitForm()
                }}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button  variant="primary" type='submit' disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>

            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter