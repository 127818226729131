
const initialValues = {
  id: null,
  supplier_id: null,
  issue_date: '',
  total: '',
  notes: '',
  internal_notes: '',
  order_type_id: null,
  detail:[],
  observations:'',
  out_order_id:'',
  total:'',
  order_status_id:'',
  order_status_label:'',

}


export {
  initialValues
}