import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomTable } from 'components'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useClickOutside, useOutOrders } from 'hooks'
import useTransactionalDocuments from 'hooks/useTransactionalDocuments'
import { TableContainer } from 'containers'
import Filter from './Filter'
import useGetColumns from './columns'
import Swal from 'sweetalert2'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import ConfirmationModal from './ConfirmationModal'
import { helix } from 'ldrs'
import AgregarOrdenesModal from './AgregarOrdenesModal'


const initialObQuery = {
  pageNumber: "1",
  pageSize: "10",
  pendiente:1,
  sortField:'id',
  sortOrder:'desc'
}

const labelColor = (status) => {
    if (!status) {
      return ""
    }

    switch (status?.toUpperCase()) {
      case "SUCCESS_WITH_OBSERVATIONS":
        return "estado-amarillo";
      case "SUCCESS":
        return "estado-verde";
      case "ERROR_NEGOCIO_AFIP":
        return "estado-rojo";
      case "DEFERRED":
        return "estado-celeste";
      case "DISCARDED":
        return "estado-naranja";
      default:
        return '';
    }
}
const statusDisplay = (status) => {
    if (!status) {
      return ""
    }
    switch (status?.toUpperCase()) {
      case "SUCCESS_WITH_OBSERVATIONS":
        return "Aprobado c/observaciones";
      case "SUCCESS":
        return "Aprobado";
      case "DEFERRED":
        return "Factura Diferida";
      case "ERROR_NEGOCIO_AFIP":
        return "Error AFIP";
      case "DISCARDED":
        return "Descartada";
      default:
        return '';
    }
}


const GroupedInvoicesAdd = () => {
    const [data, setData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [faltantes, setFaltantes] = useState(null)
    const [allSelected, setAllSelected] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [showAgregarOrdenesModal, setShowAgregarOrdenesModal] = useState(false)
    const handleRowSelection = useCallback((rowId, statusId, remito_nro_cbt_completo, afip_id) => {
      setSelectedRows((prevSelectedRows) => {
        if (prevSelectedRows.some(i => i.id === rowId)) {
          return prevSelectedRows.filter(i => i.id !== rowId)
        } else {
          return [...prevSelectedRows, { 
            id: rowId, 
            afip_id, 
            checked: true, 
            remito_nro_cbt_completo }]
        }
      })
    }, [])

    const { COLUMNS } = useGetColumns(selectedRows, handleRowSelection)
    const [filter, setFilter] = useState("")
    const [queryMD, setQueryMD] = useState(initialObQuery)
    const [totalCount, setTotalCount] = useState(0)
    const {handleGetFacturacion, loading} = useOutOrders();
    const navigate = useNavigate();
    const {handleUnificarComprobantes, loading:loadingTransactionalDocuments} = useTransactionalDocuments();

    const changeAllSelected = ()=>{
      if(!allSelected){
        setAllSelected(true)
        setData(data.map(s=>({...s, checked:true})))
        setSelectedRows(data.map(s=>({
          id: s.id, 
          afip_id: s.afip_id, 
          checked: true, 
          remito_nro_cbt_completo:s.remito_nro_cbt_completo
        })))
      }
      else{
        setAllSelected(false)
        setSelectedRows([])
      }
    }

    const handleCloseAgregarOrdenesModal = ()=>{
      setShowAgregarOrdenesModal(false)
    
    }

    const handleCloseModal = async(data)=>{
      setShowConfirmationModal(false)
      
      if(!data){
        return;
      }

      try{
        let msg = '';
        if(faltantes?.split(',')?.length>0){
          msg = `Hay ${faltantes.split(',').length} remitos que no se encontraron, confirma el agrupamiento del resto (${selectedRows?.length} remitos) en una sola factura?`
        }
        else{
          msg = `Confirma el agrupamiento de ${selectedRows?.length} remitos en una sola factura?`
        }
        const resp = await Swal.fire({
            title: 'Advertencia',
            text: msg,
            icon: 'warning',
            showDenyButton: true,
            denyButtonText: 'No, cancelar',
            confirmButtonText: `Si, facturar`,
            customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
            reverseButtons: true,
          })
    
          if (resp.isConfirmed) {   
            await handleUnificarComprobantes( data)
            setSelectedRows([])
            getDocuments()
            toast.success(`Factura generada correctamente` )
          }
      }
      catch(err){
          toast.error(err.message)
      }    
    }

    const getDocuments = async () => {
        try {
            let params = {}
            if (queryMD && queryMD.pageNumber) {
                params = {
                ...params,
                ...queryMD,
                pageSize:5000
                }
            }
            
            const response = await handleGetFacturacion(params)

            if (response.data.result) {
                const data = response.data.result

                const dataColored = data.map(d => ({ 
                    ...d, 
                    label_color: labelColor(d.afip_estado),
                    status_display: statusDisplay(d.afip_estado)
                }))
                setData(dataColored);
                setTotalCount(response.data.metadata.count);
            }
        } catch (error) {
            setData([])
            setTotalCount(0)
            toast.error(error.message)
        }
    }


  const columns = useMemo(() => [
    {
      Header: ({ table }) => (
        data?.length >0 && <input
          type="checkbox"
          checked={!!allSelected}
          onChange={changeAllSelected}
        />
      ),
      id: 'selected',
      accessor: (row) => (
          <input
            name="selected"
            type="checkbox"
            value={row.id}
            onChange={() => handleRowSelection(row.id, row.out_order_status_id, row.remito_nro_cbt_completo, row.afip_id)}
            checked={selectedRows?.some(i => i.id === row.id)}
            // disabled={!currentUser?.roles.some(r => r.id == RoleI.ADMIN || r.id == RoleI.OPERACIONES || r.id == RoleI.OPERACIONES_GTE)}
          />
      )
    },
    ...COLUMNS,
  ], [COLUMNS])

  // const paginationOptions = {
  //   totalSize: totalCount,
  //   obQuery:queryMD ,
  //   setObQuery:setQueryMD
  // };



  useEffect(()=>{
    setData([])
    setQueryMD(initialObQuery)
    setSelectedRows([])
    setAllSelected(false)
    setShowConfirmationModal(false)
    setShowAgregarOrdenesModal(false)
    setFaltantes(null)
    helix.register()
  },[])

  useEffect(()=>{
    if(queryMD.nombre && queryMD.nombre.trim() !='')
      getDocuments()
  }, [queryMD])


  return (
    <>
      <TableContainer
            title='Nuevo agrupamiento de facturas'
            createButton={
                <Button type='button' variant="secondary" onClick={() => navigate(-1)} className='me-3' >
                    <i className="ki-duotone ki-left" />Volver
                </Button>
                }
            filter={<Filter setData={setData} setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />}
        >
        {
          (loading || loadingTransactionalDocuments) &&
          <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>
        }

        <ConfirmationModal onHide={handleCloseModal} show={showConfirmationModal} selectedRows={selectedRows} 
          setSelectedRows={setSelectedRows} getDocuments={getDocuments} faltantes={faltantes} />
        
        <AgregarOrdenesModal onHide={handleCloseAgregarOrdenesModal} show={showAgregarOrdenesModal} selectedRows={selectedRows} 
          setSelectedRows={setSelectedRows} ordenesEgresoDisponibles={data} faltantes={faltantes} setFaltantes={setFaltantes} />

        <div className='row'>
          <div className="col pt-8 pe-10 pb-5 text-start">
            <label className='form-label ps-10'>Remitos seleccionados: {selectedRows?.length}</label>
            <label className='form-label ps-10'>Remitos no encontrados: {faltantes?.split(',').length || 0}</label>
          </div>
          <div className="col pt-8 pe-10 pb-5 text-end">
            <Button variant="secondary" onClick={()=>setShowAgregarOrdenesModal(true)} disabled={data?.length<1 || loadingTransactionalDocuments} className='me-3'>
                <span><i className='bi bi-copy pe-2' /></span>Cargar Órdenes
            </Button>
            <Button variant="primary" onClick={()=>setShowConfirmationModal(true)} disabled={selectedRows?.length<1 || loadingTransactionalDocuments}>
                <span><i className='bi bi-play pe-2' /></span>Agrupar
            </Button>
          </div>
        </div>

        <CustomTable columns={columns} data={data || []} queryMD={queryMD} setQueryMD={setQueryMD} />
      </TableContainer>


    </>
  )
}

export default GroupedInvoicesAdd