import { useTransactionalDocuments } from 'hooks'
import { helix } from 'ldrs'
import { useState, useCallback, useEffect } from 'react'
import { Button, Modal, OverlayTrigger, Table, Tooltip} from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'


const ConfirmationModal = ({ show, onHide, selectedRows, setSelectedRows, faltantes }) => {
    const [textoLibre, setTextoLibre] = useState("");
    const [borderDanger, setBorderDanger] = useState(false)
    const removeWaybillFromList = (row)=>{
        setSelectedRows(prev=>prev.filter(p=>p.id!=row?.id))
    }

    const crearAgrupada = ()=>{
        if(!textoLibre || textoLibre.trim()==''){
            setBorderDanger(true)
            return
        }
        let data = {
            ids: selectedRows.map(s=>s.afip_id),
            texto_libre:textoLibre
        }
        onHide(data)
    }

    useEffect(()=>{
        if(show){
            console.log(selectedRows)
            setTextoLibre("");
            setBorderDanger(false)
        }
    }, [show])

    useEffect(()=>{
        helix.register()
    }, [])

    return (
        <Modal size="md" show={show} onHide={onHide} backdrop='static'>
        <Modal.Header closeButton>
            <Modal.Title>Agrupamiento de facturas</Modal.Title>
        </Modal.Header>

        <Modal.Body className="">
            <div className="row">
                <div className="col">
                    <div className='form-label mb-0'>Seleccionados: {selectedRows?.length}</div>
                    <div className='form-label mb-5'>No encontrados: {faltantes?.split(',')?.length}</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label className='form-label'>Texto libre</label>
                    <textarea className={`form-control ${borderDanger ? 'border-danger' : ''}`} rows={2} 
                        onChange={e=>setTextoLibre(e.target.value)}/>
                </div>
            </div>
            <div className="row">
                <div className="col">

                    <Table striped bordered hover>
                        <thead>
                            <th>
                                <td colSpan={2}>Remitos a agrupar</td>
                            </th>
                        </thead>
                        <tbody>
                            {
                                selectedRows.map((row, idx)=>                        
                                    <tr key={idx}>
                                        <td>
                                            {row.remito_nro_cbt_completo}
                                        </td>
                                        <td className="text-center">
                                            <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>Quitar de la lista</Tooltip>}>
                                                <span className="" onClick={()=>{removeWaybillFromList(row)}} >
                                                    <i className="bi bi-trash text-danger fs-3" style={{ cursor: 'pointer' }} />
                                                </span>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                )
                            }

                        </tbody>
                    </Table>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <div className="row">
                <div className="col">
                    <Button variant="secondary" onClick={()=>onHide(null)} className='me-3'>Cerrar</Button>
                    <Button variant="primary" onClick={crearAgrupada} disabled={!selectedRows?.length}>Crear agrupada</Button>
                </div>
            </div>
        </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal