import { useState, useEffect, useCallback } from 'react'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import moment from 'moment'
import * as XLSX from 'xlsx/xlsx.mjs';

import { useSalesOrders, useReports } from 'hooks'

import { Input, SelectField } from 'components'

import { labelColor } from 'pages/Sells/Orders/List'
import { tipoEntregaLabelColor } from './'

const groupedStatusOptions = [
  { value: 1, label: "Pendiente" },
  { value: 2, label: "Operaciones" },
  { value: 3, label: "Finalizado" },
]

const initialFormikValues = {
  id: '',
  text: '',
  issue_date_from: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  issue_date_to: moment().format('YYYY-MM-DD'),
  profitability_auth_sent_date_from: '',
  profitability_auth_sent_date_to: '',
  sales_order_status_id_filter: [],
  grouped_status_id: [],
  transac_doc_date_from: '',
  transac_doc_date_to: '',
  with_nc: '',
  with_fc: ''
}

const Filter = ({ setData, queryMD, setQueryMD, setTotalCount, setConDetalle }) => {
  const { loading, handleGetVentasReport, handleGetVentasDetalleReport } = useReports()

  const { handleGetSalesOrderStatus } = useSalesOrders()
  const [statusOptions, setStatusOptions] = useState([])

  const filterData = (values) => {
    let params = {
      ...queryMD,
      ...values,
      id: values?.id ? values.id : undefined,
      text: values?.text ? values.text : undefined,
      issue_date_from: values?.issue_date_from ? values.issue_date_from : undefined,
      issue_date_to: values?.issue_date_to ? values.issue_date_to : undefined,
      profitability_auth_sent_date_from: values?.profitability_auth_sent_date_from ? values.profitability_auth_sent_date_from : undefined,
      profitability_auth_sent_date_to: values?.profitability_auth_sent_date_to ? values.profitability_auth_sent_date_to : undefined,
      transac_doc_date_from: values?.transac_doc_date_from ? values.transac_doc_date_from : undefined,
      transac_doc_date_to: values?.transac_doc_date_to ? values.transac_doc_date_to : undefined,
      sales_order_status_id_filter: values?.sales_order_status_id_filter ? values.sales_order_status_id_filter : undefined,
      grouped_status_id: values?.grouped_status_id ? values.grouped_status_id : undefined,
      with_nc: values.with_nc !== '' ? values.with_nc : undefined,
      with_fc: values.with_fc !== '' ? values.with_fc : undefined,
    }

    return params
  }

  const handleSubmit = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: 10,
      }
      const response = await handleGetVentasReport(params)
      const data = response.result.map(d => ({
        ...d,
        label_color: labelColor(d.EstadoPedidoID),
        entrega_label_color: tipoEntregaLabelColor(d.EntregaEspecial),
      }))

      setQueryMD({ ...params, pageNumber: 1, pageSize: 10 })
      setData(data)
      setTotalCount(response.metadata.count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  const exportData = async (values) => {
    try {
      const params = {
        ...filterData(values),
        pageNumber: 1,
        pageSize: undefined
      }

      const response = await handleGetVentasReport(params)

      if (response.result) {
        const datos = response.result.map(d => {
          let data = {
            ...d,
            PedidoFecha: moment(d.PedidoFecha).format('DD/MM/YYYY'),
            PedidoFechaAutorizacion: d?.PedidoFechaAutorizacion ? moment(d.PedidoFechaAutorizacion).format('DD/MM/YYYY HH:mm') : '',
            FechaRemito: d?.FechaRemito ? moment(d.FechaRemito).format('DD/MM/YYYY') : '',
            FechaCancelRemito: d?.FechaCancelRemito ? moment(d.FechaCancelRemito).format('DD/MM/YYYY') : '',
            FechaComprobante: d?.FechaComprobante ? moment(d.FechaComprobante).format('DD/MM/YYYY') : '',
          }

          return data;
        })

        var ws = XLSX.utils.json_to_sheet(datos);

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Ventas");
        XLSX.writeFile(wb, `Ventas ${moment().format("YYYYMMDD-HHmm")}.xlsx`);
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const exportDetailData = async (values) => {
    try {
      const params = {
        ...filterData(values),
        addDetail: 1,
        pageNumber: 1,
        pageSize: undefined
      }

      const response = await handleGetVentasDetalleReport(params)

      if (response.result) {
        const datos = response.result.map(d => {
          let data = {
            ...d,
            PedidoFecha: moment(d.PedidoFecha).format('DD/MM/YYYY'),
            PedidoFechaAutorizacion: d?.PedidoFechaAutorizacion ? moment(d.PedidoFechaAutorizacion).format('DD/MM/YYYY HH:mm') : '',
            FechaRemito: d?.FechaRemito ? moment(d.FechaRemito).format('DD/MM/YYYY') : '',
            FechaCancelRemito: d?.FechaCancelRemito ? moment(d.FechaCancelRemito).format('DD/MM/YYYY') : '',
          }

          return data;
        })

        let ws = XLSX.utils.json_to_sheet(datos);

        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Ventas_Detalle");
        XLSX.writeFile(wb, `Ventas_Detalle ${moment().format("YYYYMMDD-HHmm")}.xlsx`);
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getSalesOrderStatus = useCallback(async () => {
    let params = {}

    try {
      const response = await handleGetSalesOrderStatus(params)

      const statuses = response.data.result
        // .filter(s=>s.name!='RESERVA_PARCIAL')
        .map(s => {
          return {
            value: s.id,
            label: s.label,
          }
        })

      setStatusOptions(statuses)
    } catch (error) {
      setStatusOptions([])
      toast.error(error.message)
    }
  }, [handleGetSalesOrderStatus])

  const cambiaSwitchRemito = (ev) => {
    setConDetalle(ev?.target?.checked)
  }

  useEffect(() => {
    getSalesOrderStatus()
  }, [getSalesOrderStatus])

  return (
    <Formik
      initialValues={initialFormikValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, resetForm, submitForm }) => (
        <Form>
          <div className='row'>
            <div className='col-1'>
              <Input id='id' name='id' />
              <p className='text-muted'>ID Pedido</p>
            </div>

            <div className="col-3">
              <Input id='text' name='text' />
              <p className='text-muted'>ID Cliente / Cliente / CUIT</p>
            </div>

            <div className='col-2'>
              <Input id='issue_date_from' name='issue_date_from' type='date' max={values.issue_date_to} />
              <p className='text-muted'>Fecha desde</p>
            </div>

            <div className='col-2'>
              <Input id='issue_date_to' name='issue_date_to' type='date' min={values.issue_date_from} />
              <p className='text-muted'>Fecha hasta</p>
            </div>

            <div className='col-2'>
              <Input id='profitability_auth_sent_date_from' name='profitability_auth_sent_date_from' type='date' max={values.profitability_auth_sent_date_to} />
              <p className='text-muted'>F. autorización desde</p>
            </div>

            <div className='col-2'>
              <Input id='profitability_auth_sent_date_to' name='profitability_auth_sent_date_to' type='date' min={values.profitability_auth_sent_date_from} />
              <p className='text-muted'>F. autorización hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='sales_order_status_id_filter'
                name='sales_order_status_id_filter'
                options={statusOptions || []}
                isMulti
                marginTop={'-20px'}
                placeholder='Seleccione un estado'
              />
              <p className='text-muted'>Estado</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='grouped_status_id'
                name='grouped_status_id'
                options={groupedStatusOptions || []}
                isMulti
                marginTop={'-20px'}
                placeholder='Seleccione un estado'
              />
              <p className='text-muted'>Estado agrupado</p>
            </div>

            <div className='col-2'>
              <Input id='transac_doc_date_from' name='transac_doc_date_from' type='date' max={values.transac_doc_date_to}/>
              <p className='text-muted'>F. factura desde</p>
            </div>

            <div className='col-2'>
              <Input id='transac_doc_date_to' name='transac_doc_date_to' type='date' min={values.transac_doc_date_from} />
              <p className='text-muted'>F. factura hasta</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-4'>
              <SelectField
                id='with_fc'
                name='with_fc'
                options={[
                  { value: -1, label: "Todos" },
                  { value: 0, label: "Sin Factura" },
                  { value: 1, label: "Con Factura" }
                ]}
                marginTop={'-20px'}
                placeholder='Seleccionar'
              />
              <p className='text-muted'>Factura</p>
            </div>

            <div className='col-4'>
              <SelectField
                id='with_nc'
                name='with_nc'
                options={[
                  { value: -1, label: "Todos" },
                  { value: 0, label: "Sin Nota de crédito" },
                  { value: 1, label: "Con Nota de crédito" }
                ]}
                marginTop={'-20px'}
                placeholder='Seleccionar'
              />
              <p className='text-muted'>Nota de crédito</p>
            </div>
          </div>

          <div className="row mt-3">
            <div className='col-12 text-end'>
              <Dropdown className='d-inline me-3'>
                <Dropdown.Toggle id="dropdown-basic" variant="secondary" disabled={loading}>
                  <i className="bi bi-download pe-3"/>
                  Exportar
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ width: "200px" }}>
                  <Dropdown.Item onClick={() => exportData(values)} disabled={loading}>
                    Cabecera
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => exportDetailData(values)} disabled={loading}>
                    Detalle
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  resetForm()
                  submitForm()
                }}
                className='me-3'
                disabled={loading}
              >
                <i className="bi bi-zoom-out mb-1" />
                Limpiar
              </Button>

              <Button type='submit' variant="primary" disabled={loading}>
                <i className="bi bi-search mb-1" />
                {loading ? 'Cargando...' : 'Buscar'}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter