import { useState, useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useCustomerSuppliers } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'

import Filter from './FilterModal'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const AñadirClienteModal = ({ show, onClose }) => {
  const { id } = useParams()

  const { loading, handleGetCustomerSuppliers, handleAddClienteParaRemitir } = useCustomerSuppliers()
  const [clientes, setClientes] = useState([])
  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)
  const [idClienteSeleccionado, setIdClienteSeleccionado] = useState(null)

  const getClientes = useCallback(async () => {
    try {
      let params = {
        ...query,
        cliente_para_remitir: 1,
      }
      const response = await handleGetCustomerSuppliers(null, null, params)
      const data = response.data.result
      const count= response.data.metadata.count

      setClientes(data)
      setTotalCount(count)
    } catch (error) {
      setClientes([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetCustomerSuppliers, query])

  const addClienteParaRemitir = async () => {
    try {
      let params = {
        id_cliente: Number(id),
        id_cliente_para_remitir: idClienteSeleccionado
      }
      const response = await handleAddClienteParaRemitir(params)

      toast.success(response.data.message)
      setIdClienteSeleccionado(null)
      setQuery(initialQuery)
      onClose()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const COLUMNS = [
    {
      Header: <p className='m-0 p-0'>ID</p>,
      id: 'id',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.id}</p>
      )
    },
    {
      Header: <p className='m-0 p-0'>Nombre</p>,
      id: 'business_name',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.business_name}</p>
      ),
    },
    {
      Header: <p className='m-0 p-0'>Documento</p>,
      id: 'documentation',
      accessor: (row) => (
        <p className='m-0 p-0'>{row.documentation}</p>
      ),
    },
    {
      Header: '',
      id: 'seleccionar',
      accessor: (row) => (
        <Button
          type='button'
          variant='primary'
          className='p-2'
          onClick={() => setIdClienteSeleccionado(row.id)}
          disabled={idClienteSeleccionado === row.id}
        >
          <i className='bi bi-plus-circle p-0' />
        </Button>
      )
    }
  ]

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    if (show) {
      getClientes()
    }
  }, [show, getClientes])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} size='lg' backdrop='static' centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Añadir cliente para remitir</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row mb-8'>
          <div className='col'>
            <TableContainer>
              <Filter loading={loading} query={query} setQuery={setQuery} />

              <CustomTable data={clientes} columns={COLUMNS} paginationOptions={paginationOptions} />
            </TableContainer>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className='row'>
          <div className='col text-end'>
            <Button type='button' variant='secondary' className='me-3' onClick={onClose}>
              <i className='bi bi-slash-circle' />
              Cancelar
            </Button>

            <Button type='button' variant='primary' onClick={addClienteParaRemitir}>
              <i className='bi bi-save me-2' />
              Guardar
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default AñadirClienteModal