import React from 'react'
import { Formik, Form } from 'formik'
import moment from 'moment'

import { Input, FormButton, SelectField } from 'components'
import { Button } from 'react-bootstrap'

const request_type = [
  { value: '', label: 'Todos' },
  { value: 1, label: 'Pedido de Compra' },
  { value: 2, label: 'Pedido de Ingreso' }
]

const initialValues = {
  id_filter: '',
  proveedor: '',
  distribuidor: '',
  request_type_id: null,
  order_status_id: 2,
  article_text: '',
}

const Filter = ({ setFilter, queryMD, setQueryMD  }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        let query = '?'
        for (const key in values) {
          if (values[key] !== '') {
            if (key === 'request_type_id') {
              if(values[key]){
                query += `${key}=${values[key]}&`
              }
            }
            if(key === 'date_from' || key === 'date_to'){
              if(values[key]){
                query += `${key}=${moment(values[key]).format("YYYYMMDD")}&`
              }
            }
            else {
              query += `${key}=${values[key]}&`
            }
          }
        }
        query = query.slice(0, -1)
        setFilter(query)
        setQueryMD({...queryMD, pageNumber:1})
      }}
    >
      {({ values, errors }) => (
        <Form>
          <div className='row'>
            <div className='col-2'>
              <Input id='id_filter' name='id_filter' />
              <p className='text-muted'>Nro.</p>
            </div>

            <div className='col-5'>
              <Input id='proveedor' name='proveedor' />
              <p className='text-muted'>ID / Proveedor</p>
            </div>

            <div className='col-5'>
              <Input id='distribuidor' name='distribuidor' />
              <p className='text-muted'>ID / Distribuidor</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <Input id='article_text' name='article_text' />
              <p className='text-muted'>ID / Artículo / Droga</p>
            </div>

            <div className='col-2'>
              <SelectField
                id='request_type_id'
                name='request_type_id'
                options={request_type}
                placeholder='Seleccionar'
                marginTop={'-20px'}
              />
              <p className='text-muted'>Tipo de pedido</p>
            </div>

            <div className='col-2'>
              <Input id='date_from' name='date_from' type='date' />
              <p className='text-muted'>Desde</p>
            </div>

            <div className='col-2'>
              <Input id='date_to' name='date_to' type='date' />
              <p className='text-muted'>Hasta</p>
            </div>
          </div>

          <div className='row pull-right'>
            <div className='col pb-8 d-flex justify-content-end'>
              <Button type='submit' variant='primary'>
                <i className='bi bi-zoom-out' />
                Buscar
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Filter